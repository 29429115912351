@import url('https://fonts.googleapis.com/css2?family=Commissioner:wght@300;400;500;600;700;800&family=Play:wght@400;700&family=Roboto:wght@400;700&family=Ubuntu:wght@400;500;700&display=swap');

body {
	margin: 0;
	font-family: 'Commissioner', sans-serif;
	font-weight: 400;
	font-size: 14px;
	color: #040707;
	line-height: 20px;

	&::-webkit-scrollbar {
		width: 15px;
	}

	/* Track */
	&::-webkit-scrollbar-track {
		background: #f1f1f1;
	}

	/* Handle */
	&::-webkit-scrollbar-thumb {
		background: #888;
		border-radius: 10px;
	}

	/* Handle on hover */
	&::-webkit-scrollbar-thumb:hover {
		background: #555;
	}
}

* {
	box-sizing: border-box;
	--bigsize: 46px;
	--mediumsize: 32px;
	--smallsize: 24px;
	--regsize: 16px;
	--microsize: 14px;
	--nanosize: 12px;
	--picosize: 10px;

	@media (max-width: 980px) {
		--bigsize: 38px;
		--mediumsize: 26px;
		--smallsize: 22px;
		--microsize: 14px;
		--nanosize: 12px;
	}

	@media (max-width: 575px) {
		--bigsize: 24px;
		--mediumsize: 22px;
		--smallsize: 18px;
		--microsize: 12px;
		--nanosize: 10px;
	}
}

a {
	text-decoration: none;
}

img {
	max-width: 100%;
	display: block;
}

b,
strong {
	font-family: 'Commissioner', sans-serif;
	font-weight: 700;
}

.medium-font {
	font-family: 'Commissioner', sans-serif;
	font-weight: 500;
}

.black-font {
	font-family: 'Commissioner', sans-serif;
	font-weight: 700;
}

button {
	background: none;
	border: 0;
	padding: 0;
	margin: 0;
	cursor: pointer;
}

.content-wrapper {
	width: 100%;
	max-width: 1244px;
	margin: auto;
	padding: 0 22px;
}

ul {
	list-style: none;
	margin: 0;
	padding: 0;
}

.swiper-wrapper,
.swiper-slide {
	cursor: grab;
}

header {
	position: relative;
	z-index: 2000;

	#anchor-links {
		transition: all 0.3s ease;
		-webkit-transition: all 0.3s ease;
		-moz-transition: all 0.3s ease;
	}

	.content-wrapper {
		max-width: 1470px;
		position: relative;
		display: flex;
		align-items: center;
		height: 74px;
		justify-content: space-between;

		@media (max-width: 1420px) {
			position: unset;
		}

		@media (max-width: 767px) {
			height: 60px;
		}
	}

	.topbar-message {
		background-color: #fff;
		font-size: 16px;
		font-family: 'Play', 'Commissioner', sans-serif;
		display: flex;
		justify-content: space-between;
		padding: 10px 26px;

		.top-message {
			text-align: center;
			width: 100%;

			.message {
				font-weight: 700;
				padding-right: 10px;
				margin-right: 10px;
				position: relative;

				&:after {
					content: '';
					position: absolute;
					right: 0;
					top: 0;
					width: 10px;
					height: 100%;
					background-image: url(../images/polygon-arrow.svg);
					background-repeat: no-repeat;
					background-position: center;
				}
			}
		}

		.links a {
			font-weight: 500;
			color: #000;
			text-decoration: underline;
		}

		@media (max-width: 767px) {
			.message:after,
			.links {
				display: none;
			}
		}
	}

	.top-header-row {
		height: 30px;
		border-bottom: 1px solid rgb(182, 186, 194, 0.3);

		.content-wrapper {
			max-width: 1470px;
			position: relative;
			display: flex;
			align-items: center;
			height: 100%;
			justify-content: space-between;

			.part {
				display: flex;
				gap: 20px;

				.lang-change {
					gap: 0;
				}
			}

			a {
				color: #b5bac3;
				font-size: var(--microsize);
				display: inline-flex;
				align-items: center;
				gap: 10px;

				&:hover {
					color: white;

					svg > path {
						stroke: white;
					}
				}
			}
		}

		@media (max-width: 767px) {
			display: none;
		}
	}

	.visible-menu {
		display: inline-flex;
		height: 100%;

		@media (max-width: 1420px) {
			position: fixed;
			display: flex;
			top: 0;
			left: 0;
			max-width: 352px;
			width: 100%;
			transform: translateX(-100%);
			opacity: 0.6;
			transition: 0.3s;
			z-index: 100;
			background: white;

			&.active {
				transform: none;
				opacity: 1;
			}
		}
	}

	#mobile-menu-button {
		display: none;
		cursor: pointer;
		z-index: 1;

		@media (max-width: 1420px) {
			display: block;
		}
	}

	#logo {
		display: inline-flex;
		justify-content: center;

		a {
			display: inline-block;
			line-height: 0;
		}

		@media (max-width: 1420px) {
			position: absolute;
			left: 50%;
			transform: translateX(-50%);

			&.search-opened {
				display: none;
			}
		}

		@media (max-width: 767px) {
			img {
				width: 130px;
			}
		}
	}
}

#burger-btn {
	width: 34px;
	height: 34px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	margin-right: 35px;

	svg.close-burger {
		display: none;
	}

	&.active {
		svg.close-burger {
			display: initial;
		}

		svg.open-burger {
			display: none;
		}
	}
}

header {
	background: black;
	position: sticky;
	width: 100%;
	top: 0;
	z-index: 2002;
	box-shadow: 0px 10px 30px #00000029;

	@media (max-width: 767px) {
		&.home-header {
			box-shadow: none;

			#view-search {
				position: absolute;
				top: 65px;
				left: 0;
				z-index: 1;
				width: 100%;

				.search-input {
					width: calc(100% - 44px);
					display: flex;
					margin: 0 auto;
					background: #f2f2f2;
					height: 35px;
					padding: 0 15px;
					justify-content: flex-end;
					align-items: center;
					right: 0;
					border-radius: 5px;
					position: relative;

					&::after {
						position: absolute;
						content: 'Search';
						left: 15px;
						color: white;
						font-size: 14px;
						font-family: 'Ubuntu', Arial, sans-serif;
					}
				}
			}
		}
	}
}

footer {
	background-color: #f1f2f3;
}

//Search bar CSS Transition - START
.search-transition-enter {
	opacity: 0.4;
	transform: translateY(-100%);
}

.search-transition-enter-active {
	opacity: 1;
	transform: translateY(0);
	transition:
		opacity 300ms,
		transform 300ms;
}

.search-transition-exit {
	opacity: 1;
	transform: translateY(0);
}

.search-transition-exit-active {
	opacity: 0.2;
	transform: translateY(-100%);
	transition:
		opacity 0s,
		transform 0s;
}

//Search bar CSS Transition - END

.login-register-mobile {
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	width: 100vw;
	bottom: 0;
	left: 0;
	height: 83px;
	background: white;
	z-index: 2004;
	opacity: 0;
	pointer-events: none;
	transition: 0.2s;

	button {
		width: 46%;
		max-width: 164px;
		height: 45px;
		display: inline-flex;
		border: 1px solid black;
		align-items: center;
		justify-content: center;
		font-size: 14px;
		align-items: center;
		margin: 0 6px;

		&.register-button {
			background: black;
			color: white;
		}
	}

	@media (min-width: 481px) {
		display: none;
	}

	&.active {
		opacity: 1;
		pointer-events: none;

		button {
			pointer-events: auto;
		}
	}
}

#top-home-search {
	opacity: 0;
	pointer-events: none;
	transition: 0.3s;
	display: block;
	position: absolute;
	top: 65px;
	left: 0;
	z-index: 1;
	width: 100%;

	button {
		width: calc(100% - 44px);
		display: flex;
		margin: 0 auto;
		background: #f2f2f2;
		height: 35px;
		padding: 0 15px;
		align-items: center;
		justify-content: space-between;
	}

	&.active {
		opacity: 1;
		pointer-events: initial;
	}

	@media (min-width: 767px) {
		display: none !important;
	}
}

header.line-header {
	box-shadow: none;
	border-bottom: 1px solid #ababab;
	background: white;

	@media (max-width: 1260px) {
		border-bottom: none;
	}
}

header .header-options {
	display: inline-flex;
	column-gap: 20px;
	align-items: center;

	div {
		display: inline-block;
		line-height: 0;

		&.search-mobile img {
			height: 25px;
			width: 25px;
		}

		&#view-search {
			// Go-To : 194px
			.search-input {
				width: 278px;
				display: flex;
				background: white;
				justify-content: space-between;
				padding: 9px 10px;
				cursor: pointer;
				margin-right: 28px;
				align-items: center;

				span {
					line-height: 17px;
					text-decoration: none;
					color: #6a727e;
				}
			}
		}
	}

	@media (max-width: 1280px) {
		#view-search,
		#view-wishlist,
		#user-login {
			display: none !important;
		}

		.view-search-mobile {
			display: block;
		}
	}

	@media (min-width: 1281px) {
		.search-mobile {
			display: none !important;
		}

		.view-search-mobile {
			display: none;
		}
	}
}

#menu-section {
	nav {
		height: 100%;
	}

	.mobile-view-section {
		display: none;
		padding: 0 25px;
		height: 65px;
		align-items: center;
		column-gap: 20px;
		position: relative;
		border-bottom: 1px solid #f1f2f3;
		margin-bottom: 15px;

		.menu-close-mobile {
			display: flex;
			height: 100%;
			align-items: center;
			position: absolute;
			top: 0;
			right: 22px;
			cursor: pointer;
		}

		.user-login-mobile {
			cursor: pointer;
		}

		&.bottom {
			flex-flow: column nowrap;
			justify-content: flex-start;
			align-items: flex-start;
			font-size: 14px;
			border-bottom: none;
			border-top: 1px solid #f1f2f3;
			margin: 15px 0 0 0;
			height: auto;
			padding: 18px 25px;

			a {
				margin: 5px 0;
				padding: 7.5px 0;
				display: block;
				width: 100%;
				color: #6a727e;

				svg {
					margin-right: 7px;
				}
			}
		}
	}

	// @media (max-width: 1280px) {
	@media (max-width: 1420px) {
		width: 100%;
		overflow-y: scroll;

		.mobile-view-section {
			display: flex;
		}

		nav {
			height: auto;
		}
	}

	&::-webkit-scrollbar {
		width: 10px;
	}

	/* Track */
	&::-webkit-scrollbar-track {
		background: #f1f1f1;
	}

	/* Handle */
	&::-webkit-scrollbar-thumb {
		background: #888;
		border-radius: 10px;
	}

	/* Handle on hover */
	&::-webkit-scrollbar-thumb:hover {
		background: #555;
	}
}

.main-menu {
	display: flex;
	flex-wrap: wrap;
	height: 100%;

	.menu-item {
		padding: 0 2px;

		a {
			color: black;
			font-family: 'Commissioner', sans-serif;
			font-weight: 500;
			display: inline-block;
		}

		& > a {
			display: inline-flex;
			justify-content: center;
			align-items: center;
			padding: 9px 11px;
			height: 100%;
			font-weight: 700;
			font-size: var(--regsize);
			color: white;
			text-transform: uppercase;
			cursor: pointer;

			@media (max-width: 980px) {
				font-size: 14px;
			}
		}

		.sub-menu {
			display: none;
			position: absolute;
			width: 100%;
			top: 0;
			left: 0;

			.sub-wrapper {
				background: white;
				display: flex;
				max-width: 1200px;
				margin: 0 auto;
				padding: 55px 95px;
				align-items: flex-start;

				a {
					display: block;
					font-size: var(--regsize);
					font-weight: 400;
					margin-bottom: 20px;

					@media (max-width: 980px) {
						margin: 0;
						padding: 11.5px 18px;
						font-size: 16px;
					}
				}

				.all-brands-part {
					max-height: 65vh;
					height: 100%;
					overflow-y: auto;
					width: 58.3%;
					display: flex;
					flex-flow: row wrap;
					margin-right: 15px;
					row-gap: 10px;
					justify-content: flex-start;

					@media (max-width: 1420px) {
						max-height: 100%;
						margin-right: 0px;
						padding-right: 15px;
						overflow-y: unset;
					}

					&::-webkit-scrollbar {
						width: 10px;
					}

					/* Track */
					&::-webkit-scrollbar-track {
						background: #f1f1f1;
					}

					/* Handle */
					&::-webkit-scrollbar-thumb {
						background: #888;
						border-radius: 10px;
					}

					/* Handle on hover */
					&::-webkit-scrollbar-thumb:hover {
						background: #555;
					}

					a {
						width: 33.3%;

						&:hover {
							font-weight: 700;
						}
					}
				}

				.menu-subparents {
					position: relative;
					white-space: nowrap;
					cursor: default;
					width: 33.33%;
					// background-color: #0154ff;

					.menu-subparents-titles {
						font-weight: 700;
						margin-bottom: 20px;
					}

					.menu-subparents-see-all {
						font-weight: 700;
						text-decoration: underline;
					}
				}

				.top-brands-part {
					width: 41.7%;
					display: flex;
					flex-flow: row wrap;

					a {
						&.brand-item {
							display: inline-block;
							margin: 0 17.8% 20px 0;
						}

						.brand-logo-wrapper {
							display: inline-flex;
							justify-content: center;
							align-items: center;
							width: 90px;
							height: 40px;
							overflow: hidden;
							padding: 5px;
							border-radius: 5px;
							border: 1px solid #e8e8e8;

							img {
								max-height: 30px;
								max-width: 80px;
								width: auto;
								height: auto;
							}
						}

						&:nth-child(3n + 1) {
							margin: 0;
						}

						&:hover {
							.brand-logo-wrapper {
								border-color: black;
							}
						}
					}

					.top-brands-title {
						display: block;
						width: 100%;
						font-family: 'Play', 'Commissioner', sans-serif;
						font-weight: 700;
						font-size: 16px;
						margin-bottom: 30px;
						margin-right: 0 !important;
					}

					.view-all-button {
						display: flex;
						width: 100%;
						height: 40px;
						border: 1px solid black;
						justify-content: center;
						align-items: center;
						margin-right: 0 !important;
						font-size: 16px;
						font-family: 'Play', 'Commissioner', sans-serif;
						font-weight: 700;
						margin: 30px 0;
						transition: 0.1s;

						&:hover {
							background: black;
							color: white;
						}
					}
				}
			}

			.info-item {
				margin-top: 30px;

				.item-title {
					display: block;
					position: relative;
					padding-left: 20px;
					font-weight: 700;
					cursor: pointer;

					.icon {
						display: inline-block;
						position: absolute;
						right: 0;

						svg.close-info {
							display: none;
						}

						@media (max-width: 980px) {
							right: 18px;
						}
					}

					&.active {
						svg.close-info {
							display: inline-block;
						}

						svg.open-info {
							display: none;
						}
					}

					@media (max-width: 980px) {
						margin: 0;
						padding: 14px 18px;
						font-size: 16px;
					}
				}

				.info-sub-menu {
					display: none;
					padding: 15px 0;

					&.active {
						display: block;
					}
				}
			}
		}

		&:hover {
			& > a {
				color: #d71e26;
			}

			.sub-menu {
				display: block;
				top: 100%;
				left: 0;

				.sub-wrapper {
					background: white;
					display: flex;
					max-width: 1400px;
					margin: 0 auto;
					padding: 55px 95px;
					align-items: flex-start;
					box-shadow: 2px 7px 12px 0px rgba(153, 153, 153, 1);

					@media (max-width: 1420px) {
						box-shadow: none;
					}

					a {
						display: block;
						font-size: var(--regsize);
						font-weight: 400;
						margin-bottom: 20px;
					}

					.all-brands-part {
						width: 58.3%;
						display: flex;
						flex-flow: row wrap;
						padding-right: 15px;
						box-shadow: inset 0 -7px 10px -10px #ccc;

						a {
							width: 200px;
							white-space: normal;

							&:hover {
								font-weight: 700;
							}
						}
					}

					.top-brands-part {
						width: 41.7%;
						display: flex;
						flex-flow: row wrap;

						a {
							&.brand-item {
								display: inline-block;
								margin: 0 17.8% 20px 0;
							}

							.brand-logo-wrapper {
								display: inline-flex;
								justify-content: center;
								align-items: center;
								width: 90px;
								height: 40px;
								overflow: hidden;
								padding: 5px;
								border-radius: 5px;
								border: 1px solid #e8e8e8;

								img {
									max-height: 30px;
									max-width: 80px;
									width: auto;
									height: auto;
								}
							}

							.sport-logo-wrapper {
								width: 120px;
								height: 64px;
								padding: 2px;

								img {
									max-height: 50px;
									max-width: 56px;
								}
							}

							&:nth-child(3n + 1) {
								margin: 0;
							}

							&:hover {
								.brand-logo-wrapper {
									border-color: black;
								}
							}
						}

						.top-brands-title {
							display: block;
							width: 100%;
							font-family: 'Play', 'Commissioner', sans-serif;
							font-weight: 700;
							font-size: 16px;
							margin-bottom: 30px;
							margin-right: 0 !important;
						}

						.view-all-button {
							display: flex;
							width: 100%;
							height: 40px;
							border: 1px solid black;
							justify-content: center;
							align-items: center;
							margin-right: 0 !important;
							font-size: 16px;
							font-family: 'Play', 'Commissioner', sans-serif;
							font-weight: 700;
							margin: 30px 0;
							transition: 0.1s;

							&:hover {
								background: black;
								color: white;
							}
						}

						.horizontal-grid {
							display: flex;
							column-gap: 15px;

							.top-brands-title {
								text-align: center;
								margin-bottom: 15px;
							}

							a {
								&.brand-item {
									display: block;
									margin: 0;
								}
							}

							.view-all-button {
								display: flex;
								width: max-content;
								padding: 20px;
								margin: 20px auto !important;
							}
						}

						.generic-view-item {
							width: 31.333%;
							margin-right: 2%;

							.brand-logo-wrapper {
								border: none !important;
								max-width: 100%;
								width: 100%;
								height: auto;
								max-height: unset;

								img {
									max-width: 100%;
								}
							}
						}
					}

					.top-brands-part:has(.sport-logo-wrapper) a.brand-item {
						margin-right: 15px;

						&:last-child {
							margin-right: 0;
						}
					}
				}

				.info-item {
					margin-top: 30px;

					.item-title {
						display: block;
						position: relative;
						padding-left: 20px;
						font-weight: 700;
						cursor: pointer;

						.icon {
							display: inline-block;
							position: absolute;
							right: 0;

							svg.close-info {
								display: none;
							}

							@media (max-width: 980px) {
								right: 18px;
							}
						}

						&.active {
							svg.close-info {
								display: inline-block;
							}

							svg.open-info {
								display: none;
							}
						}

						@media (max-width: 980px) {
							margin: 0;
							padding: 14px 18px;
							font-size: 16px;
						}
					}

					.info-sub-menu {
						display: none;
						padding: 15px 0;

						&.active {
							display: block;
						}
					}
				}

				@media (max-width: 1260px) {
					width: 100%;
					max-width: unset;
				}

				@media (max-width: 980px) {
					padding: 13.5px 0 0 0;
				}
			}
		}

		&.item-has-children {
			&:hover > a {
				background: white;
			}
		}
	}
}

.mobile-main-menu {
	display: flex;
	flex-wrap: wrap;
	height: 100%;

	.menu-item {
		padding: 0 2px;

		a {
			color: black;
			font-family: 'Commissioner', sans-serif;
			font-weight: 500;
			display: inline-block;
		}

		&.active {
			a.main-item {
				color: #d71e26;
			}
		}

		& > a {
			display: inline-flex;
			justify-content: center;
			align-items: center;
			padding: 9px 11px;
			height: 100%;
			font-weight: 700;
			font-size: var(--regsize);
			color: white;
			text-transform: uppercase;
			cursor: pointer;

			@media (max-width: 980px) {
				font-size: 14px;
			}
		}

		.sub-menu {
			display: none;
			position: absolute;
			width: 100%;
			top: 0;
			left: 0;

			.sub-wrapper {
				background: white;
				display: flex;
				max-width: 1200px;
				margin: 0 auto;
				padding: 55px 95px;
				align-items: flex-start;

				a {
					display: block;
					font-size: var(--regsize);
					font-weight: 400;

					@media (max-width: 980px) {
						margin: 0;
						padding: 11.5px 18px;
						font-size: 16px;
					}
				}

				.all-brands-part {
					max-height: 65vh;
					height: 100%;
					overflow-y: auto;
					width: 58.3%;
					display: flex;
					flex-flow: row wrap;
					margin-right: 15px;
					row-gap: 10px;
					justify-content: flex-start;

					@media (max-width: 1420px) {
						max-height: 100%;
						margin-right: 0px;
						padding-right: 15px;
						overflow-y: unset;
					}

					&::-webkit-scrollbar {
						width: 10px;
					}

					/* Track */
					&::-webkit-scrollbar-track {
						background: #f1f1f1;
					}

					/* Handle */
					&::-webkit-scrollbar-thumb {
						background: #888;
					}

					/* Handle on hover */
					&::-webkit-scrollbar-thumb:hover {
						background: #555;
					}

					// a {
					// 	width: 33.3%;

					// 	&:hover {
					// 		font-weight: 700;
					// 	}
					// }
				}

				.menu-subparents {
					position: relative;
					white-space: nowrap;
					cursor: default;
					width: 33.33%;
					// background-color: #0154ff;

					.menu-subparents-titles {
						font-weight: 700;
						margin-bottom: 20px;
					}

					.menu-subparents-see-all {
						font-weight: 700;
						text-decoration: underline;
					}
				}

				.top-brands-part {
					width: 41.7%;
					display: flex;
					flex-flow: row wrap;

					a {
						&.brand-item {
							display: inline-block;
							margin: 0 17.8% 20px 0;
						}

						.brand-logo-wrapper {
							display: inline-flex;
							justify-content: center;
							align-items: center;
							width: 90px;
							height: 40px;
							overflow: hidden;
							padding: 5px;
							border-radius: 5px;
							border: 1px solid #e8e8e8;

							img {
								max-height: 30px;
								max-width: 80px;
								width: auto;
								height: auto;
							}
						}

						&:nth-child(3n + 1) {
							margin: 0;
						}

						&:hover {
							.brand-logo-wrapper {
								border-color: black;
							}
						}
					}

					.top-brands-title {
						display: block;
						width: 100%;
						font-family: 'Play', 'Commissioner', sans-serif;
						font-weight: 700;
						font-size: 16px;
						margin-bottom: 30px;
						margin-right: 0 !important;
					}

					.view-all-button {
						display: flex;
						width: 100%;
						height: 40px;
						border: 1px solid black;
						justify-content: center;
						align-items: center;
						margin-right: 0 !important;
						font-size: 16px;
						font-family: 'Play', 'Commissioner', sans-serif;
						font-weight: 700;
						margin: 30px 0;
						transition: 0.1s;

						&:hover {
							background: black;
							color: white;
						}
					}
				}
			}

			.info-item {
				margin-top: 30px;

				.item-title {
					display: block;
					position: relative;
					padding-left: 20px;
					font-weight: 700;
					cursor: pointer;

					.icon {
						display: inline-block;
						position: absolute;
						right: 0;

						svg.close-info {
							display: none;
						}

						@media (max-width: 980px) {
							right: 18px;
						}
					}

					&.active {
						svg.close-info {
							display: inline-block;
						}

						svg.open-info {
							display: none;
						}
					}

					@media (max-width: 980px) {
						margin: 0;
						padding: 14px 18px;
						font-size: 16px;
					}
				}

				.info-sub-menu {
					display: none;
					padding: 15px 0;

					&.active {
						display: block;
					}
				}
			}
		}

		&:hover {
			.sub-menu {
				// display: block;
				// top: 100%;
				// left: 0;

				.sub-wrapper {
					background: white;
					display: flex;
					max-width: 1400px;
					margin: 0 auto;
					padding: 55px 95px;
					align-items: flex-start;
					box-shadow: 2px 7px 12px 0px rgba(153, 153, 153, 1);

					@media (max-width: 1420px) {
						box-shadow: none;
					}

					a {
						display: block;
						font-size: var(--regsize);
						font-weight: 400;
					}

					.all-brands-part {
						width: 58.3%;
						display: flex;
						flex-flow: row wrap;
						padding-right: 15px;

						a {
							width: 200px;
							white-space: normal;
						}
					}

					.top-brands-part {
						width: 41.7%;
						display: flex;
						flex-flow: row wrap;

						a {
							&.brand-item {
								display: inline-block;
								margin: 0 17.8% 20px 0;
							}

							.brand-logo-wrapper {
								display: inline-flex;
								justify-content: center;
								align-items: center;
								width: 90px;
								height: 40px;
								overflow: hidden;
								padding: 5px;
								border-radius: 5px;
								border: 1px solid #e8e8e8;

								img {
									max-height: 30px;
									max-width: 80px;
									width: auto;
									height: auto;
								}
							}

							.sport-logo-wrapper {
								width: 120px;
								height: 64px;
								padding: 2px;

								img {
									max-height: 50px;
									max-width: 56px;
								}
							}

							&:nth-child(3n + 1) {
								margin: 0;
							}

							&:hover {
								.brand-logo-wrapper {
									border-color: black;
								}
							}
						}

						.top-brands-title {
							display: block;
							width: 100%;
							font-family: 'Play', 'Commissioner', sans-serif;
							font-weight: 700;
							font-size: 16px;
							margin-bottom: 30px;
							margin-right: 0 !important;
						}

						.view-all-button {
							display: flex;
							width: 100%;
							height: 40px;
							border: 1px solid black;
							justify-content: center;
							align-items: center;
							margin-right: 0 !important;
							font-size: 16px;
							font-family: 'Play', 'Commissioner', sans-serif;
							font-weight: 700;
							margin: 30px 0;
							transition: 0.1s;

							&:hover {
								background: black;
								color: white;
							}
						}

						.horizontal-grid {
							display: flex;
							column-gap: 15px;

							.top-brands-title {
								text-align: center;
								margin-bottom: 15px;
							}

							a {
								&.brand-item {
									display: block;
									margin: 0;
								}
							}

							.view-all-button {
								display: flex;
								width: max-content;
								padding: 20px;
								margin: 20px auto !important;
							}
						}

						.generic-view-item {
							width: 31.333%;
							margin-right: 2%;

							.brand-logo-wrapper {
								border: none !important;
								max-width: 100%;
								width: 100%;
								height: auto;
								max-height: unset;

								img {
									max-width: 100%;
								}
							}
						}
					}

					.top-brands-part:has(.sport-logo-wrapper) a.brand-item {
						margin-right: 15px;

						&:last-child {
							margin-right: 0;
						}
					}
				}

				.info-item {
					margin-top: 30px;

					.item-title {
						display: block;
						position: relative;
						padding-left: 20px;
						font-weight: 700;
						cursor: pointer;

						.icon {
							display: inline-block;
							position: absolute;
							right: 0;

							svg.close-info {
								display: none;
							}

							@media (max-width: 980px) {
								right: 18px;
							}
						}

						&.active {
							svg.close-info {
								display: inline-block;
							}

							svg.open-info {
								display: none;
							}
						}

						@media (max-width: 980px) {
							margin: 0;
							padding: 14px 18px;
							font-size: 16px;
						}
					}

					.info-sub-menu {
						display: none;
						padding: 15px 0;

						&.active {
							display: block;
						}
					}
				}

				@media (max-width: 1260px) {
					width: 100%;
					max-width: unset;
				}

				// @media (max-width: 980px) {
				// 	padding: 13.5px 0 0 0;
				// }
			}
		}

		&.item-has-children {
			&:hover > a {
				background: white;
			}
		}
	}

	@media (max-width: 1420px) {
		width: 100%;
		padding: 0 10px;

		.menu-item .sub-menu .sub-wrapper .menu-subparents {
			width: 100%;
		}

		.menu-subparents-titles {
			margin-left: 15px;
			margin-bottom: 6px !important;
			width: calc(100% - 40px) !important;
		}

		.menu-subparents-see-all {
			display: none !important;
		}

		.subparents-has-children:after {
			content: '';
			position: absolute;
			right: 0px;
			top: 0px;
			height: 43px;
			width: 12px;
			background-image: url(../images/sub-menu-right-arrow.svg);
			background-repeat: no-repeat;
			background-size: contain;
			background-position: center;
			transform: rotate(270deg);
		}

		.menu-subparents.subparents-has-children {
			.menu-subchildren {
				display: none !important;
				padding-left: 20px;
			}

			&.active {
				&.subparents-has-children:after {
					transform: rotate(0);
				}

				.menu-subchildren {
					display: block !important;
				}
			}
		}

		.menu-item {
			width: 100%;
			padding: 5px 0;
			position: relative;
			cursor: pointer;

			a {
				width: calc(100% - 60px);

				&.active {
					color: #d71e26;
				}
			}

			& > a {
				justify-content: flex-start;
				color: black;
				font-size: 14px;
				height: auto;
				padding: 15px 20px;
			}

			&.item-has-children:after {
				content: '';
				position: absolute;
				right: 20px;
				top: 0;
				height: 60px;
				width: 12px;
				background-image: url(../images/sub-menu-right-arrow.svg);
				background-repeat: no-repeat;
				background-size: contain;
				background-position: center;
				transform: rotate(270deg);
			}

			&:hover {
				.sub-menu {
					// display: none;
					// padding: 0;

					.sub-wrapper {
						padding: 0 20px;

						.all-brands-part {
							height: 100%;
							flex-flow: column nowrap;
							width: 100%;
							padding-right: 0;
						}
					}
				}
			}

			.sub-menu {
				position: unset;

				.sub-wrapper {
					padding: 0 20px;

					.top-brands-part {
						display: none !important;
					}

					.all-brands-part {
						flex-flow: column nowrap;
						width: 100%;
						padding: 0px;
						row-gap: 0px;

						.active > a.menu-subparents-titles {
							color: #d71e26;
						}

						a {
							display: block;
							padding: 11.5px 18px;
							width: 100% !important;
							font-size: 14px;
							margin-left: 15px;
							white-space: normal;

							// text-wrap: balance;
							&.active {
								color: #d71e26;
							}

							&:nth-last-child(1) {
								margin: 0;
							}
						}
					}
				}
			}

			&.active {
				&.item-has-children:after {
					transform: rotate(0);
				}

				.sub-menu {
					display: block;
				}
			}
		}
	}
}

#external li a {
	font-family: 'Commissioner', sans-serif;
	font-weight: 700;
}

#user-options {
	text-align: left;
	padding-left: 18px;
	border-bottom: solid 1px #5b5b5b;
	max-width: 155px;
	margin: auto;
}

#user-options li {
	display: flex;
	align-items: center;
}

#user-options a {
	font-size: 13px;
}

#user-options span {
	margin-right: 10px;
}

#lang-flag {
	width: 27px;
	height: 27px;
	background-position: center;
	background-repeat: no-repeat;
	display: inline-block;
	position: relative;
	top: 7px;
	right: -5px;
}

.lang-gr {
	// background-image: url(../images/lang-gr.svg);
}

.lang-en {
	// background-image: url(../images/lang-en.svg);
}

#lang-name {
	font-family: 'Commissioner', sans-serif;
	font-size: 12px;
	display: flex;
	align-items: center;
	gap: 2px;

	img {
		width: 20px;
		height: 20px;
	}

	span {
		color: #b5bac3;

		&::after {
			content: '^';
			display: inline-block;
			transform: rotate(180deg);
		}
	}
}

#lang-switch {
	text-decoration: underline;
	background: none;
	border: none;
	padding: 0;
	cursor: pointer;
}

#lang-switch:hover,
#lang-switch:focus {
	color: #fb4401;
}

#close-modal {
	text-align: right;
	position: absolute;
	right: 0;
	cursor: pointer;
	padding: 5px 18px 5px 5px;
}

h1 {
	font-family: 'Commissioner', sans-serif;
	font-weight: 700;
	font-size: var(--bigsize);
	margin: 0;
}

.page-heading {
	font-family: 'Commissioner', sans-serif;
	font-weight: 700;
	letter-spacing: -0.44px;
	font-size: 22px;
	text-align: center;
	line-height: 26px;
	margin: 0;
}

h2 {
	font-family: 'Commissioner', sans-serif;
	font-weight: 700;
	font-size: 20px;
	line-height: var(--mediumsize);
	text-align: center;
	margin: 0;
}

h3 {
	font-family: 'Commissioner', sans-serif;
	font-weight: 700;
	font-size: var(--smallsize);
	line-height: 18px;
	margin: 0 0 6px;
}

h4 {
	font-family: 'Commissioner', sans-serif;
	font-weight: 700;
	font-size: var(--microsize);
	line-height: 17px;
	margin: 0 0 20px;
	text-align: center;
}

.top-page {
	position: relative;
	z-index: 200;
	padding-top: 37px;
}

#main-wrapper,
.main-wrapper {
	@media (max-width: 767px) {
		&.home {
			margin-top: 0;
		}
	}
}

#anchor-links {
	text-align: center;
	margin: 24px 0 20px;
	font-family: 'Commissioner', sans-serif;
	font-weight: 500;
	color: #040707;
}

#scroll-to-top {
	cursor: pointer;
	margin: 0 8px;
}

#anchor-links a {
	font-size: 13px;
	margin: 0 25px;
	font-family: 'Commissioner', sans-serif;
	font-weight: 500;
	color: #040707;
}

#anchor-links a.active,
#anchor-links span.active {
	color: #fc4400;
	border-bottom: solid 1px #fc4400;
}

#anchor-links a:hover,
#anchor-links a:focus,
#anchor-links span:hover,
#anchor-links span:focus {
	color: #fc4400;
}

#anchor-links.sticky {
	position: fixed;
	left: 0;
	top: 59px;
	right: 0;
	background: #b6bac0;
	z-index: 2000;
	padding: 38px 0 7px;
	margin: 0;
	box-shadow: 0px 10px 30px #76767629;
}

.modal-dialog-overlay {
	position: fixed;
	inset: 0;
	z-index: 10000;
	background: rgba(0, 0, 0, 0.6);
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: auto;

	.modal-dialog {
		z-index: 10000;
		position: relative;
		animation: fadeIn 0.5s;
		border-radius: 4px;

		@keyframes fadeIn {
			0% {
				opacity: 0;
			}

			100% {
				opacity: 1;
			}
		}

		.close-modal-btn {
			position: absolute;
			top: 20px;
			right: 20px;
			cursor: pointer;

			@media (max-width: 768px) {
				top: 15px;
				right: 15px;
			}
		}

		.footer {
			display: flex;
			justify-content: center;

			button {
				padding: 0 70px;
				height: 50px;
			}
		}
	}
}

.discounts-poupup-modal {
	@media (max-width: 768px) {
		.modal-dialog-overlay {
			position: fixed;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: 10000;
			background: rgba(0, 0, 0, 0.6);
			display: flex;
			justify-content: center;
			align-items: center;
			overflow: auto;
		}
	}

	@media (max-width: 575px) {
		.modal-dialog-overlay {
			position: fixed;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: 10000;
			background: rgba(0, 0, 0, 0.6);
			display: flex;
			justify-content: center;
			align-items: flex-end;
			overflow: auto;
		}
	}

	.discounts-popup {
		max-width: 575px;
		width: 100%;
		margin: 20px 0;
		background-color: #fff;

		@media (max-width: 575px) {
			margin: 0;
			border-radius: 20px 20px 0 0;
		}

		.discounts-popup-container {
			padding: 40px 20px 40px;
			max-width: 450px;
			margin: auto;

			@media (max-width: 420px) {
				padding: 40px 20px 40px;
			}

			.header {
				margin-bottom: 45px;

				.title {
					font-size: 24px;
					font-family: 'Roboto Condensed';
					font-weight: 600;

					@media (max-width: 420px) {
						margin-left: 1px;
					}

					@media (max-width: 360px) {
						font-size: 20px;
					}

					span {
						font-size: 34px;
					}
				}
			}

			.body {
				h4 {
					font-size: 16px;
					font-family: 'Roboto Condensed';
					margin: 0;
					text-align: left;
				}

				.discounts-features {
					margin: 20px 0 30px;
					display: flex;
					flex-direction: column;
					gap: 14px;

					li {
						display: flex;
						align-items: center;
						justify-content: space-between;
						gap: 20px;
						margin-bottom: 20px;

						h5 {
							font-size: 17px;
							margin: 0;
							text-align: left;
							font-weight: 400;
						}

						span {
							font-size: 17px;
							color: #000;
						}
					}
				}

				input::placeholder {
					opacity: 1;
					color: #4d4d4d;
				}

				.checkboxes-container {
					margin: 20px 0;

					.custom-checkbox {
						font-size: 12px !important;
					}
				}
			}

			// .footer {
			// 	width: 100%;
			// 	button {
			// 		width: 100% !important;
			// 		max-width: 100% !important;
			// 		height: 50px;
			// 	}
			// }
		}
	}
}

/* HOMEPAGE */
.light-gray {
	background-color: rgba(244, 246, 249, 0.5);
}

#home-slider {
	margin-top: -60px;
	padding-bottom: 34px;
}

#home-slider .content-wrapper {
	max-width: 780px;
	padding: 0;
}

.raffle-carousel .owl-item {
	padding: 60px 90px;
}

.raffle-carousel-main,
.raffle-registration {
	background-color: #f4f6f9;
	width: 100%;
	border-radius: 25px;
}

.raffle-carousel-main {
	box-shadow: 2px 7px 70px #434b5759;
	padding: 22px 35px 25px;
	position: relative;
}

.raffle-carousel-main .brand-icon {
	margin-bottom: 20px;
}

.raffle-registration {
	height: 101px;
	margin-top: 15px;
	box-shadow: 2px 7px 29px #434b5759;
	padding: 20px 20px 17px;
	text-align: center;
}

.raffle-carousel-name {
	margin: 25px 0 8px;
	font-family: 'Commissioner', sans-serif;
	font-weight: 700;
	font-size: 22px;
}

.raffle-carousel-name a {
	font-family: 'Commissioner', sans-serif;
	font-weight: 700;
	font-size: 22px;
	color: #040707;
}

.raffle-carousel-price {
	color: #fc4400;
	font-size: 22px;
}

.raffle-carousel-img {
	display: flex;
	justify-content: center;
	align-items: center;
}

.raffle-carousel .owl-nav button {
	font-size: 0 !important;
}

.slider-go-to-prod a {
	width: 60px;
	height: 60px;
	border: solid 1px #fc4400;
	border-radius: 50%;
	position: absolute;
	right: 50px;
	bottom: 25px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.slider-go-to-prod a:hover {
	background-color: #fc4400;
}

.slider-go-to-prod a:hover svg path {
	fill: #fff;
}

.registation-heading {
	font-size: 15px;
}

.registation-table {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 10px;
}

.registation-cell {
	font-size: 10px;
}

.registation-cell span {
	font-family: 'Commissioner', sans-serif;
	font-weight: 700;
	font-size: 20px;
	display: block;
	border-left: solid 1px #040707;
	padding: 2px 12px;
	width: 50px;
}

.registation-cell:first-child span {
	border-left: none;
}

.owl-carousel .owl-item img {
	margin: auto;
	max-width: 100%;
	height: 300px;
	object-fit: contain;
}

.raffle-carousel .owl-dots {
	text-align: center;
	margin-top: -40px;
	position: relative;
}

.raffle-carousel .owl-dot {
	background: #f1f1f1 !important;
	width: 16px;
	height: 16px;
	margin: 0 5px;
	border-radius: 50%;
	cursor: pointer;
}

.raffle-carousel .owl-dot.active {
	background: #fc4400 !important;
}

.raffle-carousel .owl-nav button {
	position: absolute;
	width: 20px;
	height: 20px;
	top: 225px;
	background-image: url(../images/slider-arrow.svg) !important;
	background-repeat: no-repeat !important;
	background-position: center !important;
}

.raffle-carousel .owl-nav button.owl-prev {
	transform: rotate(-180deg);
	left: 120px;
}

.raffle-carousel .owl-nav button.owl-next {
	right: 120px;
}

.raffle-category {
	padding: 34px 0 45px;
}

.raffle-products {
	margin-top: 35px;
}

.raffle-prod {
	display: flex;
	align-items: center;
	margin-bottom: 35px;
	position: relative;
}

.raffle-prod a {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	z-index: 10;
}

.raffle-prod-img {
	background-color: #f4f6f9;
	border-radius: 25px;
	box-shadow: 2px 7px 29px #434b5759;
	width: 108px;
	height: 108px;
	padding: 6px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
}

.raffle-prod-img img {
	max-width: 100%;
}

.raffle-prod-img .brand-icon {
	margin-bottom: 4px;
}

.raffle-prod-details {
	margin-left: 42px;
	flex: 1;
}

.default-btn {
	width: 127px;
	height: 25px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 11px;
	line-height: 13px;
	border-radius: 13px;
	box-shadow: 2px 7px 29px #434b5759;
}

.default-btn span {
	font-family: 'Commissioner', sans-serif;
	font-weight: 700;
	margin-left: 5px;
}

.black-btn {
	color: #fff;
	background-color: #040707;
}

.white-btn {
	color: #040707;
	background-color: #fff;
}

.go-to-prod {
	margin-left: auto;
	width: 77px;
	height: 72px;
	background-image: url(../images/arrow-right-shadow.svg);
	background-position: center;
	background-repeat: no-repeat;
}

.raffle-prod:hover > .go-to-prod {
	-webkit-animation: bounceright 0.3s alternate ease 4 forwards;
	animation: bounceright 0.3s alternate ease 4 forwards;
}

.raffle-prod:hover > .go-to-prod {
	display: block;
}

.raffle-finished .raffle-prod-details,
.raffle-finished img {
	opacity: 0.3;
}

#recent {
	background-color: #f4f6f9;
}

#recent .raffle-prod-img {
	background-color: #fff;
}

.load-more {
	text-align: center;
	font-family: 'Commissioner', sans-serif;
	font-weight: 700;
	font-size: 16px;
	cursor: pointer;
	margin-top: 58px;
	display: none;
}

.load-more.show {
	display: block;
}

.load-more span {
	display: block;
	margin-top: 10px;
}

.add-wishlist {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;

	svg path {
		fill: none;
		stroke: #000;
	}

	&.active svg path {
		fill: red;
		stroke: red;
	}
}

.add-new-wishlist-form {
	display: flex;
	flex-direction: column;

	button {
		text-align: center;
	}
}

#combine-products,
#popular-categories,
#personalised-products,
#best-sellers-carousel {
	background: #f1f2f3;
	padding: 62px 0;
}

/* PRODUCT PAGE */
.product-page {
	.top-wrapper {
		display: flex;
		justify-content: space-between;
		padding-bottom: 70px;
		margin-top: 28px;

		@media (max-width: 920px) {
			flex-flow: column nowrap;
			padding: 0 22px;
		}

		@media (max-width: 480px) {
			padding: 0;
		}
	}

	.images-area #images-control .images-column {
		.special-labels {
			display: none;
		}

		.black-friday {
			position: absolute;
			top: 0px;
			left: 0px;
			z-index: 10;
			background-color: #000000;
			color: white;
			padding: 2px 4px;
			font-size: var(--microsize);
			font-family: Play;
			font-weight: 600;
			text-transform: uppercase;
		}

		.product-statuses-tags {
			position: absolute;
			top: 60px;
			left: 20px;
			z-index: 10;
			color: white;
			font-size: var(--microsize);
			font-family: Play;
			font-weight: 600;
			text-transform: uppercase;
			display: flex;
			flex-direction: column;
			gap: 4px;

			span {
				width: fit-content;
				padding: 0 4px;
			}
		}

		// Show special labels on 1st image only.
		.img-1 {
			position: relative;

			.special-labels {
				display: block;
				position: absolute;
				top: 35px;
				left: 20px;
				z-index: 1;

				.label-item span {
					height: 20px;
					padding: 0 4px;
					display: inline-flex;
					align-items: center;
					background: #d71e26;
					color: white;
					font-size: var(--microsize);
					font-family: Play;
					font-weight: 700;

					&.empty-item {
						padding: 0;
					}
				}
			}
		}

		@media (max-width: 480px) {
			padding-right: 0;

			.image-item {
				border: none;
			}
		}

		.zoom-image-container {
			display: none;
			@media (max-width: 1024px) {
				display: block;
			}
		}

		.default-image-container {
			display: block;
			@media (max-width: 1024px) {
				display: none;
			}
		}

		img {
			@media (max-width: 1024px) {
				cursor:
					url('../images/zoom.svg') 24 24,
					zoom-in;

				&.zoom-active {
					cursor:
						url('../images/zoom-out.svg') 24 24,
						zoom-out;
				}
			}
		}
	}

	.product-info {
		max-width: 490px;
		margin: 0 0 0 15px;
		width: 100%;

		.product-title {
			font-weight: 700;
			margin: 28px 0 12px 0;
			line-height: 1;
			font-family: Play;

			h1 {
				font-size: var(--mediumsize);
			}

			.add-wishlist {
				display: none;
			}

			@media (max-width: 920px) {
				margin: 15px 0 5px 0;
				display: flex;
				justify-content: space-between;

				.add-wishlist {
					display: inline-block;
					border: 0;
					width: auto;
					height: auto;
					margin-left: 10px;
				}
			}
		}

		.product-code {
			font-size: var(--microsize);
			color: #6a727e;
			margin-bottom: 30px;
			font-family: Play;

			@media (max-width: 920px) {
				font-size: 14px;
				margin: 10px 0 15px 0;
			}
		}

		.price {
			font-family: Play;
			font-weight: 700;
			font-size: var(--smallsize);
			margin-bottom: 25px;

			span {
				color: #6a727e;
				font-weight: 700;
				font-size: calc(2px + var(--microsize));
				margin-left: 10px;
				text-decoration: line-through;

				@media (max-width: 767px) {
					margin: 0 0 0 10px;
				}
			}

			@media (max-width: 767px) {
				margin: 0 0 15px 0;
			}
		}

		.color-container {
			font-family: Play;
			margin-bottom: 35px;
			color: #6a727e;

			.prod-colors {
				margin-top: 12px;

				label {
					width: 25px;
					height: 25px;
					border-radius: 100%;
					box-shadow: 0 0 0 6px white inset;
					background-clip: content-box;
					padding: 3px;
					cursor: pointer;

					&.selected-color {
						outline: 1px solid #000000;
						pointer-events: none;
					}
				}

				input[type='radio']:checked + label {
					outline: 1px solid #000000;
					pointer-events: none;
				}
			}

			@media (max-width: 920px) {
				margin: 0 0 20px 0;
			}
		}

		.sizes {
			display: flex;
			flex-flow: row wrap;
			column-gap: 10px;
			margin: 0 0 15px;

			#sizes-dropdown {
				width: 306px;
				height: 42px;
				position: relative;
				border: 1px solid #b5bac3;
				background: white;
				cursor: pointer;

				span {
					width: 100%;
					height: 100%;
					display: flex;
					align-items: center;
					padding: 0 15px 0 20px;
					cursor: cursor;

					&.short-code {
						display: none;
					}
				}

				.drop-icon {
					position: absolute;
					top: 0;
					right: 15px;
					height: 100%;
					display: inline-flex;
					align-items: center;
					pointer-events: none;
				}

				ul.selects {
					position: absolute;
					background: white;
					width: calc(100% + 2px);
					left: -1px;
					top: calc(100% + 1px);
					z-index: 1;
					max-height: 300px;
					overflow: auto;

					li.size-box {
						width: 100%;
						height: 42px;
						display: flex;
						align-items: center;
						// padding: 0 15px 0 20px;
						font-size: 12px;
						font-weight: 500;
						border: 1px solid #b5bac3;
						border-top: none;
						background-color: white;

						&.disabled {
							background: #f4f4f4 0% 0% no-repeat padding-box;
							color: #ababab;
							box-shadow: none;
							pointer-events: none;
						}

						&:hover {
							background: #8080800d;
						}
					}
				}
			}

			.selected-size {
				font-weight: 700;
				color: #d71e26;
			}

			input[type='radio']:checked + label {
				border: 1px solid #000000;
				background: #f2f2f2;
			}

			.short-code {
				display: none;
			}

			@media (max-width: 767px) {
				margin: 0 0 10px 0;
			}
		}

		.buy-section {
			display: flex;
			flex-wrap: wrap;
			column-gap: 10px;
			margin-bottom: 10px;

			.add-wishlist {
				width: 48px;
				height: 48px;
				border: 1px solid #b5bac3;
				display: inline-flex;
				justify-content: center;
				align-items: center;
			}

			.add-wishlist.active svg path {
				fill: red;
				stroke: red;
			}

			@media (max-width: 920px) {
				.add-wishlist {
					display: none;
				}
			}

			@media (max-width: 480px) {
				// display: none;
			}
		}

		.missing-size {
			display: block;
			width: 100%;
			margin: 0 0 28px 0;

			button {
				display: inline-block;
				text-decoration: underline;
				color: black;
				font-weight: 500;
				font-family: Play;
				font-size: var(--microsize);
			}
		}

		.store-points {
			margin-bottom: 35px;
			cursor: default;

			.point {
				display: flex;
				align-items: center;
				justify-content: flex-start;
				padding: 3px 0;
				color: #6a727e;

				img {
					margin-right: 8px;
				}
			}

			@media (max-width: 920px) {
				margin-bottom: 0;
			}

			@media (max-width: 480px) {
				margin-top: 24px;
			}
		}

		.description-section {
			margin: 0 0 30px 0;

			.drop-item {
				width: 100%;
				display: block;
				border-bottom: 1px solid #b5bac3;

				.drop-title {
					display: block;
					font-family: Play;
					font-weight: 700;
					font-size: calc(2px + var(--microsize));
					cursor: pointer;
					position: relative;
					padding: 18px 0 20px;
					text-transform: uppercase;

					&:before {
						position: absolute;
						content: '';
						width: 11px;
						height: 100%;
						background-repeat: no-repeat;
						background-size: contain;
						background-position: center;
						background-image: url(../images/open-text.svg);
						top: 0;
						right: 4px;
					}

					&:after {
						position: absolute;
						content: '';
						width: 11px;
						height: 100%;
						background-repeat: no-repeat;
						background-size: contain;
						background-position: center;
						background-image: url(../images/close-text.svg);
						top: 0;
						right: 4px;
						display: none;
					}

					&.active {
						&:before {
							display: none;
						}

						&:after {
							display: block;
						}
					}
				}

				.drop-subtext {
					display: none;
					font-size: var(--microsize) !important;
					color: black !important;
					display: none;
					margin-top: -17px;
				}
			}

			@media (max-width: 920px) {
				margin-bottom: 25px;
			}
		}

		@media (max-width: 920px) {
			margin-top: 35px;
		}
	}

	.product-info {
		.product-manufactor {
			border: 1px solid #e8e8e8;
			border-radius: 5px;
			display: inline-block;

			img {
				padding: 9px 23px;
			}

			span {
				display: block;
				background-color: #fff;
				border-radius: 10px;
				font-size: 24px;
				font-weight: bold;
				padding: 20px 30px;
			}
		}

		.add-to-cart-mobile {
			display: none;
		}

		.prod-colors.flex-col {
			label {
				position: relative;

				&:before {
					content: '';
					z-index: 1;
					width: 13px;
					height: 13px;
					position: absolute;
					top: calc(50% - 7.5px);
					left: calc(50% - 7.5px);
					display: block;
					border-radius: 100%;
					border: 2px solid rgba(230, 230, 230, 0.5);
					pointer-events: none;
				}
			}
		}

		.low-stock-notification {
			width: 306px;
			height: 28px;

			span {
				background: #ffefc3;
				font-size: 14px;
				width: 100%;
				height: 100%;
				display: grid;
				place-items: center;
			}
		}
	}

	.add-to-cart {
		padding: 10px 20px;
		height: 48px;
		font-family: Play;
		font-weight: 700;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		background: black;
		font-size: calc(2px + var(--microsize));
		color: white;
		cursor: pointer;
		width: 249px;

		svg {
			display: block;
			margin-left: 10px;
		}
	}

	.combine-products-mobile {
		display: none;
	}

	.cta-bar {
		display: none;
	}

	@media (max-width: 920px) {
		.images-area,
		.product-info {
			margin-left: auto;
			margin-right: auto;
		}

		.cta-bar {
			background-color: #fff;
			padding: 22px;
			position: sticky;
			bottom: 0;
			z-index: 2;
			display: block;

			.initial-view {
				.product-info,
				.actions {
					display: flex;
					align-items: center;
				}

				.product-info {
					padding: 0;
					margin: 0;
					margin-bottom: 10px;

					.left-part {
						width: 50%;

						.product-title {
							margin: 0;
						}
					}

					.right-part {
						width: 50%;
						text-align: end;

						.add-wishlist {
							border: none;
							height: unset;
							width: unset;
						}

						.price {
							margin: 0;
						}
					}
				}

				.actions {
					display: flex;
					overflow-x: hidden;

					.action {
						min-width: 130px;
						width: 100%;
						height: 48px;
						border: 1px solid #000;
						display: flex;
						align-items: center;
						justify-content: center;

						&.show-sizes-action {
							background: #000;
							color: #fff;
							font-weight: 700;
							border: none;
							margin-left: 10px;

							svg {
								margin-left: 10px;
							}
						}
					}
				}
			}

			.secondary-view {
				.sizes {
					margin: 0;
					padding: 10px 0px 26px 0px;

					ul {
						display: flex;
						justify-content: start;
						overflow-x: scroll;
						gap: 40px;

						li {
							&.selected-size {
								color: red;
							}

							.full-code {
								padding-bottom: 10px;
								display: block;
								font-weight: bold;
								text-wrap: nowrap;
							}
						}

						.disabled {
							color: #b6bac2;
							pointer-events: none;
						}

						.full-code {
							display: none;
						}

						.short-code {
							display: block;
							font-weight: 700;
							cursor: pointer;
						}

						.selected-size {
							font-weight: 700;
							color: #d71e26;
						}
					}
				}

				.buy-section {
					margin: 0;

					.add-to-cart {
						width: 100%;
					}
				}
			}
		}

		.product-info {
			width: 100%;

			.add-to-cart-desktop {
				display: none;
			}

			.add-to-cart-mobile {
				display: block;
				margin-bottom: 24px;

				.color-container label {
					&.selected-color {
						outline: 1px solid #000000;
						pointer-events: none;
						border-radius: 100%;
					}
				}

				.cta-bar {
					margin: 0 -22px;
				}

				.missing-size {
					margin: 0;
				}

				.low-stock-notification {
					margin-top: 24px;
				}
			}
		}

		.cta-line {
			border-bottom: 1px solid #b5bac3;
		}
	}

	@media (max-width: 480px) {
		.breadcrumbs-row {
			margin-bottom: 0;
		}

		.combine-products-mobile {
			display: block;
			margin-left: -22px; // Added margin in order to override page padding.
			margin-right: -22px;

			#combine-products {
				padding: 36px 0;
			}
		}

		.combine-products-desktop {
			display: none;
		}

		.product-info {
			padding: 0 22px;
			margin-left: 0;
			margin-right: 0;

			.low-stock-notification {
				font-size: 12px;
				width: 100%;
			}
		}
	}

	// modal

	.modal-dialog {
		background: #fff;
		width: 80vw;
		// padding: 40px;

		.size-guide-content {
			.title {
				font-size: 20px;
				font-weight: 500;
				border-bottom: 1px solid lightgray;
				padding: 12px 20px;
				height: 50px;
			}

			.subtitle {
				font-size: 16px;
				font-weight: 800;
				padding: 0 20px;
				margin: 18px 0;
			}

			.table-content {
				padding: 0 20px;
			}

			table {
				th {
				}

				tr {
				}

				td {
					text-align: center;
					word-wrap: break-word;
					border: 1px solid #d1d1d1;
					font-size: 16px;
					padding: 5px;
				}
			}
		}

		.footer {
			margin: 30px 0;
		}
	}
}

#popular-categories {
	@media (max-width: 767px) {
		padding: 40px 0;
	}
}

#best-sellers-carousel {
	@media (max-width: 767px) {
		padding-top: 20px;
	}
}

.combine-title {
	font-family: Play;
	font-weight: 700;
	margin: 0 0 22px 0;
	font-size: var(--mediumsize);
	line-height: 1.2;
	margin-bottom: 0;

	@media (max-width: 920px) {
		font-size: 16px;
	}
}

.combine-products {
	position: relative;
	padding: 0 20px;

	.swiper .swiper-pagination {
		display: none;
	}

	.product-box {
		background: white;
		padding: 25px 20px 0;

		.prod-img {
			margin-bottom: 8px;

			.on-hover {
				position: absolute;
				top: 0;
			}
		}

		@media (max-width: 1280px) {
			// min-width: 160px;
		}
	}
}

#trends-slider {
	max-width: 1432px;
	padding: 70px 22px 25px;
	position: relative;

	h3 {
		font-weight: 700;
		font-family: Play;
		font-size: var(--mediumsize);
		margin-bottom: 15px;

		@media (max-width: 1520px) {
			padding: 0 22px;
		}
	}

	.swiper-slide {
		width: 100%;
		object-fit: cover;
		object-position: center;
		display: block;
	}

	.slide-overlay {
		position: absolute;
		z-index: 1;
		display: flex;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		justify-content: center;
		align-items: center;

		.viewall-button {
			display: block;
			border: 1px solid white;
			color: white;
			font-size: var(--regsize);
			font-family: Play;
			font-weight: 700;
			padding: 12px 25px;
			transition: 0.1s;

			&:hover {
				background: black;
				border-color: black;
			}
		}
	}

	@media (max-width: 1520px) {
		margin: 0;
		padding-top: 60px;
	}

	@media (max-width: 1460px) {
		.swiper-slide img {
			height: 35.5vw;
			min-height: 302px;
			object-fit: cover;
			object-position: center;
		}
	}

	@media (max-width: 860px) {
		margin: 0;
		padding: 40px 0 0 0;
	}
}

#homepage {
	#personalised-products {
		& > .content-wrapper {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-bottom: 20px;

			.combine-title {
				display: inline-block;
				width: auto;
				margin: 0;
			}
		}
	}
}

#popular-categories,
#best-sellers-carousel {
	& > .content-wrapper {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 20px;

		.combine-title {
			display: inline-block;
			width: auto;
			margin: 0;
		}

		@media (max-width: 767px) {
			flex-flow: column nowrap;
			justify-content: flex-start;
			align-items: flex-start;

			.combine-title {
				font-size: var(--bigsize);
				margin-bottom: 15px;
			}
		}
	}
}

#personalised-products {
	background: white;
}

.images-area {
	display: flex;
	width: 100%;
	max-width: 610px;
	height: 730px;
	overflow: auto;

	#images-control {
		width: 100%;
		height: 100%;

		& > div {
			width: 100%;
			height: 100%;
			overflow-y: scroll;
			scroll-behavior: smooth;

			&::-webkit-scrollbar {
				margin-top: 5px;
				width: 0.2em;
				height: 0.2em;
			}

			&::-webkit-scrollbar-thumb {
				background-color: #474747;
				outline: 1px solid #474747;
				border-radius: 10px;
			}

			&.images-column {
				display: flex;
				flex-direction: column;
				gap: 24px;
				flex-flow: row wrap;
				justify-content: space-between;
				position: relative;
				padding-right: 20px;

				.image-item {
					width: 100%;
					position: relative;

					img {
						object-position: center;
						display: block;
						object-fit: contain;
						aspect-ratio: 1;
						width: 100%;
						height: auto;
						border: 1px solid black;
					}

					&:nth-child(2),
					&:nth-child(3) {
						flex: 1;
						height: 282px;
						max-width: 50%;
					}

					@media (max-width: 920px) {
						&:nth-child(2),
						&:nth-child(3) {
							flex: auto;
							height: auto;
							max-width: unset;
						}
					}
				}
			}
		}
	}

	@media (max-width: 1200px) {
		height: 535px;
	}

	@media (max-width: 920px) {
		width: 55%;

		#images-control > div.images-column {
			gap: 10px;

			&:nth-child(n) {
				flex: auto;
				height: unset;
			}
		}
	}

	@media (max-width: 740px) {
		width: 80%;
	}

	@media (max-width: 520px) {
		width: 90%;
	}

	@media (max-width: 480px) {
		position: relative;

		#images-control {
			& > div::-webkit-scrollbar {
				display: none;
			}
		}
	}
}

// breadcrumbs
.breadcrumbs-container {
	border-bottom: 1px solid #b5bac3;

	#breadcrumbs {
		max-width: 1244px;
		padding: 10px 22px;
		margin: auto;
		display: flex;
		font-size: var(--nanosize);
		white-space: nowrap;
		overflow: hidden !important;
		text-overflow: ellipsis;

		.bread-item {
			position: relative;
			padding-right: 5px;
			margin-right: 12px;

			img {
				display: inline;
			}

			a {
				color: #000000;
			}

			&:after {
				content: '>';
				position: absolute;
				display: inline-block;
				left: 100%;
				color: #b5bac3;
			}

			&:nth-last-child(1) {
				color: #6a727e;
				white-space: nowrap;
				overflow: hidden !important;
				text-overflow: ellipsis;

				&:after {
					display: none;
				}
			}
		}
	}
}

#heritage {
	text-align: center;
	margin-top: 40px;
	font-size: 13px;
}

.img-container-double {
	display: flex;
	justify-content: space-between;
	padding: 15px 0 30px;
	flex-wrap: wrap;
}

.img-container-double > div {
	width: 48.25%;
	position: relative;
	overflow: hidden;
}

.img-container-double > div img,
.img-gallery img {
	width: 100%;
}

.img-container-double > div:hover {
	box-shadow: 2px 7px 29px #434b5759;
	border-radius: 50px;
}

.img-container-double > div:hover .zoom-overlay,
.img-gallery > div:hover .zoom-overlay {
	background-color: rgba(244, 246, 248, 0.4);
	content: '';
	background-image: url(../images/plus-icon.svg);
	background-repeat: no-repeat;
	background-position: center;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1;
	opacity: 1;
}

.panzoom {
	position: relative;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.panzoom__viewport {
	position: relative;
	width: 100%;
	height: 100%;
	min-height: 1px;
	margin: auto;
}

.panzoom__content {
	max-width: 100%;
	max-height: 100%;
	object-fit: contain;
	transform: translate3d(0, 0, 0) scale(1);
	transform-origin: 0 0;
	transition: none;
	touch-action: none;
	user-select: none;
}

.is-draggable {
	cursor: move;
	cursor: grab;
}

.is-dragging {
	cursor: grabbing;
}

.img-gallery {
	display: flex;
	justify-content: space-between;
	margin-top: 25px;
	padding: 15px 0 30px;
	flex-wrap: wrap;
}

.img-gallery > div {
	width: 50%;
	position: relative;
	overflow: hidden;
}

ol {
	padding: 0 0 0 8%;
}

ol li::marker {
	font-family: 'Commissioner', sans-serif;
	font-weight: 700;
}

#raffle-ends,
#raffle-starts {
	font-size: 15px;
	padding-left: 8%;
	margin: 30px 0;
}

#raffle-ends span,
#raffle-starts span {
	margin-right: 32px;
	font-family: 'Commissioner', sans-serif;
	font-weight: 500;
}

.product-bottom form {
	max-width: 404px;
	margin: auto;
}

.custom-select {
	position: relative;
	cursor: pointer;
}

.custom-select select {
	display: none;
}

.select-selected {
	font-family: 'Commissioner', sans-serif;
	font-weight: 700;
	font-size: 14px;
	padding: 18.5px 16px 18.5px 30px;
	background-color: #fff;
	box-shadow: 2px 7px 29px #434b5759;
	border-radius: 28px;
}

.select-selected span {
	display: block;
	width: calc(100% - 36px);
	overflow-x: hidden;
}

.select-selected span.new {
	font-family: 'Commissioner', sans-serif;
	font-weight: 500;
}

.select-selected.select-arrow-active {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}

.select-selected:after {
	position: absolute;
	content: '';
	top: 10px;
	right: 10px;
	width: 47px;
	height: 39px;
	background-image: url(../images/button-arrow-orange.svg);
	background-size: cover;
	background-position: center;
}

.select-items div {
	color: #040707;
	padding: 3px 8px;
	cursor: pointer;
	line-height: 21px;
}

.select-items div:hover {
	background: #ff0000;
	color: #fff;
}

.select-items {
	padding: 0 30px 10px;
	max-height: 170px;
	overflow-y: auto;
	overflow-x: hidden;
	position: absolute;
	background: #fff;
	width: 100%;
	border-bottom-right-radius: 28px;
	border-bottom-left-radius: 28px;
	z-index: 100;
}

.select-hide {
	display: none;
}

.same-as-selected {
	background-color: rgba(0, 0, 0, 0.1);
}

@-webkit-keyframes bounceright {
	from {
		-webkit-transform: translateX(0);
	}

	to {
		-webkit-transform: translateX(10px);
	}
}

#go-to-form-wrapper {
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 100;
	text-align: center;
	padding: 20px 15px;
	background: rgba(255, 255, 255, 0.8);
	backdrop-filter: blur(20px);
	-webkit-backdrop-filter: blur(50px);
}

#go-to-form-wrapper.hidden {
	opacity: 0;
	z-index: -1;
}

#go-to-form {
	max-width: 404px;
	margin: 0 auto;
}

.faq-item:not(:last-child) {
	border-bottom: solid 1px rgba(2, 3, 3, 0.07);
}

.faq-heading {
	font-family: 'Commissioner', sans-serif;
	font-weight: 700;
	position: relative;
	padding: 25px 5px;
	cursor: pointer;
}

.faq-heading:after {
	position: absolute;
	content: '';
	top: 15px;
	right: 10px;
	width: 47px;
	height: 39px;
	background-image: url(../images/button-arrow-orange.svg);
	background-size: cover;
	background-position: center;
	transition: 0.3s;
}

.faq-item.active .faq-heading:after {
	transform: rotate(180deg);
}

.faq-content {
	display: none;
	padding: 0 40px 20px 5px;
}

.faq-content .orange {
	color: #ea762e;
}

.faq-content a {
	color: inherit;
	text-decoration: underline;
}

.faq-content a:hover {
	color: #fc4400;
}

/* PRODUCT PAGES */
#popup-top {
	height: 42px;
	display: flex;
	align-items: center;
}

#popup-main {
	padding: 25px 22px 38px;
}

#popup-main .custom-select {
	margin-bottom: 13px;
}

#popup-main .select-selected {
	font-family: 'Commissioner', sans-serif;
	font-weight: 300;
}

#popup-main.has-background {
	border-radius: 25px;
}

#popup-main.checkout.has-background {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}

#popup-top #back-to {
	margin: 0 0 0 13px;
}

#close-popup {
	margin-left: auto;
	cursor: pointer;
	margin-right: 11px;
}

#close-popup.hidden {
	display: none;
}

#close-popup:hover svg path {
	fill: #fff;
	stroke: #fff;
}

#fast-checkout-wrapper {
	margin-top: 25px;
	padding-top: 30px;
	border-top: solid 1px #b6bac0;
}

.option-button {
	background-color: #fff;
	box-shadow: 2px 7px 29px #434b5759;
	border-radius: 50px;
	height: 101px;
	padding: 15px 8px 15px 36px;
	display: flex;
	align-items: center;
	text-align: left;
	width: 290px;
	margin: auto auto 20px;
	cursor: pointer;
}

.option-button:last-of-type {
	margin-bottom: 47px;
}

.button-inner {
	width: 160px;
}

.button-contents {
	display: flex;
	width: 100%;
	align-items: center;
}

.button-icon {
	background-position: center;
	background-repeat: no-repeat;
	margin-right: 18px;
	margin-bottom: auto;
}

.pin-icon {
	width: 22px;
	height: 29px;
	background-image: url(../images/pin-icon.svg);
}

.home-icon {
	width: 26px;
	height: 24px;
	background-image: url(../images/home-icon.svg);
	margin-top: 8px;
}

.form-arrow-right {
	width: 53px;
	height: 48px;
	background-image: url(../images/form-arrow-right.svg);
	background-position: center;
	background-repeat: no-repeat;
	margin: auto;
}

.button-title {
	font-family: 'Commissioner', sans-serif;
	font-weight: 700;
}

.button-shipping-cost {
	font-family: 'Commissioner', sans-serif;
	font-weight: 500;
	color: #5fc993;
	margin-top: 6px;
}

#popup-main .select-selected {
	padding: 12px 16px 12px 30px;
}

#popup-main .select-selected:after {
	top: 4px;
}

.option-button.selected {
	border: 1px solid #fc4400;
}

#popup-main .select-items {
	margin-top: -8px;
}

.min-margin input[type='text'],
.min-margin input[type='email'],
.min-margin input[type='password'],
.min-margin input[type='tel'],
input[type='number'] {
	margin-bottom: 13px;
}

.product-part {
	padding: 0 0 24px;
}

.cost-details-part {
	padding: 24px 0 18px;
}

.total-part {
	padding: 18px 0;
}

.product-part .raffle-prod {
	margin-bottom: 3px;
}

table {
	width: 100%;
	line-height: 17px;
}

table.checkout-table th {
	text-align: left;
	font-family: 'Commissioner', sans-serif;
	font-weight: 500;
	padding: 6px 0;
}

table.checkout-table td {
	text-align: right;
	padding: 6px;
	font-size: 15px;
	vertical-align: bottom;
}

.cost-details-part p {
	margin-top: 9px;
}

table#shipping-details td {
	color: #5fc993;
	font-family: 'Commissioner', sans-serif;
	font-weight: 500;
}

.square {
	background-color: #ff0000;
	width: 9px;
	height: 9px;
	display: inline-block;
	margin-left: 5px;
	position: relative;
	top: 1px;
}

table#total th,
table#total td {
	font-family: 'Commissioner', sans-serif;
	font-weight: 700;
}

table#total td {
	color: #fc4400;
}

.info {
	background: #000000;
	width: 18px;
	height: 18px;
	text-align: center;
	color: #fff;
	border-radius: 50%;
	font-family: 'Commissioner', sans-serif;
	font-weight: 500;
	font-size: 10px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	margin-left: 4px;
	position: relative;
	cursor: pointer;
}

.info span {
	position: relative;
	top: 1px;
}

#cost-details .info {
	top: -3px;
}

#info-subscription,
#info-withholding {
	display: none;
}

/* SUCCESS PAGE */
.full-background {
	background: #f4f6f9;
}

main#success {
	padding: 35px 0 42px;
	text-align: center;
}

.personal-heading {
	font-family: 'Commissioner', sans-serif;
	font-weight: 700;
	font-size: 26px;
	line-height: 31px;
}

.success-subheading {
	font-family: 'Commissioner', sans-serif;
	font-weight: 700;
	font-size: 20px;
	margin-bottom: 20px;
}

main#success p {
	margin: 25px 0;
}

/* ACCOUNT PAGE */
#popup-main .birth-date-options {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	margin-bottom: 35px;
}

.birth-date-options.profile-edit input {
	width: 31%;
}

.birth-date-options .select-selected {
	padding-left: 20px !important;
}

.birth-date-options .select-day,
.birth-date-options .select-year {
	width: 27%;
}

.birth-date-options .select-month {
	width: 42%;
}

.birth-date-options .select-items {
	padding: 0 13px 10px;
}

#account-info-wrapper .simple-link {
	margin: 60px 0 0;
}

#footer-top-boxes {
	background: black;
	width: 100%;
	display: block;
	overflow-x: auto;

	.footer-top-wrapper {
		display: flex;
		justify-content: space-between;
		margin: 0 auto;
		flex-flow: row nowrap;
		max-width: 1480px;
		width: 100%;

		.footer-box {
			display: flex;
			flex-flow: row nowrap;
			align-items: center;
			text-align: center;
			padding: 33px 5px;
			justify-content: center;
			column-gap: 13px;
			width: auto;

			.box-title {
				align-items: center;
				color: white;
				font-family: 'Play', 'Commissioner', sans-serif;
				font-weight: 700;
				font-size: 16px;
				margin: 0;
				white-space: nowrap;
			}
		}

		@media (max-width: 1420px) {
			max-width: unset;
			width: max-content;
			padding: 0;

			.footer-box {
				padding: 16px 5px;

				.box-title {
					font-size: calc(var(--microsize) + 1px);
				}
			}
		}
	}

	&.under-slider {
		background: transparent;
		margin-bottom: 40px;
		width: 100%;
		display: block;
		overflow-x: auto;

		.footer-top-wrapper {
			flex-flow: row nowrap;
			max-width: 1480px;
			width: 100%;

			.footer-box {
				padding: 16px 5px;
				width: auto;

				.box-title {
					color: black;
					font-size: calc(var(--microsize) + 1px);
				}
			}
		}

		@media (max-width: 1420px) {
			.footer-top-wrapper {
				max-width: unset;
				width: max-content;
			}
		}

		@media (max-width: 767px) {
			margin-bottom: 20px;
		}
	}
}

#footer-top {
	padding: 60px 0 50px;

	.footer-info-section {
		color: #6a727e;

		p {
			margin-top: 0;
		}
	}
}

#footer-top nav a {
	display: block;
	font-family: 'Commissioner', sans-serif;
	font-weight: 500;
	font-size: 15px;
	margin-bottom: 15px;
}

#footer-top .cms-nav {
	display: flex;
	flex-direction: column;
	margin-top: 20px;
}

#footer-top .footer-level-1 {
	display: flex;
	justify-content: space-between;

	.newsletter-area {
		width: 35%;

		* {
			max-width: 293px;
		}

		.newsletter-title {
			text-align: left;
			font-size: 32px;
		}

		.newsletter-text {
			font-size: 16px;
			line-height: 22px;
		}

		.newsletter-box {
			margin-bottom: 30px;

			.gender-section {
				margin-bottom: 10px;
				display: flex;
				font-weight: 600;

				.form-control {
					display: flex;
					gap: 8px;

					&:nth-last-child(1) {
						margin-left: 30px;
					}
				}

				input[type='radio'] {
					display: initial;
					appearance: none;
					margin: 0;
					width: 20px;
					height: 20px;
					border-radius: 100%;
					display: grid;
					place-content: center;
					border: 1px solid #000;
					cursor: pointer;
				}

				input[type='radio']::before {
					content: '';
					width: 10px;
					height: 10px;
					border-radius: 100%;
					transform: scale(0);
					transition: 120ms transform ease-in-out;
					box-shadow: inset 10px 10px #000;
				}

				input[type='radio']:checked::before {
					transform: scale(1);
				}
			}

			input[type='email'] {
				padding: 0px 90px 0 2px;
				border-bottom: 1px solid black;
				background-color: #f1f2f3;
			}
		}

		.checkbox-area {
			display: flex;
			justify-content: flex-end;
			padding: 10px 0;
			font-size: var(--picosize);

			input {
				border-color: #ababab;
				margin: 3px 5px;
				height: unset !important;
				width: unset !important;
			}

			a {
				margin: 0 0 0 5px;
				text-decoration: underline;
				color: black;
			}
		}

		.social-links {
			display: flex;
			column-gap: 24px;
			margin-bottom: 20px;

			a:hover svg path {
				fill: #000;
			}
		}
	}

	.footer-menu-clmn {
		display: inline-flex;
		flex-flow: column nowrap;
		gap: 12px 0;
		line-height: 1.6;

		.footer-links-title {
			line-height: 1;
			margin: 0 0 20px;
			position: relative;

			.drop-icon {
				display: none;
			}

			&.active {
				.drop-icon {
					rotate: (180deg);
				}

				.column-links {
					display: flex;
					flex-flow: column nowrap;
					gap: 12px 0;
					line-height: 1.6;
				}
			}
		}

		.drop-icon .active {
			rotate: 180deg;
		}

		.retro-collection-link {
			color: #6a727e;
			position: relative;
			width: fit-content;

			&:after {
				position: absolute;
				content: '';
				bottom: -2px;
				left: 0;
				right: 0;
				transform: scaleX(0);
				transition: transform 250ms ease-in-out;
				transform-origin: 0% 50%;
				border-bottom: 2px solid #6a727e;
			}

			&:hover:after {
				transform: scaleX(1);
			}
		}

		.footer-info-section,
		.column-links {
			display: flex;
			flex-flow: column nowrap;
			gap: 12px 0;
			line-height: 1.6;

			a {
				color: #6a727e;
				transition: 0.3s ease-out;
				position: relative;
				width: fit-content;

				@media (min-width: 641px) {
					&:after {
						position: absolute;
						content: '';
						bottom: -2px;
						left: 0;
						right: 0;
						transform: scaleX(0);
						transition: transform 250ms ease-in-out;
						transform-origin: 0% 50%;
					}

					&:hover:after {
						border-bottom: 2px solid #6a727e;
						transform: scaleX(1);
					}
				}
			}
		}

		@media (max-width: 480px) {
			width: 100%;

			.footer-links-title {
				font-size: 16px;
				padding-right: 1px;

				.drop-icon {
					display: block;
					position: absolute;
					top: 5px;
					right: 0;

					&.active {
						rotate: 180deg;
					}
				}
			}

			.footer-info-section,
			.column-links {
				display: none;
			}
		}
	}

	.footer-menu-clmn:has(.footer-links-title):has(.active) .column-links {
		display: flex;
	}
}

.footer-bottom-1 {
	.content-wrapper {
		display: flex;
		justify-content: space-between;
		margin-bottom: 21px;

		@media (max-width: 767px) {
			flex-wrap: wrap;
		}
	}

	.payment-methods {
		display: inline-flex;
		gap: 20px;
		justify-content: center;
		align-items: center;
		margin-right: 15px;
	}

	.partners-area {
		display: inline-flex;
		align-items: center;
		column-gap: 20px;
	}
}

.footer-bottom-2 {
	border-top: 1px solid #f4f4f4;
	font-size: var(--picosize);
	padding: 16px 0 11px 0;

	a {
		display: inline-block;
		text-decoration: underline;
		color: inherit;
	}

	.content-wrapper {
		display: flex;
		justify-content: space-between;
		gap: 9px 20px;
		align-items: center;
		text-align: center;
		font-size: 14px;

		#copyright {
			.year {
				border-right: 1px solid black;
				padding-right: 12px;
				margin-right: 12px;
			}
		}
	}

	.bottom-menu {
		a:nth-child(odd) {
			padding-right: 13px;
			border-right: 1px solid;
			margin-right: 14px;
		}

		a:nth-last-child(odd) {
			padding-right: 0;
			border-right: 0;
			margin-right: 0;
		}
	}
}

.subscription {
	position: relative;
	width: 100%;
	height: 33px;
	border: none;
}

.subscription .add-email {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: none;
	outline: none;
	padding: 0 120px 0 20px;
}

.subscription .submit-email {
	position: absolute;
	top: 0;
	right: 0;
	height: 100%;
	width: 75px;
	border: none;
	outline: none;
	margin: 0;
	cursor: pointer;
	color: black;
	transition:
		width 0.35s ease-in-out,
		background 0.35s ease-in-out;
}

.subscription.done .submit-email {
	width: 100%;
}

.subscription .submit-email .before-submit,
.subscription .submit-email .after-submit {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	text-align: center;
	transition:
		visibility 0.35s ease-in-out,
		opacity 0.35s ease-in-out;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 14px;
	font-family: Play;
	font-weight: 700;
	text-transform: uppercase;
}

.subscription.done .submit-email .before-submit,
.subscription:not(.done) .submit-email .after-submit {
	visibility: hidden;
	opacity: 0;
}

.subscription .submit-email .after-submit {
	transition-delay: 0.35s;
}

.subscription:not(.done) .submit-email .before-submit,
.subscription.done .submit-email .after-submit {
	visibility: visible;
	opacity: 1;
}

#social {
	margin: 20px 0 30px;
	display: flex;
	align-items: center;
	justify-content: center;
}

#social a {
	display: block;
	margin: 0 13px;
}

#scrollToTopBtn {
	position: fixed;
	width: 48px;
	height: 48px;
	border-radius: 50%;
	border: 2px solid #ff4500;
	bottom: 90px;
	right: 31px;
	background: #fc4400;
	z-index: 99999999 !important;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: all 0.4s ease-in-out;
	-webkit-transition: all 0.4s ease-in-out;
	-moz-transition: all 0.4s ease-in-out;
	-o-transition: all 0.4s ease-in-out;
}

#scrollToTopBtn span {
	display: flex;
	transform: rotate(-90deg);
}

#scrollToTopBtn:hover {
	background: #fff;
	-moz-box-shadow: rgba(0, 0, 0, 0.4) 0 4px 8px;
	-webkit-box-shadow: rgb(0 0 0 / 40%) 0 4px 8px;
	box-shadow: rgb(0 0 0 / 40%) 0 4px 8px;
}

#scrollToTopBtn:hover span svg path {
	fill: #fc4400;
}

body.compensate-for-scrollbar {
	overflow: auto !important;
}

.fancybox__thumbs .carousel__slide .fancybox__thumb::after {
	border-color: var(--fancybox-accent-color, rgba(252, 68, 0, 0.96)) !important;
}

.loading-spinner {
	display: none;
	width: 80px;
	height: 80px;
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	z-index: 999;
	background: #fff;
	border-radius: 50%;
}

.loading-spinner.visible {
	display: inline-block;
}

.loading-spinner div {
	position: absolute;
	width: 6px;
	height: 6px;
	background: #000;
	border-radius: 50%;
	animation: loading-spinner 1.2s linear infinite;
}

.loading-spinner div:nth-child(1) {
	animation-delay: 0;
	top: 37px;
	left: 66px;
}

.loading-spinner div:nth-child(2) {
	animation-delay: -0.1s;
	top: 22px;
	left: 62px;
}

.loading-spinner div:nth-child(3) {
	animation-delay: -0.2s;
	top: 11px;
	left: 52px;
}

.loading-spinner div:nth-child(4) {
	animation-delay: -0.3s;
	top: 7px;
	left: 37px;
}

.loading-spinner div:nth-child(5) {
	animation-delay: -0.4s;
	top: 11px;
	left: 22px;
}

.loading-spinner div:nth-child(6) {
	animation-delay: -0.5s;
	top: 22px;
	left: 11px;
}

.loading-spinner div:nth-child(7) {
	animation-delay: -0.6s;
	top: 37px;
	left: 7px;
}

.loading-spinner div:nth-child(8) {
	animation-delay: -0.7s;
	top: 52px;
	left: 11px;
}

.loading-spinner div:nth-child(9) {
	animation-delay: -0.8s;
	top: 62px;
	left: 22px;
}

.loading-spinner div:nth-child(10) {
	animation-delay: -0.9s;
	top: 66px;
	left: 37px;
}

.loading-spinner div:nth-child(11) {
	animation-delay: -1s;
	top: 62px;
	left: 52px;
}

.loading-spinner div:nth-child(12) {
	animation-delay: -1.1s;
	top: 52px;
	left: 62px;
}

@keyframes loading-spinner {
	0%,
	20%,
	80%,
	100% {
		transform: scale(1);
	}

	50% {
		transform: scale(1.5);
	}
}

p.error-message {
	color: red;
	font-size: 16px;
	text-align: center;
	font-weight: 700;
}

#sizeSelect .css-6j8wv5-Input:before {
	content: none;
}

#sizeSelect .css-14el2xx-placeholder {
	font-family: 'Commissioner', sans-serif;
	font-weight: 700;
}

#sizeSelect .css-1s2u09g-control,
#sizeSelect .css-1pahdxg-control {
	height: 56px;
	border-radius: 30px !important;
	margin-bottom: 0;
}

#sizeSelect .css-1pahdxg-control {
	border-bottom-right-radius: 0 !important;
	border-bottom-left-radius: 0 !important;
}

#sizeSelect.css-b62m3t-container.hide .css-1pahdxg-control {
	border-radius: 30px !important;
}

.birth-date-options .css-1s2u09g-control,
.birth-date-options .css-1pahdxg-control {
	margin-bottom: 0;
}

.fancybox__content {
	max-width: 600px;
}

.fancybox__content .orange {
	color: #ea762e;
}

.fancybox__content a {
	color: inherit;
	text-decoration: underline;
}

.fancybox__content a:hover {
	color: #fc4400;
}

.pass-visibillity-icon {
	width: 28px;
	position: absolute;
	right: 12px;
	top: 10px;
	padding: 4px;
	cursor: pointer;
}

#hidePassIcon {
	display: none;
}

.my-account-form input {
	font-size: 14px !important;
}

.PhoneInputCountry {
	position: absolute !important;
	top: 0;
	bottom: 13px;
	left: 32px;
}

input.PhoneInputInput {
	padding-left: 70px;
}

.menu-logo {
	max-width: 122px;
}

/*404 Not Found Page*/
.notfound {
	.content-wrapper {
		padding: 110px 0 160px 0;

		.notfound-wrapper {
			display: flex;
			justify-content: center;
			justify-content: space-between;
			gap: 130px;
			margin: 0 200px;

			.image {
				display: flex;
				align-items: center;
				justify-content: center;
			}

			.text {
				font-family: 'Play', 'Commissioner', sans-serif;
				text-align: center;
				line-height: 29px;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: space-evenly;

				h1 {
					font-size: 24px;
					font-weight: 700;
					max-width: 300px;
				}

				p {
					font-family: 'Commissioner', sans-serif;
					max-width: 380px;
					font-size: 16px;
				}

				button {
					display: flex;
					justify-content: center;
					align-items: center;
					border-radius: 0;
					width: 275px;
					height: 48px;
					background: black;
					color: #ffffff;
					font-weight: 700;

					&:hover {
						box-shadow: 0 5px 20px rgba(0, 0, 0, 0.16);
					}

					svg {
						margin: 0 10px 0 0;
					}
				}
			}
		}

		@media (max-width: 1023px) {
			.notfound-wrapper {
				margin: 0 100px;
			}
		}

		@media (max-width: 850px) {
			padding: 64px 0;

			.notfound-wrapper {
				flex-direction: column;
				gap: 56px;

				p {
					margin-top: 22px;
					margin-bottom: 48px;
				}
			}
		}

		@media (max-width: 480px) {
			.notfound-wrapper {
				margin: 0 24px;

				.text p {
					max-width: 240px;
					line-height: 19px;
				}
			}
		}
	}
}

/* cms content */
.page-title {
	text-align: left;
	margin-bottom: 30px;
}

.cms-content .rounded {
	border-radius: 50px;
}

.cms-content p {
	margin: 25px 0;
}

.cms-content .orange-btn {
	display: block;
	max-width: 405px;
	margin: 50px auto;
}

.cms-content .icons-wrapper {
	display: flex;
	justify-content: space-between;
	margin: 30px auto;
}

.cms-content .icons-wrapper .icon-col {
	display: flex;
	align-items: center;
	width: 32%;
}

.cms-content .icons-wrapper .icon-col .icon {
	margin-right: 10px;
}

.cms-content .icons-wrapper .icon-col .text {
	font-family: 'Commissioner', sans-serif;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
}

.cms-content .images-grid {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.cms-content .images-grid img:nth-child(1),
.cms-content .images-grid img:nth-child(2) {
	width: 48.5%;
	margin-bottom: 35px;
}

.cms-content .images-grid img:nth-child(3) {
	max-width: 100%;
}

/* cms content end */

.homepage-slider {
	position: relative;
	aspect-ratio: 1920/750;
	width: 100% !important;

	@media (max-width: 480px) {
		aspect-ratio: 480/720;
	}

	.swiper-slide {
		position: relative;
		flex: 0 0 100%;

		img {
			width: 100%;
			height: 100%;
			max-height: 1200px;

			@media (max-width: 767px) {
				min-height: unset;
				object-fit: cover;
				object-position: top left;
			}

			@media (max-width: 425px) {
				// height: 640px;
			}
		}

		.slide-overlay {
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;

			.content-wrapper {
				width: 35%;
				max-width: 568px;
				color: white;
				height: 100%;
				margin: auto;
				padding: 0 25px;
				display: flex;
				flex-flow: column nowrap;
				justify-content: center;
				text-align: center;
				position: relative;

				* {
					z-index: 1;
				}

				&:before {
					z-index: 0;
					position: absolute;
					background-image: url(../images/slider-shadow.png);
					background-repeat: no-repeat;
					background-size: contain;
					background-position: center;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					content: '';

					@media (max-width: 767px) {
						background: rgba(0, 0, 0, 0.1);
					}
				}

				@media (max-width: 1240px) {
					max-width: unset;
					width: 100%;
					margin: 0;
				}

				@media (max-width: 767px) {
					justify-content: flex-start;
					padding-top: 6.5%;
				}

				@media (max-width: 425px) {
					align-items: flex-start;
				}
			}

			.slider-subtext {
				font-size: var(--microsize);
				margin: 0 0 15px 0;

				@media (max-width: 767px) {
					margin: 0;
					text-align: left;
				}
			}

			.slider-text {
				font-weight: 700;
				font-size: var(--mediumsize);
				line-height: 1.3;
				margin-bottom: 18px;

				p {
					margin: 0;
				}

				@media (max-width: 767px) {
					font-size: calc(var(--mediumsize) + 2px);
					margin-bottom: 10px;
					text-align: left;
				}
			}

			.viewall-button {
				display: flex;
				justify-content: center;
				align-items: center;
				height: 48px;
				text-align: center;
				width: fit-content;
				outline: none;
				margin: 0 auto;
				padding: 0 20px;
				background: black;
				color: #ffffff;
				transition: box-shadow 0.2s;
				font-size: 16px;
				font-family: Play;
				font-weight: 700;

				&:hover {
					box-shadow: 0 5px 20px rgba(0, 0, 0, 0.16);
				}

				&:after {
					right: 20px;
				}

				&:hover:after {
					width: 18px;
					right: 12px;
				}

				@media (max-width: 767px) {
					font-size: 14px;
					line-height: 16px;
					min-width: unset;
					height: unset;
					padding: 0;
					background: transparent;
				}

				@media (max-width: 425px) {
					margin: 0 auto 0 0;
				}
			}
		}

		.espa-banner {
			position: absolute;
			bottom: 0;
			right: 0;
			background-image: url(../images/espa-hero.png);
			background-repeat: no-repeat;
			background-position: center;
			width: 110px;
			height: 94px;

			@media (max-width: 480px) {
				background-image: url(../images/espa-hero-mobile.png);
				width: 60px;
				height: 51px;
			}
		}
	}

	.swiper-button-next {
		display: flex;
		transform: scale(0.5) translateX(-80px);

		&:after {
			color: white;
		}

		@media (max-width: 425px) {
			transform: scale(0.5) translateX(-10px);
		}
	}

	.swiper-button-prev {
		display: flex;
		transform: scale(0.5) translateX(80px);

		&:after {
			color: white;
		}

		@media (max-width: 425px) {
			transform: scale(0.5) translateX(10px);
		}
	}

	.swiper-pagination-bullet {
		background: transparent;
		border: 1px solid white;
		width: 10px;
		height: 10px;
		margin: 0 10px !important;
		transform: translateY(-50px);

		&.swiper-pagination-bullet-active {
			background: white;
		}
	}

	@media (max-width: 767px) {
		// height: calc(100vh - 60px);
	}

	@media (max-width: 480px) {
		// height: 640px;
	}
}

.custom-arrows {
	.swiper-button-prev {
		width: 30px;
		height: 30px;
	}

	.swiper-button-prev::after {
		content: '';
		width: 100%;
		height: 100%;
		background-image: url('../images/carousel-arrow-prev.svg');
		background-size: contain;
		background-repeat: no-repeat;
	}

	.swiper-button-next {
		width: 30px;
		height: 30px;
	}

	.swiper-button-next::after {
		content: '';
		width: 100%;
		height: 100%;
		background-image: url('../images/carousel-arrow-next.svg');
		background-size: contain;
		background-repeat: no-repeat;
	}

	.swiper-button-disabled {
		display: none !important;
	}

	@media (max-width: 680px) {
		.swiper-button-prev,
		.swiper-button-next {
			// display: none;
		}
	}
}

#double-banner {
	display: flex;

	.banner-item {
		width: 50%;
		height: 50vw;
		position: relative;
		overflow: hidden;

		img {
			display: block;
			width: 100%;
			object-fit: contain;
			transition: transform 0.5s;

			@media (max-width: 767px) {
				object-fit: cover;
				width: 100vw;
				height: 100vw;
			}
		}

		.title-container {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			background-color: rgba(0, 0, 0, 0.15);

			h3 {
				font-size: var(--bigsize);
				font-weight: 400;
				color: white;
				transition: 0.5s;
			}
		}

		&:hover {
			img {
				transform: scale(1.01);
			}
		}

		@media (max-width: 767px) {
			width: 100%;
			height: 100vw;
		}
	}

	@media (max-width: 767px) {
		flex-direction: column;
	}
}

#triple-banner {
	display: flex;
	justify-content: space-between;
	max-width: 1484px;
	width: 100%;
	margin: 0 auto;
	padding: 0 22px;

	.banner-item {
		width: 32%;
		max-width: 452px;
		height: 80px;
		background: #f1f2f3;
		display: flex;
		flex-flow: row wrap;
		align-items: center;
		margin-bottom: 85px;

		.image-container {
			display: block;
			overflow: hidden;
			border-radius: 100%;
			width: 65px;
			height: 65px;
			margin: 0 5%;

			img {
				transition: 0.1s;
			}

			@media (max-width: 767px) {
				width: 38px;
				height: 38px;
			}
		}

		.title-container {
			display: block;
			width: calc(100% - 65px - 10%);
			text-align: center;
			color: black;
			font-family: Play;
			font-weight: 700;
			font-size: var(--smallsize);

			@media (max-width: 767px) {
				text-align: left;
				padding: 0 0 0 20px;

				h3 {
					font-size: 16px;
				}
			}
		}

		&:hover,
		&:focus {
			img {
				transform: scale(1.1);
			}
		}
	}

	@media (max-width: 767px) {
		flex-flow: column nowrap;
		margin-bottom: 40px;

		.banner-item {
			margin-bottom: 7px;
			max-width: 100%;
			width: 100%;
			height: 45px;
		}
	}
}

#collection-triple-banner {
	.content-wrapper {
		display: flex;
		justify-content: space-between;
		margin-top: 25px;
		margin-bottom: 60px;
		max-width: 1420px;

		&.title-wrapper {
			margin-top: 45px;
			margin-bottom: 30px;
		}

		@media (max-width: 767px) {
			&.title-wrapper {
				margin: 45px auto 0 0;
			}

			display: flex;
			flex-flow: column nowrap;
			justify-content: center;
			align-items: center;
			padding-left: 0;
			padding-right: 0;

			.title-container {
				padding: 0 22px;
			}

			.view-black-text {
				padding: 0 22px;
			}
		}

		@media (max-width: 516px) {
			// margin-top: 0;
			margin-bottom: 40px;
		}
	}

	.banner-item {
		max-width: 452px;
		// width: 33%;
		display: flex;
		flex-flow: column nowrap;
		align-items: flex-start;

		.banner-image {
			display: block;
			width: 100%;
			height: 452px;
			overflow: hidden;
			margin-bottom: 30px;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: center;
				transform: scale(1.02);
				transition: 0.3s;
			}

			@media (max-width: 1280px) {
				height: 360px;
			}
		}

		@media (max-width: 767px) {
			width: 100%;
			// max-width: 360px;
			margin-bottom: 40px;

			&:last-child {
				margin: auto;
			}

			.banner-image {
				margin-bottom: 10px;
			}

			.title-container {
				h3 {
					margin: 0;
				}

				.subtitle p {
					margin: 7px 0;
				}
			}
		}

		@media (max-width: 516px) {
			// max-width: unset;
		}

		.title-container h3 {
			font-family: Play;
			font-weight: 700;
			font-size: var(--mediumsize);
			line-height: 35px;
		}

		.subtitle {
			font-size: var(--microsize);
			color: #6a727e;
		}

		&:hover {
			.banner-image {
				img {
					transform: none;
				}
			}
		}
	}
}

#brands-banner {
	margin-bottom: 55px;

	.swiper-slide a {
		height: 70px;
		display: flex;
		align-items: center;
		justify-content: center;

		img {
			display: block;
			max-height: 100%;
			width: auto;
			height: auto;
			filter: grayscale(100%);
			transition: 0.2s;
		}

		&:hover {
			filter: none;
		}
	}

	.swiper-wrapper {
		transition-timing-function: linear;
	}
}

#collection-banner {
	display: flex;
	align-items: flex-start;
	height: 810px;
	margin-bottom: 80px;

	@media (max-width: 980px) {
		flex-flow: column-reverse nowrap;
		height: unset;
		max-width: 100%;
	}

	@media (max-width: 480px) {
		margin-bottom: 0;
	}

	.banner-item {
		width: 50%;
		height: inherit;

		&:nth-child(1) {
			background: #f4f4f4;

			.grid-container {
				display: flex;
				flex-flow: column nowrap;
				max-width: 530px;
				margin: 0 auto;
				padding: 0 22px;
				justify-content: center;
				height: inherit;

				@media (max-width: 980px) {
					padding: 24px 22px;
				}
			}

			.category-intro {
				font-size: var(--microsize);
				margin: 0 0 10px 0;
			}

			h3 {
				display: block;
				font-size: var(--mediumsize);
				margin: 0 0 15px 0;
			}

			p {
				margin: 0 0 40px 0;
			}

			.images-container {
				display: flex;
				justify-content: space-between;
				column-gap: 20px;
				height: 34.7vw;
				max-height: 500px;

				.first-part {
					width: 57.6%;
					height: inherit;
					max-height: 500px;

					.img-container {
						width: 100%;
						height: 100%;

						img {
							object-fit: cover;
							object-position: center;
							width: 100%;
							height: inherit;
						}
					}

					@media (max-width: 980px) {
						max-width: 100%;
						width: 100%;
						height: 587px;
						max-height: unset;
						margin-bottom: 10px;
					}
				}

				.second-part {
					width: 37%;
					height: 100%;
					display: flex;
					flex-flow: column nowrap;
					justify-content: space-between;
					row-gap: 10px;

					.img-container {
						width: 100%;
						height: 100%;

						img {
							object-fit: cover;
							object-position: center;
							width: 100%;
							height: inherit;
						}
					}

					@media (max-width: 980px) {
						flex-flow: row nowrap;
						max-width: 100%;
						width: 100%;
						gap: 10px;
						height: 218px;
					}
				}

				@media (max-width: 980px) {
					height: unset;
					flex-flow: column nowrap;
					max-height: unset;
				}
			}
		}

		&:nth-last-child(1) {
			position: relative;
			overflow: hidden;

			img {
				display: block;
				width: 100%;
				height: inherit;
				object-fit: cover;
				transition: transform 0.5s;
			}

			.title-container {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				display: flex;
				flex-flow: column nowrap;
				justify-content: center;
				align-items: center;
				background-color: rgba(0, 0, 0, 0.15);
				color: white;

				.category-intro {
					font-size: var(--microsize);
					line-height: 1;
					margin: 0 0 14px 0;
				}

				h3 {
					font-size: var(--smallsize);
					font-weight: 400;
					color: white;
					transition: 0.5s;
					line-height: 1;
				}
			}
		}

		@media (max-width: 980px) {
			width: 100%;
		}
	}
}

#homepage-motto {
	padding: 176px 0;

	.content-wrapper {
		.motto-title {
			position: relative;
			font-weight: 700;
			font-size: calc(var(--bigsize) + 2px);
			padding: 0 48px;
			display: block;
			text-align: left;
			line-height: 1.2;

			.hashtag {
				position: absolute;
				left: 0;
				top: 0;
				font-weight: 700;
				font-size: 48px;
			}
		}

		& > div {
			font-size: calc(var(--bigsize) - 1px);
			line-height: 1.4;
			max-width: 828px;

			strong {
				font-weight: 500;
				transition: 0.3s ease-out;

				&:hover {
					font-style: italic;
				}
			}
		}
	}

	.motto-link {
		color: black;
		display: inline-block;
		font-size: 16px;
		position: relative;
		padding-right: 24px;
		transition: 0.3s ease-out;
		line-height: 18px;
		color: #000;

		&::after {
			content: '';
			position: absolute;
			background: url(../images/arrow-black.svg) no-repeat right;
			top: 0;
			bottom: 0;
			margin: auto;
			width: 12px;
			height: 12px;
			right: 0;
			transition: 0.3s ease-out;

			@media (max-width: 480px) {
				width: 10px !important;
				height: 11px;
				background-size: cover;
			}
		}

		&:hover {
			padding-right: 26px;
		}

		&:hover:after {
			width: 18px;
			right: -5px;
		}

		@media (max-width: 480px) {
			padding-right: 16px !important;
			font-size: 14px;
		}

		/*        .arrow {
            display: inline-block;
            padding: 0 10px;
            transform: translateY(10%);
        }*/
	}

	@media (max-width: 575px) {
		padding: 32px 0;

		.content-wrapper {
			.motto-title {
				padding: 0 32px;

				.hashtag {
					top: -2px;
					font-size: unset;

					svg {
						width: 24px !important;
						height: 35px !important;
					}
				}
			}

			p {
				margin: 12px 0;
			}
		}
	}
}

.homepage-insta {
	margin-bottom: 147px;

	.title {
		h1 {
			margin-bottom: 36px;

			@media (max-width: 900px) {
				font-size: 24px !important;
			}

			@media (max-width: 480px) {
				font-size: 16px !important;
				line-height: 18px !important;
				margin-bottom: 17px;
			}
		}
	}

	@media (max-width: 575px) {
		margin-bottom: 50px;
	}
}

.product-tabs {
	position: relative;
	margin-top: 64px;

	.heading-wrapper {
		display: flex;
		justify-content: space-between;
		align-items: center;
		max-width: 1484px;
		padding: 0 22px;
		height: 100px;
		width: 100%;
		margin: 0 auto;

		.title {
			font-family: Play;
			font-weight: 700;
			font-size: calc(var(--bigsize) + 10px);
			line-height: 1;
		}
	}

	.content-wrapper {
		display: block;
		padding: 0;
		max-width: 1920px;
		height: 770px;
		max-height: 790px;
		width: 100%;
		margin: 0 auto;

		.main-image-container {
			display: block;
			height: calc(100% - 100px);
			width: 100%;

			img {
				display: block;
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: center;

				&.on-hover {
					position: absolute;
					top: 0;
				}
			}
		}
	}

	.heading-wrapper {
		position: relative;
	}

	.tabs-container {
		display: flex;
		align-items: center;
		width: 100%;
		max-width: 600px;
		position: relative;

		.tabs-header {
			overflow-x: hidden;
			height: 40px;

			button {
				display: inline-block;
				font-family: Play;
				font-size: var(--microsize);
				font-weight: 700;
				color: black;
				padding: 0 23px;
				border: 1px solid black;
				border-radius: 20px;
				min-height: 40px;
				line-height: 1;
				text-transform: uppercase;
				transition: 0.06s;
				width: auto;

				&.active,
				&:hover {
					background: black;
					color: white;
				}
			}

			.swiper .swiper-wrapper {
				gap: 16px;

				.swiper-slide {
					text-align: center;
					width: auto !important;
				}
			}

			.swiper-button-prev {
				position: absolute;
				top: 50%;
				left: -50px;
				transform: translate(50px, 7px);
			}

			.swiper-button-next {
				position: absolute;
				top: 50%;
				right: 50px;
				transform: translate(50px, 7px);
			}
		}

		.swiper {
			&.TabCarousel {
				margin: auto;
				width: 336px;
				height: 512px;
				position: absolute;
				top: 132px;
				left: 50%;
				transform: translateX(-50%);

				.swiper-pagination-bullet {
					border: 1px solid #fff;
					width: 10px;
					height: 10px;

					&.swiper-pagination-bullet-active {
						background-color: #fff;
					}
				}
			}
		}

		.TabCarousel.disabled {
			display: none;
		}

		.swiper-button-prev {
			transform: translate(-5px, -50%);
		}

		.swiper-button-next {
			transform: translate(5px, -50%);
		}

		.product-box {
			background: white;
			padding: 30px 28px;
			margin: 0 auto;
			width: 300px;
			min-height: 420px;

			.add-wishlist {
				top: 20px;
				right: 18px;
			}

			.prod-img {
				margin-bottom: 8px;
			}

			&:hover {
				.on-hover {
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					background-color: #fff;
					visibility: visible;
					opacity: 1;
					transform: translateX(0);
				}
			}
		}
	}

	@media (max-width: 768px) {
		.content-wrapper {
			height: 670px;

			.heading-wrapper {
				flex-wrap: wrap;
				gap: 16px;
				margin-bottom: 24px;

				.title {
					font-size: 28px;
				}

				.tabs-container {
					max-width: 100%;

					.swiper {
						&.TabCarousel {
							height: 470px;
						}
					}

					.tabs-header {
						margin-right: -22px;
					}

					.product-box {
						width: 244px;
						padding: 20px;
					}

					.TabCarousel {
						.product-box {
							height: 420px;
						}

						.swiper-button-prev {
							transform: translate(20px, -50%);
						}

						.swiper-button-next {
							transform: translate(-20px, -50%);
						}
					}
				}
			}

			.main-image-container {
				height: 552px;
			}
		}
	}

	.product-box .prod-img {
		max-height: 238px;
	}

	@media (max-width: 480px) {
		.content-wrapper .heading-wrapper .tabs-container .TabCarousel .product-box {
			min-height: unset;
			height: 348px;
			padding: 19px;

			.product-box-content {
				overflow-y: unset;

				.prod-colors,
				.quick-buy {
					display: none;
				}
			}

			&:hover {
				.product-box-content {
					transform: none;
				}
			}

			.prod-img {
				max-height: 206px;
			}
		}

		.product-box .prod-img {
			max-height: 206px;
		}
	}
}

.popup {
	display: none;
}

#info-page {
	.cms-title {
		width: 100%;
		background: #f1f2f3;
		padding: 50px 0 60px 0;

		h1 {
			display: block;
			max-width: 850px;
			width: 100%;
			padding: 15px 0;
			margin: 0 auto;
			font-family: Play;
			font-size: var(--mediumsize);
			text-align: center;
		}
	}

	.content-wrapper {
		display: block;
		max-width: 850px;
		width: 100%;
		padding: 15px 22px;
		margin: 0 auto;

		img {
			display: block;
			max-width: 100%;
			border: 1px solid black;
			width: 100%;
			height: auto;
		}

		p {
			font-size: (--regsize);
			line-height: 1.4;
		}
	}
}

#contact {
	.header-banner {
		display: block;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		min-height: 95px;
		max-height: 280px;
	}

	.cms-content {
		display: flex;
		justify-content: space-between;
		max-width: 1200px;
		padding-bottom: 110px;
		gap: 51px 20px;
	}

	.form-area {
		display: flex;
		flex-flow: row wrap;
		justify-content: center;
		padding: 45px 22px 55px;
		width: 100%;
		max-width: 792px;
		box-shadow: 0px 10px 30px rgb(0, 0, 0, 0.4);
		background: white;
		margin-top: -43px;
		border-radius: 5px;

		.form-container {
			width: 100%;
		}

		.form-container form {
			display: flex;
			margin: auto;
			flex-flow: row wrap;
			max-width: 590px;

			.form-title {
				display: block;
				width: 100%;
				text-align: center;
				font-weight: 400;
				font-size: 20px;
				margin: 0 0 35px 0;
			}

			select {
				width: 100%;
				padding: 14.5px 13px;
				border-radius: 5px;
				margin-bottom: 17px;
			}

			.inputs {
				width: 100%;
			}

			input[type='text'],
			input[type='email'] {
				width: 100%;
			}

			textarea {
				border: none !important;
				background-color: #f2f2f2 !important;
			}

			label {
				display: block;
				width: 100%;

				& > span {
					display: block;
					font-weight: 700;
					margin: 12px 0 15px;
				}

				.input-label {
					margin: 0;
					color: #000000;
				}

				.attach-top {
					margin-top: 27px;
				}
			}

			input[type='file'] {
				display: none;
			}

			.uploadfile-button {
				width: 100%;
				padding: 11px 13px;
				border-radius: 5px;
				margin-bottom: 10px;
				border-radius: 5px;
				border: 1px solid #000000;
				text-align: left;
				font-size: 14px;
				line-height: 1;
				display: flex;
				justify-content: space-between;
				align-items: center;
			}

			.black-arrow-button {
				min-width: 245px;
				text-align: left;
				padding-left: 30px;
				margin-left: auto;
				margin-top: 7px;
			}

			.grey-comment {
				color: #7b7b7b;
			}

			.capcha-align {
				margin-left: auto;
				text-align: end;
			}

			.captcha-wrapper iframe {
				transform-origin: right;
			}

			.form-row {
				width: 100%;
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin: 17px 0 40px;
				gap: 20px 10px;

				p {
					margin: 0;
				}
			}
		}
	}

	.info-area {
		max-width: 290px;

		.content-wrapper {
			padding: 10px 0 32px 0;

			& > p {
				display: block;
				width: 100%;
				text-align: center;
			}

			h2 {
				font-size: 16px;
				color: #717171;
				margin: 75px 0 15px 0;
				text-align: left;
			}

			.contact-item {
				width: 100%;
				display: flex;
				margin-bottom: 13px;

				span {
					margin-right: 24px;
				}

				p {
					margin: 0;
				}

				&.first {
					border-right: 1px solid #ababab;
				}

				a {
					font-weight: 700;
					color: black;
				}
			}
		}
	}
}

// Brands
#brands-list {
	#brands-search {
		background-color: #f1f2f3;
		position: sticky;
		width: 100%;
		top: 103px;
		z-index: 2;

		.content-wrapper {
			padding: 16px 22px;
		}
	}

	.search-bar {
		margin-bottom: 30px;
		display: flex;
		justify-content: space-between;

		input {
			outline: 0;
			font-family: 'Commissioner';
			display: flex;
			padding: 0 22px;
			align-items: center;
			height: 40px;
			font-size: var(--microsize);
			color: black;
			border: none;
			background-color: #fff;

			&:hover {
				outline: none;
				border: none;
			}

			&::placeholder {
				color: #6a727e;
				opacity: 0.35;
			}

			&::-ms-input-placeholder {
				/* Microsoft Edge */
				color: #6a727e;
			}
		}

		img {
			margin-left: -22px;
		}
	}

	.top-brands {
		padding-bottom: 65px;
		border-bottom: 1px solid #b6bac2;
		margin-bottom: 34px;

		h3 {
			font-size: var(--mediumsize);
			font-family: Play;
			font-weight: 700;
			margin-bottom: 50px;
			line-height: 1;
		}

		.top-brands-items {
			display: flex;
			flex-flow: row wrap;
			row-gap: 30px;
			column-gap: 18px;

			a {
				width: 125px;
				height: 70px;
				display: flex;
				align-items: center;
				justify-content: center;

				img {
					display: block;
					max-width: 100%;
					width: auto;
					height: auto;
					max-height: 100%;
					filter: grayscale(1);
					transition: 0.1s;
				}

				&:hover {
					img {
						filter: none;
					}
				}
			}
		}
	}

	& > .content-wrapper {
		padding-top: 60px;

		& > .brands-list {
			margin-bottom: 50px;

			.brands-letter-item {
				display: flex;
				padding-bottom: 68px;
				border-bottom: 1px solid #b6bac2;
				margin-bottom: 30px;

				h3 {
					font-size: var(--mediumsize);
					font-family: Play;
					font-weight: 700;
					line-height: 1;
					margin: 0 80px 0 0;
				}

				.brands-list {
					display: flex;
					flex-flow: row wrap;
					row-gap: 22px;
					column-gap: 100px;
					padding-top: 3px;

					a {
						font-size: var(--regsize);
						text-transform: uppercase;
						color: black;
					}
				}

				&:nth-last-child(1) {
					padding-bottom: 0;
					border-bottom: 0;
				}
			}
		}

		.search-results {
			display: flex;
			flex-flow: row wrap;
			row-gap: 22px;
			column-gap: 100px;
			margin-bottom: 50px;

			a {
				font-size: var(--regsize);
				text-transform: uppercase;
				color: black;
			}
		}
	}
}

@media (max-width: 820px) {
	#home-slider {
		margin-top: -30px;
	}

	#home-slider .content-wrapper {
		max-width: 660px;
		width: 100%;
	}

	.raffle-carousel .owl-item {
		padding: 30px;
	}

	.raffle-carousel-main,
	.raffle-registration {
		box-shadow: 2px 7px 29px #434b5759;
	}

	.raffle-carousel .owl-nav button {
		top: 195px;
	}

	.raffle-carousel .owl-nav button.owl-prev {
		left: 7%;
	}

	.raffle-carousel .owl-nav button.owl-next {
		right: 7%;
	}

	.raffle-carousel .owl-dots {
		margin-top: -10px;
	}

	#anchor-links a,
	#scroll-to-top {
		margin: 0 10px;
	}

	#anchor-links a:last-child {
		margin-right: 0;
	}

	#scroll-to-top {
		margin-left: 0;
	}
}

@media (max-width: 600px) {
	.slider-go-to-prod a {
		right: 20px;
		bottom: 23px;
	}

	#burger-btn {
		background-color: transparent;
	}

	#burger-btn img {
		width: 24px;
		height: 23px;
	}

	.go-to-prod {
		display: block;
	}

	#product-title,
	#product-price,
	#heritage,
	#heritage h2 {
		text-align: left;
	}

	#product-title,
	#product-price {
		padding-left: 5px;
	}

	.cms-content .icons-wrapper {
		flex-direction: column;
		align-items: flex-start;
		margin: 0;
	}

	.cms-content .icons-wrapper .icon-col {
		width: 100%;
		max-width: 200px;
		margin-bottom: 35px;
	}

	.cms-content .icons-wrapper .icon-col:last-child {
		margin-bottom: 0;
	}
}

@media (max-width: 480px) {
	.raffle-prod-details {
		margin-left: 18px;
	}

	.img-container-double {
		display: block;
	}

	.img-container-double > div {
		width: 100%;
		max-width: 300px;
		margin: 0 auto 20px;
	}

	.img-gallery {
		margin-left: -15px;
		margin-right: -15px;
	}

	#raffle-ends,
	#raffle-starts {
		padding-left: 0;
	}

	#raffle-ends span,
	#raffle-starts span {
		margin-right: 10px;
	}

	.popup .content-wrapper {
		padding: 0;
	}

	.cms-content .images-grid {
		flex-direction: column;
	}

	.cms-content .images-grid img:nth-child(n) {
		width: 100%;
		margin-bottom: 35px;
	}

	.cms-content .rounded {
		height: 236px;
		object-fit: cover;
	}

	.cms-content .images-grid img:nth-child(3) {
		height: 182px;
		margin-bottom: 0;
	}

	.cms-content .orange-btn {
		margin: 25px auto 0;
	}
}

@media (max-width: 420px) {
	.birth-date-options .select-day,
	.birth-date-options .select-year {
		width: 31%;
	}

	.birth-date-options .select-month {
		width: 31%;
	}
}

@media (max-width: 600px), (max-height: 500px) {
	#close-modal {
		top: 20px;
		right: 15px;
		padding-right: 0;
	}

	#close-modal svg path {
		stroke: #000;
		fill: #000;
	}

	#main-menu {
		text-align: left;
		background: #e9edf3;
		border-bottom: none;
		max-width: none;
	}

	#main-menu li {
		padding-left: 46px;
	}

	#user-options {
		max-width: none;
		padding-left: 0;
		border-bottom: none;
	}

	#external li,
	#user-options li,
	#lang {
		padding-left: 15px;
	}

	#main-menu li {
		padding-bottom: 11px;
	}

	#user-options li {
		padding-bottom: 8px;
		padding-top: 11px;
	}

	#external li {
		padding-top: 17px !important;
		padding-bottom: 17px !important;
	}

	#lang {
		padding-top: 8px;
		padding-bottom: 16px;
	}

	#menu-section ul#external {
		background: #e9edf3;
	}

	#main-menu li:last-child {
		border-bottom: none;
		padding-bottom: 12px;
	}

	#user-options svg path {
		stroke: #040707;
	}

	#user-options svg path#Path_224 {
		fill: #040707;
	}

	#product,
	#heritage,
	#raffle-ends,
	#raffle-starts,
	.faq-content {
		font-size: 16px;
	}
}

.search-screen {
	background: #fff;

	.content-wrapper {
		height: 40px;
		padding: 0;
		display: flex;
		align-items: center;
		position: relative;
	}

	@media (max-width: 767px) {
		position: absolute;
		top: 0px;
		right: 0px;
		height: 100vh;
		width: calc(100vw - 0px);
		z-index: 9999;
		background-color: #fff;
		box-shadow: -22px 0px 9px 4px rgba(0, 0, 0, 0.6);
		-webkit-box-shadow: -22px 0px 9px 4px rgba(0, 0, 0, 0.6);
		-moz-box-shadow: -22px 0px 9px 4px rgba(0, 0, 0, 0.6);
		overflow: scroll;
		padding: 0 25px;
		padding-right: 23px;

		.content-wrapper {
			height: 48px;
		}

		.exit-search {
			width: 22px;
			height: 22px;
			top: 20px;
			left: 0;
		}

		.before-search-section {
			display: block;
			position: fixed;
			top: 100px;
			left: 0px;
			right: 0px;
			justify-content: center;
			background-color: #fff;
		}
	}

	&::-webkit-scrollbar {
		width: 0;
	}

	#search-close {
		cursor: pointer;
		pointer-events: auto;
		position: relative;
		z-index: 9;
		width: fit-content;
		margin-left: auto;
		display: block;
	}

	.results-section {
		display: flex;
		gap: 180px;
		position: absolute;
		top: 57px;
		left: 0;
		padding-top: 47px;
		padding: 47px;

		@media (max-width: 767px) {
			flex-direction: column;
			gap: 20px;
			padding: 36px 22px;
			top: 210px;

			.content-wrapper {
				padding: 0;
			}
		}

		@media (max-width: 1023px) {
			gap: 130px;
		}

		.keyword-title {
			font-weight: 700;
			font-size: 24px;
			font-family: 'Play', 'Commissioner', sans-serif;
			line-height: 29px;
			margin: 0 0 22px 0;
			display: block;
			text-transform: capitalize;
		}

		.keyword-item {
			color: #ababab;
			font-size: 14px;
			margin: 0 0 22px 0;
			display: block;
			width: 100%;
			text-align: left;
		}

		.keyword-results {
			width: 140px;

			@media (max-width: 767px) {
				width: 100%;
			}
		}

		.product-box {
			display: inline-flex;
			flex-flow: column nowrap;
			width: auto;
			padding: 0px;

			& > svg {
				top: 15px;
				right: 35px;
			}

			.prod-details {
				background: unset;
			}

			.prod-img {
				position: relative;
				display: grid;
				place-items: center;

				a {
					display: block;
					position: relative;

					img {
						width: 100%;
						height: 100%;

						&.on-hover {
							display: block;
							position: absolute;
							width: 100%;
							height: 100%;
							top: 0;
							left: 0;
						}
					}
				}
			}

			@media (max-width: 575px) {
				padding: 0px 5px;
			}
		}
	}

	.quote-container {
		border-bottom: 1px solid grey;
		padding-top: 2%;
	}

	.quote {
		font-style: italic;
		font-size: 1.2em;
	}

	.quote-author {
		color: gray;
		font-size: 0.9em;
	}

	.highlight {
		font-weight: bold;
		color: black;
	}

	.searchbar {
		div img {
			display: none;
		}

		@media (max-width: 767px) {
			margin: 66px 0 0 0px;
			padding-top: 50px;
			width: 100%;
			position: unset;

			div {
				display: flex;
				position: relative;

				img {
					display: block;
					position: absolute;
					right: 12px;
					top: 50%;
					transform: translateY(-50%);
				}
			}
		}

		@media (max-width: 480px) {
			width: 100%;
			max-width: 400px;
		}

		&::before {
			content: '';
			position: absolute;
			background: url(../images/search-icon.svg) no-repeat center;
			right: 5px;
			bottom: 10px;
			width: 22px;
			height: 22px;

			@media (max-width: 767px) {
				content: none;
			}
		}

		.searchbar-input,
		button {
			width: 480px;
			padding: 0;
			padding-left: 24px !important;
			padding-right: 80px !important;
			border: none;

			@media (max-width: 767px) {
				background: #f1f2f3;
				width: 100% !important;
				padding-left: 15px !important;
				padding-right: 36px !important;
			}

			@media (max-width: 1023px) {
				width: 410px;
			}

			&::-webkit-input-placeholder,
			&::placeholder {
				opacity: 0.55;
				font-size: 14px;
				color: #050507;

				@media (max-width: 767px) {
					font-size: 14px;
				}

				@media (max-width: 480px) {
					font-size: 14px;
				}
			}
		}

		button {
			height: 48px;
			background-color: #000;
			color: #fff;
			font-size: 16px;
			text-transform: uppercase;
			font-weight: 700;
			line-height: 19px;
			border-radius: 0;
			margin-top: 12px;
			padding: 0 !important;

			@media (min-width: 768px) {
				display: none;
			}
		}
	}

	.before-search-section {
		position: fixed;
		top: 100px;
		left: 0px;
		right: 0px;
		justify-content: center;
		background-color: #fff;

		.products-results {
			display: flex;
			width: 50%;
			justify-content: space-between;

			.product-box {
				.prod-details {
					.flex-col {
						flex-flow: column nowrap;
						gap: 0;

						h3 {
							font-size: 14px;
							max-width: unset;
							margin: 0 0 5px 0;
						}

						.prod-price {
							text-align: left;
							line-height: 1;
							gap: 0;

							span {
								display: none;
							}
						}
					}
				}

				.quick-buy,
				.add-wishlist {
					display: none !important;
				}
			}
		}
	}

	.after-search-section {
		position: fixed;
		top: 100px;
		left: 0px;
		right: 0px;
		background-color: #fff;
		-webkit-box-shadow: 20px 1px 13px -1px rgba(0, 0, 0, 0.75);
		-moz-box-shadow: 20px 1px 13px -1px rgba(0, 0, 0, 0.75);
		box-shadow: 20px 1px 13px -1px rgba(0, 0, 0, 0.75);

		.results-container {
			display: flex;
			justify-content: center;
			gap: 200px;

			@media (max-width: 767px) {
				display: block;
				padding: 1.5rem;
				height: 70vh;
				overflow-y: scroll;
			}
		}

		@media (max-width: 767px) {
			padding: 0;
			margin: 30px 0 0 0;
			width: 100%;
			height: 100vh;
		}

		.go-to-search-page {
			width: 100%;
			text-align: center;
			padding: 40px 22px;

			@media (max-width: 767px) {
				padding: 20px 0;
			}

			button {
				background: black;
				color: white;
				margin-left: auto;
				padding: 15px;
			}
		}

		.products-results {
			display: flex;
			width: 50%;
			flex-flow: column nowrap;
			gap: 10px;

			@media (max-width: 767px) {
				width: 100%;
				max-width: 100%;
			}

			.product-item {
				display: block;
				width: 100%;
				text-align: left;
				width: 100%;

				.product-box {
					flex-flow: row nowrap;
					max-width: unset;
					width: 100%;
					padding: 13px;
					background-color: #f1f2f3;

					@media (max-width: 767px) {
						padding: 13px 9px;
						overflow: hidden;
					}

					.prod-img {
						width: 74px;
						height: 74px;
						margin: 0;
					}

					.prod-details {
						padding: 0;
						padding-left: 24px;

						@media (max-width: 767px) {
							padding-left: 18px;
						}

						.flex-col {
							height: 100%;
							flex-flow: column nowrap;
							justify-content: space-between;
							gap: 0;
							width: auto;

							& > div {
								height: 100%;
								display: flex;
								flex-direction: column;
								justify-content: space-between;
							}

							.brand-logo {
								display: none;
							}

							h3 {
								font-size: 16px;
								font-weight: 700;
								line-height: 21px;
								font-family: Roboto, Arial, sans-serif;
								margin: 0;
								max-width: 220px;
							}

							.prod-price {
								text-align: left;
								line-height: 1;
								gap: 0;
								margin: 0;
							}

							.prod-colors {
								display: none;
							}
						}
					}

					.quick-buy,
					.add-wishlist {
						display: none !important;
					}

					.special-labels {
						display: none;
					}
				}
			}
		}

		.show-all-container {
			width: 100%;
			display: flex;
			justify-content: center;
			margin-top: 55px;

			@media (max-width: 767px) {
				position: fixed;
				bottom: 20px;
				background: #fff;
				padding: 20px 0;
			}

			.show-all {
				height: 50px;
				width: 400px;

				@media (max-width: 450px) {
					width: 100%;
					margin: 0 1.5rem;
				}
			}
		}
	}

	.after-search-section {
		display: none;
	}

	&.search-clicked {
		.before-search-section {
			display: none;
		}

		.after-search-section {
			display: block;

			.products-results {
				width: 340px;

				@media (max-width: 767px) {
					width: 100%;
					min-width: 250px;
				}
			}

			@media (max-width: 767px) {
				flex-direction: column;
				gap: 28px 0;
			}

			.keyword-item {
				color: #000;
			}
		}
	}

	.view-search-mobile {
		width: 100vw;
		height: 100vh;
		background: #fff;
		position: relative;

		.search-container {
			margin-left: 23px;
			display: flex;
			gap: 25px;

			span {
				padding: 15px;
				padding-right: 0;
				background: #f1f2f3;
				max-width: 305px;
			}
		}

		.exit-search {
			width: 25px;
			left: 10px;
			top: 0;

			#search-close svg {
				width: 25px;
				height: 25px;
			}
		}
	}
}

body {
	-webkit-transition: all 0.5s ease-in-out;
	-o-transition: all 0.5s ease-in-out;
	transition: all 0.5s ease-in-out;
}

body.cart-open {
	overflow: hidden;
	position: relative;
}

body.overflow {
	overflow: hidden;
}

body.cart-open:after {
	position: absolute;
	content: '';
	background-color: rgba(0, 0, 0, 0.2);
	left: 0;
	top: 0;
	right: 0;
	height: 100%;
	z-index: 2003;
}

.grid-col {
	display: grid;
}

.flex-col {
	display: flex;
}

button {
	font-family: Play, Arial, sans-serif;
	font-weight: 700;
	font-size: 16px;
}

form button {
	padding: 15px;
}

#slider_placeholder {
	background: unset;
	display: unset;
	height: unset;
	width: unset;
}

.newsletter-sub-message {
	margin: 60px 0 70px;
	text-align: center;
	font-size: 24px;
	font-weight: 700;
}

.black-button {
	background-color: #000000;
	color: #ffffff;
	height: 48px;
	border-radius: 5px;
	padding: 15px 20px;
}

.white-arrow-button {
	background-color: #ffffff;
	color: #000000;
	border: 1px solid #000;
}

.black-arrow-button {
	background-color: #000000;
	color: #ffffff;
}

.white-arrow-button:after {
	content: '';
	position: absolute;
	background: url(../images/arrow-black.svg) no-repeat right;
	top: 0;
	bottom: 0;
	margin: auto;
	width: 12px;
	height: 12px;
	right: 34px;
	transition: 0.3s ease-out;
}

.black-arrow-button:hover:after,
.white-arrow-button:hover:after {
	width: 18px;
	right: 28px;
}

.acceptance {
	font-size: 12px;
	line-height: 18px;
	color: #717171;
}

.acceptance .red {
	position: initial;
	left: 0;
	top: 0;
	font-size: 14px;
}

.captcha-wrapper iframe {
	transform: scale(0.77);
	transform-origin: 0 0;
}

.no-result {
	font-size: 18px;
	line-height: 22px;
	text-align: center;
	font-weight: 700;
	color: #d12b2b;
}

.empty-content {
	text-align: center;
	font-size: 24px;
	line-height: 28px;
	font-weight: 700;

	@media (max-width: 640px) {
		margin-top: 30px;
	}
}

#view-cart {
	position: relative;
}

#view-cart .active span {
	background-color: white;
	font-size: 8px;
	border-radius: 100%;
	position: absolute;
	bottom: -4px;
	left: 15px;
	line-height: 1;
	width: 12px;
	height: 12px;
	color: black;
	display: inline-flex;
	justify-content: center;
	align-items: center;
}

#pager {
	gap: 10px;
	justify-content: center;
	margin-top: 80px;
}

#pager a button {
	height: 35px;
	width: 35px;
	background-color: #fff;
	border-radius: 0;
	font-size: 14px;
	line-height: 22px;
	transition: 0.3s ease-out;
}

#pager a button:hover {
	box-shadow: 0px 4px 6px rgb(0 0 0 / 16%);
}

#pager a.active button {
	background-color: #000;
	color: #fff;
}

#pager .arrow {
	width: 35px;
	height: 35px;
	display: flex;
	align-items: center;
	justify-content: center;
}

#pager .arrow.back {
	cursor: pointer;
	transform: rotate(180deg);
}

#pager .arrow.front {
	cursor: pointer;
}

.select__input {
	color: transparent !important;
}

/* toast */
.Toastify__toast.black-background {
	background-color: #000;
	color: #fff;
	font-size: 14px;
	line-height: 20px;
	font-family: 'Commissioner', Arial, sans-serif;
	max-width: 282px;
	margin: auto;
}

.black-background svg {
	fill: #fff;
}

.Toastify__toast-container--top-center.toast-content {
	top: 62px;
}

.black-background .Toastify__progress-bar--info,
.black-background .Toastify__progress-bar--success,
.black-background .Toastify__progress-bar--warning,
.black-background .Toastify__progress-bar--error,
.black-background .Toastify__progress-bar-theme--light,
.black-background .Toastify__progress-bar-theme--dark {
	background: #f4f4f4;
}

/* end toast */
/*general inputs*/
input[type='password'],
input[type='email'],
input[type='text'],
input[type='tel'],
input[type='date'],
textarea,
.content-phone .form-control.phone-input {
	height: 48px;
	border: none;
	background-color: #f1f2f3;
	width: 100%;
	font-size: 14px;
	line-height: 22px;
	color: #000;
	padding: 19px 14px 12px;
	outline: none;
}

.content-phone .flag-dropdown {
	display: none;
}

textarea {
	min-height: 140px;
	max-width: 588px;
	width: 100% !important;
	padding: 16px 14px;
}

textarea::placeholder {
	font-size: 14px;
	line-height: 22px;
	color: #7b7b7b;
	max-width: 472px;
	font-family: Ubuntu, Arial, sans-serif;
	opacity: 1;
}

input.add-email::-webkit-input-placeholder {
	font-size: 14px;
	line-height: 22px;
	color: #7b7b7b;
	max-width: 472px;
	font-family: Ubuntu, Arial, sans-serif;
	opacity: 1;
}

input.add-email::-ms-input-placeholder {
	font-size: 14px;
	line-height: 22px;
	color: #7b7b7b;
	max-width: 472px;
	font-family: Ubuntu, Arial, sans-serif;
	opacity: 1;
}

.input-label a {
	text-decoration: underline;
	color: #161919;
}

input:required {
	padding-left: 14px;
}

.form-input {
	position: relative;
	margin-bottom: 17px;
}

input[type='radio'],
input[type='checkbox'] {
	display: none;
}

input[type='radio'] + .radio-label {
	display: block;
	cursor: pointer;
	padding-left: 27px;
	position: relative;
	line-height: 33px;
	display: flex;
	align-items: center;
}

input[type='radio'] + .radio-label:before {
	content: '';
	border-radius: 50%;
	display: inline-block;
	width: 20px;
	height: 20px;
	border: 1px solid #000000;
	background: #fff;
	transition: 0.2s;
	position: absolute;
	left: 1px;
}

input[type='radio'] + .radio-label:after {
	content: '';
}

input[type='radio']:checked + .radio-label:after {
	position: absolute;
	left: 7px;
	border-radius: 50%;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	width: 10px;
	height: 10px;
	background: #000;
}

input[type='checkbox'] + .input-label {
	display: block;
	cursor: pointer;
	padding-left: 22px;
	position: relative;
}

.input-label::before,
.input-label::after {
	content: ' ';
}

input[type='checkbox'] + .input-label:before {
	content: '';
	border-radius: 2px;
	display: inline-block;
	width: 14px;
	height: 14px;
	background: #fff;
	transition: 0.2s;
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	margin: auto;
	border: 1px solid #ababab;
}

input[type='checkbox']:checked + .input-label:after {
	position: absolute;
	left: 6px;
	top: 0px;
	bottom: 0;
	margin: auto;
	width: 3px;
	height: 7px;
	border: solid #000000;
	border-width: 0 1px 1px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}

input[type='checkbox'] + .input-label_newsletter {
	display: block;
	cursor: pointer;
	padding-left: 22px;
	position: relative;
}

.input-label_newsletter::before,
.input-label_newsletter::after {
	content: ' ';
}

input[type='checkbox'] + .input-label_newsletter:before {
	content: '';
	border-radius: 2px;
	display: inline-block;
	width: 14px;
	height: 14px;
	background: #fff;
	transition: 0.2s;
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	margin: auto;
	border: 1px solid #ababab;
}

input[type='checkbox']:checked + .input-label_newsletter:after {
	position: absolute;
	left: 6px;
	top: 0px;
	bottom: 0;
	margin: auto;
	width: 3px;
	height: 7px;
	border: solid #000000;
	border-width: 0 1px 1px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}

form .text-label label {
	border: none;
	outline: none;
	display: block;
	position: absolute;
	left: 14px;
	/* bottom: 0;*/
	top: 0;
	font-size: 14px;
	line-height: 22px;
	color: #7b7b7b;
	pointer-events: none;
	/*margin: auto;*/
	height: fit-content;
	transition: 0.2s ease-out;
}

form input:placeholder-shown + label {
	transform: translateY(13px);
}

form input:not(:placeholder-shown) + label,
form input:focus + label {
	transform: translateY(0);
	font-size: 10px;
}

form .text-label input:focus-visible + label,
form .text-label input:focus + label,
form .text-label.actived > label,
form .phone-label label {
	/* top: 1px;
    bottom: unset;*/
	font-size: 10px;
	color: #717171;
}

.form-input.text-label.actived input:invalid,
.form-input.text-label.actived .no-validate,
.form-input.text-label.error input,
form .text-label.error > .css-b62m3t-container > div:last-child,
form .text-label.error > .css-1pahdxg-control {
	background-color: #ffe8e6 !important;
}

form .text-label.actived > input {
	background-color: #f4f4f4;
	border: 1px solid #000;
}

input:focus-visible,
input:focus {
	// border: 1px solid #000;
}

*:focus::-webkit-input-placeholder {
	opacity: 0;
}

*:focus:-moz-placeholder {
	opacity: 0;
}

*:focus::-moz-placeholder {
	opacity: 0;
}

*:focus:-ms-input-placeholder {
	opacity: 0;
}

*:focus::placeholder {
	opacity: 0;
}

::-webkit-input-placeholder {
	color: #7b7b7b;
	font-family: 'Commissioner', sans-serif;
	font-weight: 400;
	font-size: 14px;
	opacity: 0;
}

:-ms-input-placeholder {
	color: #7b7b7b;
	font-family: 'Commissioner', sans-serif;
	font-weight: 400;
	font-size: 14px;
	opacity: 0;
}

::placeholder {
	color: #7b7b7b;
	font-family: 'Commissioner', sans-serif;
	font-weight: 400;
	font-size: 14px;
	opacity: 0;
}

.red {
	display: none;
}

input.error {
	box-shadow: 0.93px 3px 16px #ff0000a6;
}

.simple-link {
	text-align: right;
	margin-top: 12px;
}

.simple-link a {
	font-size: var(--picosize);
	line-height: 18px;
	text-decoration: underline;
	color: #000000;
}

button.submit:disabled {
	opacity: 0.4;
}

button.arrow-button.submit:after,
#go-to-form:after,
.orange-btn:after {
	content: '';
}

button.submit:hover:after,
#go-to-form:hover:after,
.orange-btn:hover:after,
.profile-btn:hover:after {
	-webkit-animation: bounceright 0.3s alternate ease 4 forwards;
	animation: bounceright 0.3s alternate ease 4 forwards;
}

button.submit a {
	color: #fff;
}

form label:last-of-type input {
	margin-bottom: 0;
}

.react-select__placeholder {
	color: #000 !important;
}

.breads {
	background-color: #fff;
}

/*page register success */
.grey-back {
	background-color: #f1f2f3;

	.form-input input {
		background: #f9f9f9;
	}
}

.info-box-message {
	max-width: 600px;
	margin: auto;
	text-align: center;
	background-color: #fff;
	border-radius: 5px;
	padding: 56px 48px;
}

.info-box-message > div {
	font-size: 16px;
	line-height: 20px;
}

.info-box-message strong {
	font-size: 20px;
	line-height: 26px;
}

.info-box-message img {
	margin: 0 auto 40px;
}

.info-box-message .link {
	margin: 40px auto 40px 0;
}

.space-arround {
	padding: 100px 0;
}

/*end page register success */
/*Category page */
.category-page {
	color: #000000;

	& > .content-wrapper {
		background: #f1f2f3;
		width: 100%;
		padding: 0;
		max-width: unset;
	}
}

// Category Page
.cat-list {
	padding: 0 0 70px;

	.title-wrapper {
		background: #f1f2f3;

		.cat-title-wrapper {
			width: 100%;
			max-width: 1244px;
			margin: 0 auto;
			padding: 50px 22px;
			display: flex;
			align-items: center;

			h1 {
				width: 306px;
				padding-right: 5px;

				div {
					margin-left: 6px;
					background-color: #fff;
					border-radius: 10px;
					width: 190px;
					height: 86px;
					display: grid;
					place-items: center;

					img {
						// width: 90px;
						// height: 48px;
					}
				}
			}

			.banner-categories {
				width: 100%;
				overflow-x: auto;

				.cat-item {
					display: flex;
					background: white;
					min-width: 165px;
					height: 48px;
					align-items: center;
					justify-content: space-evenly;

					.title-container {
						padding: 10px;
						text-align: center;
						font-family: Play;
						font-weight: 700;
						color: #6a727e;
					}

					&:hover {
						.title-container {
							color: black;
						}
					}

					&.active {
						// border: 1px solid #d71e26;

						.title-container {
							color: #d71e26;
						}
					}
				}
			}
		}
	}

	.products-section {
		.product-filters ul {
			gap: 5px;
			margin-bottom: 20px;
			position: relative;
		}

		.grid-part .product-box {
			text-align: left;

			.prod-img {
				min-height: 238px;

				.on-hover {
					position: absolute;
					top: 0;
				}
			}
		}
	}
}

.banner-cat {
	background-color: #f2f2f2;
	padding: 50px 0;
	min-height: 242px;
	overflow: hidden;
}

.banner-cat > div {
	gap: 20px 6%;
	position: relative;
	/* justify-content: space-between;*/
}

.banner-cat > div:after {
	position: absolute;
	content: '';
	background: transparent linear-gradient(90deg, #f2f2f200 0%, #f2f2f2 100%);
	right: -88px;
	top: 0;
	bottom: 0;
	width: 202px;
	height: 242px;
	margin: auto;
}

.left-side {
	height: fit-content;
	margin: auto 0;
	max-width: 230px;
}

.left-side p {
	font-weight: 400;
	margin-bottom: 0;
}

.right-side {
	gap: 24px;
	margin-right: -102px;
	overflow-x: auto;
	overflow-y: hidden;
	-ms-overflow-style: none;
	scrollbar-width: none;
}

.right-side::-webkit-scrollbar {
	display: none;
}

.right-side img {
	mix-blend-mode: multiply;
	max-width: 180px;
	min-width: 160px;
	aspect-ratio: 1;
}

.right-side h4 {
	text-transform: uppercase;
	color: #fff;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: fit-content;
	height: fit-content;
	margin: auto;
	padding: 0 20px;
	font-size: 14px;
	line-height: 16px;
}

.sub-item {
	position: relative;
	background-color: rgba(0, 0, 0, 0.39);
	display: inline-table;
	overflow: hidden;
	border-radius: 10px;
	/*  margin: auto;*/
}

.category-page h1 {
	font-size: 24px;
	line-height: 28px;
}

.short-filter {
	position: relative;
	padding-right: 15px;
}

.sort {
	margin-left: auto;
}

.sort .css-14el2xx-placeholder {
	min-width: 95px !important;
}

.short-filter::after {
	content: '';
	position: absolute;
	border-top: 1px solid;
	border-right: 1px solid;
	width: 6px;
	height: 6px;
	border-color: #000;
	transform: rotate(135deg);
	position: absolute;
	top: 23%;
	right: 0;
	transition: transform 0.5s;
}

.products-section {
	display: flex;
	justify-content: space-between;
	margin-top: 25px;

	@media (max-width: 810px) {
		justify-content: center;
	}
}

.product-list {
	grid-template-columns: repeat(3, minmax(160px, 282px));
	gap: 30px 24px;

	@media (max-width: 1120px) {
		gap: 30px 4px;
	}

	@media (max-width: 1075px) {
		grid-template-columns: repeat(2, minmax(160px, 300px));
	}
}

// Product-Box Common Rules.
.product-box {
	position: relative;
	color: #000000;

	.prod-img {
		// max-height: 255px; // img size & padding-top
		min-height: 135px;
		position: relative;
	}

	.add-wishlist {
		position: absolute;
		top: 12px;
		right: 12px;
		z-index: 2;
		border: none;
		width: auto;
		height: auto;
	}

	.black-friday {
		position: absolute;
		top: 0px;
		left: 0px;
		z-index: 10;
		background-color: #000000;
		color: white;
		padding: 2px 4px;
		font-size: var(--microsize);
		font-family: Play;
		font-weight: 600;
		text-transform: uppercase;
	}

	.product-statuses-tags {
		position: absolute;
		bottom: 40px;
		left: 0px;
		z-index: 10;
		color: white;
		font-size: var(--microsize);
		font-family: Play;
		font-weight: 600;
		text-transform: uppercase;
		display: flex;
		flex-direction: column;
		gap: 4px;

		span {
			width: fit-content;
			padding: 0 4px;
		}
	}

	.special-labels {
		display: flex;
		gap: 10px;
		margin-bottom: 16px;

		.label-item {
			height: 20px;
			padding: 0 4px;
			display: inline-flex;
			align-items: center;
			background: #d71e26;
			color: white;
			font-size: var(--microsize);
			font-family: Play;
			font-weight: 700;

			&.empty-item {
				padding: 0;
			}
		}
	}

	.prod-details {
		.brand-logo {
			font-weight: 700;
			line-height: 22px;
			min-height: 22px;
			text-transform: uppercase;
		}

		h3 {
			font-size: 14px;
			color: #6a727e;
			height: 40px;
			// Hide with ... if text is over 2 lines.
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
		}

		& > .flex-col {
			flex-flow: column nowrap;
		}
	}
}

.category-box {
	max-width: 335px;
	height: 408px;
	display: flex;
	flex-flow: column nowrap;
	background: white;

	.category-img {
		width: 100%;
		height: calc(100% - 45px);
		overflow: hidden;

		img {
			display: block;
			object-fit: cover;
			object-position: center;
			width: 100%;
			height: 100%;
			transition: 0.3s;
		}
	}

	@media (max-width: 1800px) {
		height: auto;
	}

	.category-name {
		display: flex;
		height: 45px;
		justify-content: center;
		align-items: center;
		font-weight: 700;
		font-size: 16px;
		color: black;
	}

	&:hover {
		.category-img {
			img {
				transform: scale(1.03);
			}
		}
	}
}

.sub-item a {
	position: absolute;
	height: 100%;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 1;
}

.prod-img {
	position: relative;
	overflow: hidden;
	margin-bottom: 8px;

	img {
		display: block;
		object-fit: cover;
		object-position: center;
		height: inherit !important;
		width: inherit !important;
		margin: auto;
		aspect-ratio: 1;
	}

	&:after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0.03;
		width: 100%;
		height: 100%;
		background-color: #000; // Grey background behind product box>img
		pointer-events: none;
	}
}

.on-hover {
	transition:
		visibility 0.4s ease-in-out,
		opacity 0.6s linear,
		transform 0.4s ease-in-out;
	visibility: hidden;
	opacity: 0;
	width: 0;
	height: 0;
	transform: translateX(100%);
}

.product-box:hover {
	.on-hover {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		background-color: #fff;
		visibility: visible;
		opacity: 1;
		transform: translateX(0);
	}
}

.product-box .prod-colors input {
	width: 18px;
	height: 18px;
	display: block;
	border-radius: 100%;
	cursor: pointer;
	appearance: none;
	margin: 0;
	border: 1px solid white;
	background-clip: content-box;
	padding: 3px;
	cursor: pointer;
}

.product-box .prod-colors input:checked {
	border: 1px solid #000000;
}

.prod-details {
	// padding-top: 15px;
	/*background-color: #fff;*/
}

.prod-colors.flex-col {
	justify-content: left;
	gap: 5px;
	align-items: center;
	flex-wrap: wrap;
}

.product-box {
	.prod-colors.flex-col {
		input {
			position: relative;
			// &:before {
			//     content: "";
			//     z-index: 1;
			//     width: 50%;
			//     height: 50%;
			//     position: absolute;
			//     top: calc(15%);
			//     left: calc(15%);
			//     display: block;
			//     border-radius: 100%;
			//     border: 2px solid rgba(230, 230, 230, 0.5);
			//     pointer-events: none;
			// }
		}
	}
}

.prod-details h3 {
	font-weight: 400;
	line-height: 20px;
	text-align: left;
	margin-bottom: 11px;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	-webkit-box-align: start;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
}

.prod-price {
	font-size: var(--microsize);
	font-family: Play;
	font-weight: 400;
	color: #d71e26;
	text-align: left;
	margin-bottom: 13px;

	span {
		color: #6a727e;
		text-decoration: line-through;
	}

	span.single-price {
		color: #000000;
		font-weight: 700;
		text-decoration: none;
		margin: 0;
	}
}

.prod-dim button:disabled {
	display: none;
}

.product-box .quick-buy {
	background-color: #fff;
	color: #6a727e;
	width: 100%;
	align-items: center;
	z-index: 2;
	height: auto;
}

.product-box .quick-buy span {
	font-size: 16px;
	line-height: 18px;
	font-weight: 400;
	visibility: visible;
	opacity: 1;
}

.btn-hover .prod-dim {
	visibility: visible;
	opacity: 1;
	gap: 16px;
	width: fit-content;
	height: auto;
	max-height: 90px;
	flex-wrap: wrap;
	justify-content: center;

	a {
		color: #000;

		&:hover {
			color: #d71e26;
		}

		&.disabled,
		&.disabled :hover {
			color: #6a727e;
			cursor: default;
		}
	}
}

.product-filters {
	top: 0;
	height: 100%;
	right: 0;
	width: 265px;
	padding-right: 40px;
	background-color: #ffffff;

	@media (max-width: 810px) {
		display: none;
	}

	.header-filters {
		margin-bottom: 26px;

		div {
			margin-bottom: 16px;
		}

		.clear-all {
			text-decoration: underline;
			color: #6a727e;
			line-height: 22px;
			cursor: pointer;
			margin-top: 16px;
		}
	}
}

.product-filters h2 {
	text-align: right;
}

.product-filters div.flex-col {
	// justify-content: space-between;
	// margin-bottom: 14px;
}

.filter-items li.choosen > a {
	&:before {
		background-image: url(../images/filter-choosen.svg);
	}
}

.product-filters .filter-content {
	padding: 18.5px 6px 17.5px;
}

.filters-list {
	overflow: auto;
	-ms-overflow-style: none;
	scrollbar-width: none;
}

.filters-list::-webkit-scrollbar {
	display: none;
}

.filters-list li:not(:first-child) .filter-content {
	border-top: 1px solid #f2f2f2;
}

.filter-content strong {
	position: relative;
	display: block;
	cursor: pointer;
	color: #000;
	font-family: Play;
	font-weight: var(--regsize);
	font-weight: 700;
}

.filter-content strong:before {
	content: '';
	position: absolute;
	background-color: #000000;
	top: 0;
	bottom: 0;
	right: 0;
	margin: auto;
	width: 11px;
	height: 1px;
}

.filter-content strong:after {
	transition: transform 0.5s;
	content: '';
	position: absolute;
	background-color: #000000;
	top: 0;
	bottom: 0;
	right: 0;
	margin: auto;
	width: 11px;
	height: 1px;
	transform: rotate(90deg);
}

.filter-content strong.active-filter:after {
	transform: rotate(0deg);
}

.filter-items {
	margin: 14px 0 3px;
	gap: 9px 10px;
}

.color-filter .fil-col {
	width: 20px;
	height: 20px;
	aspect-ratio: 1;
	margin-right: 10px;
	border-radius: 3px;
	border: 1px solid #f1f2f3;
}

.filter-items.color-filter li > a {
	justify-content: unset;
	word-break: break-word;
	text-align: left;
}

.filter-items.size-filter li > a {
	min-height: 34px;
}

.filter-items {
	li {
		padding-left: 10px;
	}

	li > div {
		width: 100%;
		padding: 4px 4px 4px 33px;
		align-items: center;
		font-size: var(--microsize);
		color: #6a727e;
		position: relative;
		margin: 2px 0px;
	}

	&:not(.color-filter) {
		li {
			& > div:before {
				position: absolute;
				content: '';
				width: 20px;
				height: 20px;
				background-image: url(../images/filter-not-choosen.svg);
				background-size: contain;
				left: 0;
				top: 5.5px;
			}

			&.choosen div:before {
				background-image: url(../images/filter-choosen.svg);
			}
		}
	}

	&.color-filter {
		li {
			& > div {
				padding: 6px 0px;
			}

			&.choosen {
				background-color: #f1f2f3;
				border: 1px solid #000;
			}
		}
	}
}

.size-filter div > a {
	min-height: 34px;
}

#range-price .filterslide-track.filterslide-track-0,
#range-price .filterslide-track.filterslide-track-2 {
	top: 24px;
	background-color: #f4f4f4;
}

#range-price .horizontal-slider {
	height: 36px;
	position: relative;
}

#range-price .filterslide-track {
	background-color: #000;
	top: 24px;
}

#range-price .filterslide-thumb {
	background: #fff;
	font-weight: normal;
	border: 4px solid #030303;
	width: 20px;
	height: 20px;
	box-shadow: none;
	top: 16px;
}

.prices-box {
	margin-top: 14px;
	justify-content: space-between;
}

.prices-box label {
	font-size: 14px;
	font-weight: 500;
	color: #000;
}

.prices-box input {
	height: 30px;
	border-radius: 4px;
	border: 1px solid #b0adad;
	outline: none;
	font-size: 12px;
	line-height: 13px;
	color: #000;
	font-weight: 500;
	max-width: 70px;
	margin-right: 6px;
	font-family: Ubuntu, Arial, sans-serif;
	padding: 5px;
}

.show-more-prods {
	text-align: center;
	font-size: 14px;
	line-height: 22px;
	color: #000;
	margin-top: 62px;
}

.progress-percentage {
	height: 5px;
	max-width: 100%;
	width: 384px;
	background-color: #f2f2f2;
	margin: auto;
	position: relative;
	margin-top: 20px;
}

.progress-percentage:after {
	position: absolute;
	content: '';
	left: 0;
	height: 100%;
	width: 100%;
	background-color: #000;
}

.see-more {
	font-size: 16px;
	line-height: 18px;
	color: #000;
	padding: 14px;
	min-width: 170px;
	border-radius: 0px 10px;
	border: 1px solid #000000;
	margin-top: 38px;
	transition: 0.3s ease-out;
}

.see-more:hover {
	color: #fff;
	background-color: #000;
}

.filters-btn {
	position: fixed;
	align-items: center;
	font-size: 12px;
	line-height: 22px;
	padding: 12px 33px;
	bottom: 50px;
	right: 0;
	left: 0;
	width: fit-content;
	margin: auto;
	border-radius: 50px;
	z-index: 1999;
}

.filters-btn img {
	margin-right: 12px;
}

.category-banner {
	grid-column: 1 / -1; // Span across all columns
	margin-bottom: 10px;
	padding: 0 22px;
}

.category-page .css-b62m3t-container .css-1s2u09g-control,
.category-page .css-b62m3t-container .css-1pahdxg-control {
	min-height: unset !important;
	background-color: #fff !important;
	padding: 0 !important;
	height: unset !important;
	outline: none !important;
	border: none !important;
}

.category-page .css-1s2u09g-control > div:first-child,
.category-page .css-1pahdxg-control > div:first-child {
	padding: 0 !important;
	width: auto !important;
	min-width: 71px !important;
	outline: none !important;
	border: none !important;
	display: flex;
}

.category-page .css-14el2xx-placeholder::after {
	top: 28%;
	width: 5px;
	height: 5px;
}

.category-page .css-14el2xx-placeholder {
	margin: 0 !important;
	color: #000000 !important;
	font-size: 12px;
	padding-right: 20px;
}

/*end Category */
/*login- register*/

@-webkit-keyframes appear {
	0% {
		opacity: 0;
	}
}

@keyframes appear {
	0% {
		opacity: 0;
	}
}

.login-modal main {
	height: -moz-fit-content;
	padding: 53px 18px 40px;
	overflow: auto;
	scrollbar-width: none;
}

.login-modal main::-webkit-scrollbar {
	display: none;
}

.login-content main {
	min-height: 556px;
}

/*.guest-register-content main{
    max-height: 402px;
}*/

#login-page {
	background-color: #f1f2f3;

	input[type='text'],
	input[type='email'],
	input[type='password'],
	input[type='tel'],
	input[type='date'],
	textarea {
		background-color: #fff;
		height: 48px;
		border: 0;
	}

	.phone-wrapper {
		position: relative;

		span.label {
			font-size: 10px;
			position: absolute;
			top: 0px;
			left: 45px;
			z-index: 1;
			opacity: 0.7;
			letter-spacing: 0.2px;
		}
	}

	.flag-dropdown {
		background-color: #fff;
	}
}

.register-content main {
	min-height: 778px;
}

.register-content .acceptance input[type='checkbox'] + .input-label:before {
	bottom: unset;
	top: 3px;
}

.login-modal main > button {
	position: absolute;
	top: 21px;
	right: 25px;
}

.login-modal ul {
	justify-content: center;
	margin-bottom: 36px;
}

.login-alter > div {
	justify-content: center;
	gap: 25px;
}

.login-modal li button {
	padding: 16px 17px;
	min-width: 280px;
	font-size: 16px;
	line-height: 18px;
	font-family: Play, Arial, sans-serif;
	font-weight: 700;
	border-bottom: 2px solid white;
	text-transform: uppercase;
}

.login-modal li button.active-log-tab {
	border-bottom: 2px solid #262626;
}

#login-form {
	text-align: center;
}

#login-form button.submit {
	margin-top: 36px;
	width: 100%;
	text-transform: uppercase;
}

#login-wrapper {
	max-width: 560px;
	margin: auto;
}

#login-wrapper .captcha-wrapper iframe {
	transform: translate(40px, 25px) scale(0.77);
}

.checkout #login-wrapper input,
.checkout .register-wrapper input {
	background-color: #f1f2f3;
	color: #000;
}

.login-alter {
	margin-top: 23px;
	padding-top: 19px;
	text-align: center;
	position: relative;
}

.login-alter:after {
	content: '';
	position: absolute;
	border-top: 1px solid #f4f4f4;
	top: 0;
	right: -18px;
	left: -18px;
	height: 1px;
}

.login-alter span {
	font-size: 14px;
	line-height: 16px;
	display: block;
	margin-bottom: 32px;
	color: #000;
}

.login-alter div > button {
	border: 1px solid #707070;
	height: 48px;
	padding: 10px 22px 10px 50px;
	width: 280px;
	align-items: center;
	font-size: 16px;
	line-height: 18px;
	color: #000;
	text-transform: uppercase;
	font-weight: 400;
	text-align: center;
	justify-content: center;
	position: relative;

	img {
		position: absolute;
		left: 20px;
	}
}

#account-info-wrapper,
.register-wrapper {
	max-width: 588px;
	margin: auto;
}

.register-wrapper > .grid-col {
	gap: 40px 24px;
	grid-template-columns: 282px 1fr;
}

.register-wrapper > .grid-col button {
	min-width: 282px;
	width: 100%;
	text-align: left;
	padding-left: 35px;
	position: relative;
}

.grid-col.gender-sec {
	grid-template-columns: 75px 1fr;
	gap: 19px 15px;
	margin: 23px 0;
}

.grid-col.gender-sec label {
	font-family: Ubuntu, Arial, sans-serif;
	font-weight: 700;
	font-size: 14px;
	line-height: 16px;
	color: #000;
	margin-right: 0;
	width: fit-content;
}

.sec-col-reg > div {
	color: #000;
	font-size: 14px;
	line-height: 20px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin-left: 20px;
}

.sec-col-reg ul {
	list-style-type: disc;
	padding-left: 17px;
}

.sec-col-reg button {
	margin-top: auto;
	text-align: center;
	padding: 15px;
}

.inputs {
	grid-template-columns: 1fr 1fr;
	gap: 0 24px;
}

#account-info .grid-col.gender-sec {
	margin: 25px 0 25px;
}

.accept-sec {
	gap: 24px;
	margin-bottom: 18px;
}

#account-info button {
	display: block;
	width: 100%;
}

.back-btn {
	gap: 10px;
	align-items: center;
	margin-bottom: 16px;
}

.form-input.text-label > .custom-pass {
	position: absolute !important;
	left: 0;
	display: none;
}

.form-input.text-label.actived > .custom-pass {
	display: block;
}

.custom-pass p {
	margin: 0 !important;
}

/*end login- register*/
/*start cart */
.back-page {
	background-color: #f1f2f3;
	// background: linear-gradient(to right,#FFFFFF 51%, #F4f4f4 51%);
}

#cart-page .css-b62m3t-container > div:last-child,
#cart-page .css-1pahdxg-control,
#cart-widget .css-b62m3t-container > div:last-child,
#cart-widget .css-1pahdxg-control {
	min-height: 34px !important;
	width: 54px;
	background-color: #fff !important;
	border: 1px solid #000000;
	color: #000000;
}

#cart-page .css-qc6sy-singleValue,
#cart-widget .css-qc6sy-singleValue {
	color: #000000 !important;
}

#cart-page .css-qc6sy-singleValue::after,
#cart-widget .css-qc6sy-singleValue::after {
	top: 37%;
	right: 3px;
}

.cart-box {
	background-color: #ffff;
	box-shadow: 0px 10px 30px #0000001a;
	padding: 28px 18px;
	border-radius: 5px;
	font-size: 14px;
	color: #000;
	line-height: 20px;
}

.cart-box h1,
.left-cart h1 {
	font-size: 20px;
	line-height: 24px;
	color: #000;
	text-align: center;
}

.cart-box h1 {
	text-align: left;
	margin-bottom: 14px;
}

.discount-coupon {
	text-decoration: underline;
	margin-bottom: 26px;
}

.order-synopsis {
	gap: 18px;
	padding-bottom: 27px;
	border-bottom: 1px solid #f4f4f4;
	margin-bottom: 24px;
}

.cart-box .order-synopsis .grid-col {
	grid-template-columns: 1fr 1fr;
	gap: 18px 10px;
}

.cart-box .order-synopsis .discount {
	position: relative;

	&:hover {
		cursor: pointer;
	}

	.details-indicator {
		position: absolute;
		top: 1px;
		left: 65px;
		color: #fff;
		font-weight: 900;
		width: 18px;
		height: 18px;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 19px;
		cursor: pointer;

		&:hover {
			opacity: 0.7;
		}
	}
}

.right-cart > div {
	// padding: 72px 0;
	max-width: 486px;
	margin-right: 0;
	margin-left: auto;
	width: 100%;
}

.right,
.right-cart,
.step2-container {
	.checkout-helper {
		background: #fff;
		max-width: 100%;
		padding: 24px 10px 38px 20px;
		border-radius: 5px;

		h1 {
			font-size: 20px;
			font-weight: 700;
			font-family: 'Play', 'Commissioner', sans-serif;
		}

		.wrapper {
			margin-left: 10px;
			display: flex;
			flex-direction: column;
			gap: 32px;

			.action {
				display: flex;
				align-items: center;

				.icon {
					margin-right: 22px;
					display: flex;
					align-items: center;
				}

				a,
				.text {
					color: #000;
					text-decoration: underline;
				}
			}
		}

		p {
			color: #6a727e;
			margin: 9px 0 35px 0;
		}

		@media (max-width: 900px) {
			padding-right: 24px;
			margin: 30px auto;
		}
	}

	.cart-box,
	.checkout-helper {
		@media (max-width: 640px) {
			border-radius: 0;
		}
	}
}

.cart-box button {
	width: 100%;
	padding: 15px;
}

.cart-items {
	gap: 20px;
}

.cart-item {
	grid-template-columns: 120px 1fr auto;
	gap: 24px;
	color: #000;
	border-bottom: 1px solid #f1f2f3;
	padding-bottom: 22px;
}

.cart-item > .flex-col {
	flex-direction: column;
}

.cart-item .bottom-right {
	text-align: right;
}

.cart-item .cart-item-error {
	color: #d12b2b;
	padding-top: 15px;
}

.cart-item .price {
	font-size: 16px;
	line-height: 18px;
	font-family: 'Play', 'Commissioner', sans-serif;
	font-weight: 700;
	color: #d71e26;
	text-align: right;

	span {
		margin-left: 7px;
		color: #6a727e;
		text-decoration: line-through;
	}

	.single-price {
		color: #000;
		text-decoration: none;
	}
}

.cart-item .edit.flex-col {
	justify-content: space-between;

	button {
		background: #f1f2f3;
		width: 33px;
		height: 33px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 100%;
		margin-left: auto;
	}
}

.cart-item-info > .quantity,
.color {
	margin-bottom: 7px;
}

.cart-item-info > .edit-mobile {
	margin-top: 15px;

	.price {
		font-size: 14px;
		font-weight: 400;
	}
}

.cart-item .picture {
	width: 74px;
	height: 74px;

	img {
		height: 100%;
		width: 100%;
	}
}

.cart-item h4 {
	font-weight: 700;
	font-size: 16px;
	font-family: 'Roboto';
	line-height: 21px;
	text-align: left;
	max-width: 250px;
	margin-bottom: 8px;
}

.cart-item .color,
.cart-item .dimension {
	font-size: 14px;
	color: #6a727e;
	line-height: 22px;
}

.cart-item .quantity {
	margin-top: auto;
	margin-bottom: 0;
}

.cart-item-info span {
	font-weight: 500;
}

.cart-item .color-hex {
	margin-top: 9px;
	border-radius: 2px;
}

.empty-cart {
	margin: 60px 0 70px;
	text-align: center;

	.head-account {
		position: relative;
		width: 100%;
		text-align: center;

		h1 {
			font-size: 20px;
			line-height: 24px;

			@media (max-width: 480px) {
				font-size: 16px;
				line-height: 18px;
			}
		}
	}

	.empty-content {
		margin-top: 40px;
	}

	.submit {
		display: flex;
		margin: 40px auto 0 auto;
		text-transform: uppercase;
		width: 235px;
		height: 48px;
		border: 1px solid black;
		border-radius: 0;
		font-family: 'Play', 'Commissioner', sans-serif;
		font-weight: 700;
		padding: 0;
		align-items: center;
		justify-content: center;
		color: black;

		&::after {
			background: none;
		}
	}

	@media (max-width: 640px) {
		margin: 20px 0 60px;
	}
}

.discount {
	// color: #28c790;
}

.amount {
	text-align: right;
}

.total .amount {
	font-weight: 700;
	font-size: 16px;
	line-height: 18px;
}

.cart-ship-info li {
	font-size: 16px;
}

.cart-ship-info li img {
	margin-right: 12px;
}

#cart-page {
	.content-wrapper {
		.cart-wrapper {
			display: flex;
			gap: 24px;
		}

		.left-cart {
			width: 100%;
			max-width: 792px;
			background-color: unset;

			& > div {
				background: #fff;
				max-width: 100%;
				padding: 20px 24px;
			}

			.cart-item {
				gap: 0;
				padding: 0;
				padding-bottom: 22px;

				.cart-item-info {
					margin-left: 45px;
					padding: 0;

					a {
						text-decoration: none;
						color: #262828;
					}
				}

				.quantity {
					margin: 0 110px 0 auto;
					padding-left: 20px;

					.controls {
						width: 70px;
						height: 30px;
						border: 2px solid #000;
						display: flex;
						justify-content: space-between;
						align-items: center;

						img {
							width: 12px;
						}

						.amount {
							font-size: 16px;
						}

						.button {
							cursor: pointer;
							height: 100%;
							display: grid;
							place-items: center;
							padding: 5px;
						}
					}
				}

				.edit {
					padding: 0;
					max-width: 120px;
					width: 120px;

					.cart-item-error {
						text-align: left;
						padding: 0;
						margin: 10px 0 5px 0;
					}
				}

				.edit-mobile {
					display: none;
				}
			}

			.cart-ship-info {
				margin-top: 28px;
			}

			.total {
				padding: 0;
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-top: 30px;

				.name {
					font-size: 16px;
				}

				.amount {
					font-family: 'Play', 'Commissioner', sans-serif;
					font-size: 24px;
					font-weight: 700;
					line-height: 29px;
				}
			}

			.coupon-input {
				background: #fff;
				padding: 0;

				.top-part,
				.bot-part {
					display: flex;
					align-items: center;
					justify-content: space-between;

					span {
						font-size: 16px;
						text-decoration: underline;
					}
				}

				.top-part span {
					cursor: pointer;

					img {
						transition: 0.3s ease-out;

						&.expanded {
							transform: rotate(180deg);
							transition: 0.3s ease-out;
						}
					}
				}

				.bot-part {
					position: relative;
					margin-top: 14px;

					label {
						position: absolute;
						left: 14px;
						top: 50%;
						transform: translateY(-50%);
						color: #454545;
						pointer-events: none;
					}

					input {
						height: 48px;
						width: 100%;
						background-color: #f1f2f3;
						border: none;
						font-size: 14px;
						padding-left: 14px;
						padding-right: 120px;
					}

					&:has(input:focus-within) label {
						display: none;
					}

					button {
						position: absolute;
						right: 14px;
						font-size: 14px;
						font-weight: 700;
						text-transform: uppercase;
					}

					display: none;

					&.expanded {
						display: flex;
					}
				}
			}
		}

		.buttons-cart {
			background: #f1f2f3;
			justify-content: space-between;
			margin-top: 20px;
			padding: 0;

			button {
				text-transform: uppercase;
				width: 235px;
				height: 48px;
				border: 1px solid black;
				border-radius: 0;
				font-family: 'Play', 'Commissioner', sans-serif;
				font-weight: 700;

				&::after {
					background: none;
				}
			}
		}

		.right-cart {
			width: 100%;
			max-width: 384px;
		}

		@media (max-width: 900px) {
			padding: 0;

			.cart-wrapper {
				flex-wrap: wrap;
				justify-content: center;
				gap: 0; // Apply margin to CheckoutHelper
			}

			.left-cart {
				max-width: 100%;

				.buttons-cart {
					padding: 0;
					margin: 24px 22px 0px 22px;
					justify-content: space-evenly;
					flex-wrap: wrap;
					gap: 19px;
				}
			}
		}

		@media (max-width: 767px) {
			.left-cart {
				.cart-item {
					position: relative;

					.cart-item-info {
						margin-left: 26px;
						padding: 0;
					}

					.quantity {
						position: absolute;
						left: 100px;
						bottom: 0px;
						transform: translateY(-50%);
						padding-left: 0;
					}

					.edit {
						margin: 0 0 0 auto;

						& > .bottom-right {
							display: none;
						}
					}

					.edit-mobile {
						display: flex;
						align-items: center;
						margin-left: 90px;

						.bottom-right {
							.price {
								font-size: 16px;
								line-height: 18px;
								font-weight: 700;
							}
						}
					}
				}
			}
		}

		@media (max-width: 480px) {
			.left-cart {
				width: 100%;

				.cart-item {
					.cart-item-info {
						margin-left: 12px;
						max-width: 175px;
					}

					.quantity {
						left: calc(100px - 14px);
					}

					.edit {
						width: unset;
					}
				}

				.buttons-cart {
					button {
						width: 100%;
					}
				}
			}

			.right-cart {
				padding: 0;
				width: 100%;
				max-width: 100%;
			}
		}
	}
}

/*end cart */
/*start account*/
.sidebar a {
	font-size: 14px;
	line-height: 20px;
	color: #000;
	padding: 12px 5px 12px 20px;
	padding: 5px 16px;
	display: flex;
	align-items: center;
	gap: 15px;
	transition: 0.1s ease-out;
	margin-bottom: 8px;
}

.sidebar a:hover,
.active-side {
	background-color: #fff;
	font-weight: 700;
}

// refactor entire account-page to be all grouped
.account-page {
	background-color: #f1f2f3;

	.breads {
		border-bottom: 1px solid #b5bac3;

		.breads-wrapper {
			display: flex;
			align-items: center;
			padding: 8px 22px;
			max-width: 1214px;

			.bread-item {
				position: relative;
				margin-right: 12px;
				padding-right: 5px;
				color: #030303;
				font-size: 12px;

				& > ::after {
					content: '>';
					position: absolute;
					display: inline-block;
					left: 100%;
					color: #b5bac3;
				}
			}
		}
	}

	.content-wrapper {
		max-width: 996px;
		padding-top: 65px;

		h2 {
			font-size: 18px;
			line-height: 29px;
		}

		.side2 {
			margin-top: 32px;
		}

		.center2 {
			margin-top: -29px; // from line-height above.
			min-width: 690px;
		}
	}

	.form-content input {
		background: #f1f2f3;
		height: 48px;
		border-radius: 0;
	}

	@media (max-width: 900px) {
		.breads {
			display: none;
		}

		.content-wrapper {
			padding: 0;
			padding-bottom: 32px;

			.center2 {
				margin-top: 0;

				.main-content {
					border-radius: 0;
				}
			}
		}
	}
}

.account-page h1 {
	font-size: 20px;
	line-height: 24px;
	font-weight: 700;
	color: #000;
	text-align: center;
}

.account-page p {
	text-align: center;
}

.account-page .content-wrapper > h2 {
	text-align: left;
}

.account-page .content-wrapper > .grid-col {
	grid-template-columns: minmax(150px, 282px) 1fr;
	gap: 24px;
}

.account-page .main-content {
	background: #fff;
	box-shadow: 0px 10px 30px #00000029;
	border-radius: 5px;
	padding: 40px 34px;
}

.acc-content {
	max-width: 690px;
	margin: auto;
}

.account-page .main-content:nth-child(2) {
	margin-top: 30px;
}

.log-out {
	font-size: 16px;
	font-weight: 700;
	font-family: 'Commissioner', sans-serif;
	line-height: 19px;
	color: #000;
	padding: 14px;
	width: 100%;
	border: 1px solid #000;
	display: block;
	text-align: center;
}

h1.acc-title {
	margin-bottom: 30px;
}

.my-account-form {
	max-width: 486px;
	margin-right: auto;
	margin-left: auto;
}

.right-sit {
	margin-top: 28px;
	margin-left: auto;
	display: block;
	min-width: 204px;
}

/*address & orders */
.address-list-headers > ul,
.orders-list-headers > ul,
.order-item > ul,
.address-item > ul {
	grid-template-columns: 22% 22% 22% 70px 1fr;
	gap: 21px 4.6%;
	padding-left: 8.27%;
	padding-right: 10px;
}

.address-list-headers > ul,
.orders-list-headers > ul {
	margin: 36px 0 16px;
}

.address-list-headers > ul li,
.orders-list-headers > ul li {
	font-size: 14px;
	color: #717171;
	line-height: 20px;
}

.address-item,
.order-item {
	background-color: #f1f2f3;
}

.address-item > ul,
.order-item > ul {
	align-items: center;
	height: 70px;

	li {
		font-size: 12px;
		line-height: 20px;
		color: #000;
		font-weight: 700;
	}
}

.address-item,
.order-item {
	margin-bottom: 17px;
}

.address-list li.controls,
.orders-list li.controls {
	gap: 20px;
	justify-self: end;
}

.add-address button {
	font-size: 16px;
	line-height: 18px;
	color: #000;
	padding: 13.5px 16px 13.5px 22px;
	border: 1px solid #000;
	text-align: left;
	min-width: 204px;
	justify-content: space-between;
	margin-left: auto;
	gap: 10px;
	transition: 0.3s ease-out;
}

.add-address button svg line {
	transition: 0.3s ease-out;
}

.add-address button:hover {
	color: #fff;
	background-color: #000;
}

.add-address button:hover svg line {
	stroke: #fff;
}

/*order history */
.order-list > ul,
.ordhist-item > ul {
	grid-template-columns: 20% 16% 16% 36%;
	gap: 20px;
}

.ordhist-item > ul {
	padding: 8px 8px 8px 57px;
	overflow: hidden;
}

.ordhist-item img {
	object-fit: contain;
	min-width: 40px;
}

.ordhist-item .prods > ul li:first-child {
	margin-left: auto;
}

.ordhist-item .prods > ul {
	gap: 2px;
	/* justify-content: flex-end;*/
	overflow: auto;
	-ms-overflow-style: none;
	scrollbar-width: none;
}

.ordhist-item .prods > ul::-webkit-scrollbar {
	display: none;
}

.ordhist-item .status {
	font-size: 10px;
	line-height: 14px;
	font-weight: 400;
	color: #717171;
}

/*end account*/
/*start wishlists */
// Wishlist button
.add-wishlist {
	width: 48px;
	height: 48px;
	border: 1px solid #b5bac3;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;

	svg path {
		fill: none;
		stroke: #000;
	}

	&.active svg path {
		fill: red;
		stroke: red;
	}
}

.add-wishlist.active svg path {
	fill: red;
	stroke: red;
}

.wishlist-page {
	color: #000;

	.product-list {
		flex-wrap: wrap;
		justify-content: center;

		.product-box {
			max-width: 282px;

			.on-hover {
				position: absolute;
				top: 0;
			}

			&:last-child {
				margin-right: auto;
			}
		}
	}
}

.wishlist-page > .content-wrapper.body {
	padding-bottom: 96px;
}

.wishlist-page h1 {
	font-size: 20px;
	line-height: 24px;
	font-weight: 700;
	color: #000;
	text-align: center;
}

.wishlist-page .page-title {
	justify-content: space-between;
	padding-top: 60px;
	padding-bottom: 30px;
	margin-bottom: 0;
}

.wishlist-page .page-title > button,
.wishlist-page .page-title > div {
	width: 244px;
}

.wishlist-page .page-title button span.mobile900:hover {
	text-decoration: underline;
}

.wishlist-page .add-address button {
	min-width: 244px;
}

.wishlist-page .name {
	margin-top: 23px;
	margin-bottom: 8px;
	color: #000;
	font-weight: 700;
	font-size: 16px;
	line-height: 18px;
}

.wishlist-page .quantity {
	color: #717171;
	font-size: 14px;
	line-height: 21px;
}

.wishlist-items {
	grid-template-columns: repeat(2, minmax(150px, 440px));
	gap: 30px;
}

.masonry-pictures {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	gap: 24px;
	height: 284px;
}

.masonry-pictures > div:first-child {
	width: 65.1%;
}

.masonry-pictures img {
	object-fit: cover;
	object-position: top;
	width: 100%;
}

.masonry-pictures .grid-col {
	gap: 14px;
	width: 30.46%;
}

.edit-wishlist button {
	font-size: 16px;
	line-height: 18px;
	font-family: Ubuntu, Arial, sans-serif;
	color: #000;
	margin-left: auto;
	align-items: center;
}

.edit-wishlist img {
	margin-right: 9px;
}

.one-wishlist .page-title {
	padding-bottom: 72px;
}

/*end wishlists */
/*modals wishlist */
.modal {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.84);
	z-index: 2003;
}

.modal-content {
	background-color: #fff;
	max-width: 588px;
	height: fit-content;
	margin: auto;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	padding: 56px 102px 42px;
	border-radius: 5px;
	overflow: auto;
	scrollbar-width: none;
	-webkit-animation: appear 400ms ease-in-out 1;
	animation: appear 400ms ease-in-out 1;
}

.modal-content::-webkit-scrollbar {
	display: none;
}

.modal-content h2 {
	font-size: 20px;
	line-height: 24px;
	color: #000;
	justify-content: center;
}

.modal-content h2 img {
	margin-right: 8px;
}

.modal-content .exit {
	position: absolute;
	top: 20px;
	right: 25px;
}

.add-to-wish-pop .modal-content {
	padding-right: 0;
	padding-left: 0;
}

.wishlists-list {
	margin-top: 37px;
	max-height: 280px;
	overflow: auto;
	-ms-overflow-style: none;
	scrollbar-width: none;
	gap: 17px;
	padding: 0 102px;
	background:
		linear-gradient(white 30%, rgba(255, 255, 255, 0)),
		linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
		radial-gradient(farthest-side at 50% 0, #00000010, rgba(0, 0, 0, 0)),
		radial-gradient(farthest-side at 50% 100%, #00000010, rgba(0, 0, 0, 0)) 0 100%;
	background-repeat: no-repeat;
	background-color: white;
	background-size:
		100% 40px,
		100% 40px,
		100% 14px,
		100% 14px;
	background-attachment: local, local, scroll, scroll;
}

.wishlists-list::-webkit-scrollbar {
	display: none;
}

.mini-wishlist {
	grid-template-columns: 78px 1fr 45px;
	gap: 24px;
}

.mini-wishlist button {
	border-radius: 200px;
	height: 45px;
	padding: 14px;
	align-self: center;
}

.mini-wishlist img {
	object-fit: cover;
	object-position: top;
}

.info-wishlist .name {
	font-size: 16px;
	line-height: 18px;
	font-weight: 700;
	color: #000;
	margin-bottom: 20px;
}

.info-wishlist .quantity {
	font-size: 14px;
	line-height: 20px;
	color: #717171;
}

.modal-content .add-address {
	background-color: #fff;
	padding: 20px 0 20px;
	background-color: #fff;
	margin-bottom: -40px;
}

.modal-content .add-address button {
	margin: auto;
}

.no-img {
	height: 78px;
	background-color: #f4f4f4;
}

.new-wishlist-addition p {
	margin: 16px auto 22px;
	text-align: center;
	max-width: 250px;
	color: #000000;
	line-height: 20px;
}

.wish-btn {
	text-align: left;
	padding-left: 32px;
}

.add-wish-modal {
	padding-top: 77px;
	padding-bottom: 66px;
}

p.edit-wish-info {
	width: 100%;
	text-align: left;
	max-width: unset;
	margin: 27px auto 14px;
}

.submit.edit-save {
	width: 100%;
}

.extra-del {
	position: relative;
	margin-top: 27px;
	padding-top: 30px;
}

.extra-del:before {
	content: '';
	position: absolute;
	top: 0;
	right: -102px;
	left: -102px;
	border-top: 1px solid #f4f4f4;
}

.extra-del button {
	font-size: 16px;
	line-height: 18px;
	color: #000;
	padding: 14px;
	min-width: 180px;
	border-radius: 5px;
	border: 1px solid #000;
	display: block;
	text-align: center;
}

.extra-del p {
	margin: 0px auto 10px;
}

.no-image {
	width: 100%;
	height: 100%;
	background-color: #fff;
	/*min-width: 134px;*/
	min-height: 134px;
}

.box-empty-list {
	max-width: 588px;
	background-color: #fff;
	margin: 0 auto 100px;
	border-radius: 5px;
	padding: 62px 48px;
	text-align: center;
}

.box-empty-list .submit {
	padding: 15px;
	min-width: 228px;
}

.box-empty-list p {
	margin: 21px auto 14px;
	font-size: 14px;
	line-height: 20px;
	color: #000;
}

.no-wishlists {
	padding: 56px 48px 40px;
	margin-top: 25px;
}

.no-wishlists button {
	align-self: center;
	background-color: #f4f4f4;
	border-radius: 200px;
	height: 45px;
	padding: 14px;
}

.interest-prods {
	margin-top: 100px;
}

.interest-prods h1 {
	text-align: left;
	font-size: 24px;
	line-height: 28px;
}

#personalised-products {
	padding: 55px 0;
}

#personalised-products h3 {
	margin-bottom: 29px;
}

#personalised-products .content-wrapper {
	position: relative;
}

/*follow order*/
.followorder-page .inputs {
	position: relative;
}

#follow-order form {
	max-width: 513px;
	margin: auto;
}

.followorder-page input {
	border-radius: 4px;
	background-color: #f1f2f3;
	position: relative;
}

#follow-order p {
	color: #717171;
	margin-bottom: 34px;
}

.followorder-btn {
	position: absolute;
	top: 0px;
	right: 0px;
	padding: 13px;
	background-color: #b0adad;
}

.order-status-items {
	gap: 43px;
	margin: 32px 0 19px;
}

.order-status-item {
	gap: 46px;
	align-items: center;

	.cancel-icon {
		width: 40px;
		height: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: #ababab;
		border-radius: 100%;

		img {
			width: 20px;
		}
	}
}

.order-status-item img {
	width: auto;
	max-width: unset;
}

.order-status-item li.flex-col {
	justify-content: space-between;
	width: 100%;
	gap: 10px 46px;
	color: #000;
	line-height: 20px;
	font-size: 14px;
}

.order-status-item:not(:first-child) li.img {
	position: relative;
}

.order-status-item:not(:first-child) li.img:after {
	position: absolute;
	content: '';
	border-top: 50px solid #ababab;
	right: 0;
	left: 0;
	width: 1px;
	height: 43px;
	margin: auto;
	bottom: -6px;
}

.order-status-item li.flex-col span {
	color: #f4f4f4;
	margin: 0 16px 0 22px;
}

.order-status-item li.flex-col > div:last-child {
	font-weight: 700;
}

#follow-order .further-msg p {
	max-width: 342px;
	text-align: center;
	margin: 34px auto 10px;
	color: #000000;
	line-height: 20px;
}

.further-msg a {
	padding: 13px;
	margin: auto;
	text-align: center;
	margin-top: 15px;
	width: fit-content;
	min-width: 308px;
	font-size: 16px;
	font-family: 'Play', 'Commissioner', sans-serif;
	text-transform: uppercase;
	justify-content: space-between;
	align-items: center;
	gap: 8px;
}

/* checkout start*/
#cart-page .content-wrapper,
#checkout .content-wrapper {
	max-width: 1200px;
	padding: 0;

	.left-cart .buttons-cart {
		background: #f1f2f3;
		justify-content: space-between;
		margin-top: 25px;
		padding: 0;

		button {
			text-transform: uppercase;
			width: 235px;
			height: 48px;
			border: 1px solid black;
			border-radius: 0;
			font-family: 'Play', 'Commissioner', sans-serif;
			font-weight: 700;
			padding: 0;

			&::after {
				background: none;
			}
		}

		.submit-disabled {
			background-color: #aba7a7;
			border: 1px solid #aba7a7;
			cursor: not-allowed;
		}

		@media (max-width: 1080px) {
			flex-wrap: wrap;
			gap: 12px;
		}

		@media (max-width: 767px) {
			button {
				max-width: 100%;
				width: 100%;
			}

			a.button {
				max-width: 100%;
				width: 100%;
			}
		}
	}
}

.delivery-step {
	margin-top: 50px;
}

.delivery-step .checkbox-area {
	margin-bottom: 25px;
}

.address-select-list {
	margin-top: 30px;
	margin-bottom: 60px;
	font-size: 16px;
	line-height: 22px;

	.form-input {
		background-color: unset !important;
		display: flex;
		column-gap: 78px;
		row-gap: 14px;
		flex-wrap: wrap;
	}

	.address-box {
		background-color: white;
		border-radius: 5px;
		width: 305px;
		height: 80px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding-left: 24px;
		cursor: pointer;

		.full-address {
			font-weight: 700;
			margin-top: 8px;
		}

		&:last-child {
			flex-direction: row;
			justify-content: start;
			align-items: center;

			.text {
				max-width: 150px;
			}

			.icon {
				margin: auto;
				margin-right: 22px;
				height: 30px;
				width: 30px;
				border-radius: 100%;
				background-color: #f1f2f3;
				display: grid;
				place-items: center;

				img {
					width: 50%;
				}
			}
		}

		&.selected {
			border: 1px solid black !important;
		}
	}
}

.radio label {
	display: flex;
	align-items: center;
	margin-right: 30px;
	font-weight: 700;
	color: #000;
	cursor: pointer;
}

.radio input[type='radio'] {
	display: inline-block;
	appearance: none;
	width: 20px;
	height: 20px;
	background: #ffffff;
	outline: 1px solid #000;
	border: none;
	border-radius: 50%;
	flex-shrink: 0;
	margin: auto 7px auto 0;
}

.radio input[type='radio']:checked {
	border: 5px solid #ffffff;
	background: #000000;
}

.step-number {
	width: 21px;
	min-width: 21px;
	height: 21px;
	color: #fff;
	font-size: 14px;
	line-height: 20px;
	background-color: #ababab;
	border-radius: 100%;
	justify-content: center;
	margin-right: 11px;
}

#checkout-failed-page img {
	margin-bottom: 46px;
}

#checkout-failed-page h2 {
	font-size: 20px;
	font-family: 'Play', 'Commissioner', sans-serif;
}

#checkout-failed-page .info-box-message {
	& > div {
		font-size: 14px;
		margin: 0 auto 40px;
		max-width: 384px;
		text-align: left;

		p {
			margin-top: 24px;
		}
	}

	button {
		font-size: 16px;
		font-weight: 700;
		font-family: 'Play', 'Commissioner', sans-serif;
		text-transform: uppercase;
		border-radius: 0;

		@media (max-width: 480px) {
			padding: 0;
		}
	}
}

.shadow-header {
	background-color: #f1f2f3;

	.checkout-logo {
		background: #000;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 80px;

		img {
			height: 52px;
		}
	}

	.checkout-steps {
		display: flex;
		justify-content: center;
		padding: 46px 0;
		margin: 0;

		.checkout-step {
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: 11px;
			position: relative;

			.step-numb {
				width: 30px;
				height: 30px;
				font-weight: 700;
				border-radius: 100%;
				border: 1px solid #b5bac3;
				display: flex;
				align-items: center;
				justify-content: center;
				color: #b5bac3;
				z-index: 2;

				&.active {
					border: 1px solid black;
					background: #fff;
					color: #000;
				}

				&.completed {
					border: 1px solid #000;
				}
			}

			.step-description {
				font-family: 'Play', 'Commissioner', sans-serif;
				width: 200px;
				text-align: center;

				@media (max-width: 500px) {
					font-size: 11px;
				}
			}
		}

		.step-line {
			height: 2px;
			width: calc(100% - 30px);
			background-color: #b5bac3;
			position: absolute;
			left: calc(50% + 15px);
			z-index: 1;

			&.completed {
				background-color: #000;
			}
		}
	}
}

#order-synopsis-checkout .cart-items {
	gap: 10px;
	display: flex;
	flex-direction: column;

	.cart-item-info {
		padding-left: 24px;
		justify-content: space-between;
	}
}

#order-synopsis-checkout .cart-item {
	padding: 12px 16px;
	border: none;
	background: #f1f2f3;

	.picture {
		width: 97px;
		height: 97px;
		min-width: 97px;
	}

	.cart-item-info {
		width: 100%;
	}

	.quant-col {
		align-items: center;
	}

	.price {
		color: #262828;
	}
}

#order-synopsis-checkout .cart-item h4 {
	margin-bottom: 8px;
	font-size: 14px;
}

#order-synopsis-checkout .cart-item .color {
	font-size: 12px;
	line-height: 13px;
}

#order-synopsis-checkout .cart-item .quantity {
	color: #717171;
}

#order-synopsis-checkout .cart-item .price {
	font-size: 16px;
}

#order-synopsis-checkout .cart-item-info span {
	font-weight: 400;
}

div.quant-col {
	justify-content: space-between;
	margin-bottom: 0;
}

#order-synopsis-checkout .order-synopsis {
	border-bottom: none;
	padding: 0;
	margin: 0;
	padding-top: 31px;
}

.line {
	border-top: 1px solid #f4f4f4;
	margin-top: 28px;
	margin-left: -18px; // padding left-right is 18px
	margin-right: -18px;
}

#store-pickup-fields {
	margin: 13px 0;
}

#courier-fields {
	margin-top: 22px;
}

// #courier-fields > div {
//     gap: 24px;
// }

#courier-fields label {
	position: relative;
	flex-direction: column;
	max-width: 180px;
	width: 100%;
	height: 100px;
	justify-content: space-between;
	padding: 16px 0 15px;
	color: #ababab;
	font-weight: 700;
	border-radius: 5px;
	box-shadow: 0px 4px 6px #00000029;
	margin: 0;
}

#courier-fields input {
	display: none;
}

#courier-fields input[type='radio']:checked + label {
	border: 1px solid #000;
	background-color: #f4f4f4;
	box-shadow: none;
}

#courier-fields .radio input[type='radio']:checked {
	border: 1px solid;
}

.payment-part label.radio-label {
	background-color: #fff;
	border: 2px solid #f4f4f4;
	padding: 0 27px 0 74px;
	border-radius: 5px;
	height: 70px;
	margin-right: 0;
	margin-bottom: 11px;
	font-weight: 400;
	line-height: 20px;
	font-size: 14px !important;
}

.payment-part input[type='radio'] + .radio-label:before {
	left: 28px;
	border-color: #c4c1bf;
}

.payment-part input[type='radio']:checked + .radio-label span {
	font-weight: 700;
}

.payment-part input[type='radio']:checked + .radio-label:after {
	left: 34px;
}

.payment-part label.radio-label span {
	align-items: center;
}

.payment-part ul li {
	img {
		margin-right: 20px;
		mix-blend-mode: multiply;
	}

	&:last-child {
		img {
			margin-left: 10px;
			margin-right: 32px;
		}
	}
}

.step3-page .acceptance .input-label {
	margin-left: auto;
	width: fit-content;
}

#checkout .payment-part .radio {
	margin-bottom: 18px;
}

.order-completed-page {
	.content-wrapper {
		padding: 0;
		max-width: 100%;
		background-color: #f1f2f3;
		padding-bottom: 38px;

		.message-success {
			text-align: center;
			background-color: #fff;
			width: 100%;

			& > div {
				display: flex;
				flex-direction: column;
				align-items: center;
				padding: 0 35px 46px 35px;
				margin: 0 auto;
			}

			img {
				margin: auto;
			}

			.order-number {
				font-size: 20px;
				line-height: 28px;
				color: #000;
				margin-top: 12px;
				max-width: 530px;

				strong {
					background: #fffca7;
					font-family: 'Play', 'Commissioner', sans-serif;
					padding: 0 5px;
				}
			}
		}

		.order-summary-content {
			max-width: 996px;
			margin: 38px auto 0 auto;
			display: flex;
			justify-content: space-between;

			.shipping-synopsis,
			.notice {
				padding: 55px 75px;
				border-radius: 5px;
				box-shadow: 0px 10px 30px #0000001a;
				background-color: #fff;
				height: 375px;

				h2 {
					text-align: left;
					line-height: 24px;
					font-family: 'Play', 'Commissioner', sans-serif;
					text-transform: uppercase;
					margin-bottom: 28px;
				}

				ul {
					margin-bottom: 28px;
				}
			}

			.notice ul li {
				width: 90%;
				justify-content: space-between;
			}

			.shipping-synopsis {
				width: 100%;
				max-width: 588px;
				margin-right: 10px;

				ul {
					display: grid;
					grid-template-columns: 180px 190px;
					row-gap: 40px;
					column-gap: 70px;

					li {
						line-break: anywhere;

						.head-info {
							font-weight: 700;
							font-size: 15px;
							margin-bottom: 18px;
						}
					}
				}

				.order-mobile {
					display: block;
				}
			}

			.notice {
				max-width: 384px;

				p {
					margin-bottom: 32px;
				}

				button {
					height: 48px;
					width: 100%;
					padding: 0 18px;
					position: relative;
					font-family: 'Play', 'Commissioner', sans-serif;
					font-weight: 700;
					font-size: 16px;
					text-align: left;

					&::after {
						content: '';
						background: url(../images/arrow-white.svg) no-repeat right;
						position: absolute;
						width: 12px;
						height: 12px;
						top: 50%;
						transform: translateY(-50%);
						right: 12px;
						transition: 0.3s ease-out;
					}
				}
			}
		}

		@media (max-width: 1080px) {
			.message-success {
				& > div {
					padding: 0;
					padding-bottom: 40px;
				}
			}

			.order-summary-content {
				flex-wrap: wrap;
				justify-content: center;
				gap: 30px;

				.shipping-synopsis {
					margin: 0;
				}

				.notice {
					max-width: 480px;
				}
			}
		}

		@media (max-width: 640px) {
			.order-summary-content {
				.shipping-synopsis {
					max-width: 100%;

					ul {
						column-gap: 30px;
					}
				}
			}
		}

		@media (max-width: 480px) {
			background-color: #fff;

			.order-summary-content {
				margin-top: 0;

				.shipping-synopsis {
					height: 100%;
					padding: 26px 20px;
					margin: 0 22px;

					ul {
						grid-template-columns: 240px;
						row-gap: 24px;

						li .head-info {
							margin-bottom: 16px;
						}
					}
				}

				.notice {
					max-width: 100%;
					height: 100%;
					border-radius: 0;
					padding: 48px 22px;

					p {
						margin-right: 35px;
					}

					button {
						padding: 0 35px;
					}
				}
			}
		}
	}
}

#checkout .content-wrapper .card-sides {
	display: flex;
	justify-content: space-between;

	.form-input,
	textarea {
		background: #fff;
	}

	h1 {
		font-size: 20px;
		font-weight: 700;
		font-family: 'Play', 'Commissioner', sans-serif;
		text-align: left;
		line-height: 24px;
	}

	.left {
		width: 100%;
		max-width: 690px;
		margin: 0 10px;

		// STEP 2
		.checkout-step-3 {
			.shipping-part {
				h1 {
					margin-bottom: 21px;
				}

				.radio {
					gap: 12px;

					label {
						background-color: #fff;
						margin: 0;
						padding: 14px;
						padding-right: 40px;
						display: flex;
						align-items: center;

						input {
							margin: 0 28px 0;
							margin-right: 28px;
							cursor: pointer;
						}

						input[type='radio']:checked {
							border: 5px solid #ffffff;
							background: #000;
						}

						.icon {
							width: 110px;
							min-width: 110px;
							height: 42px;
							display: flex;
							justify-content: center;
							align-items: center;
							border-radius: 5px;
							margin-right: 30px;
						}

						.icon-geniki {
							background: #1669c3;
						}

						.text {
							font-size: 16px;
							font-weight: 400;
						}

						.price {
							margin: auto 0 auto auto;
							font-family: 'Play', 'Commissioner', sans-serif;
							font-weight: 400;
						}

						&:has(input[type='radio']:checked) .text,
						&:has(input[type='radio']:checked) .price {
							font-weight: 700;
						}
					}
				}
			}

			.billing-part {
				margin-top: 46px;

				h1 {
					margin-bottom: 30px;
				}

				.radio {
					margin-bottom: 32px;
				}
			}

			.comments-part {
				margin-top: 34px;

				h4 {
					text-align: left;
					margin-bottom: 15px;
				}

				textarea {
					max-width: 100%;
					padding: 18px 20px;
					resize: none;
					font-family: 'Commissioner', sans-serif;
				}
			}

			.delivery-part {
				margin-top: 42px;

				.checkbox-area {
					margin-top: 14px;
				}

				.inputs {
					margin-top: 32px;
				}
			}

			.billing-part,
			.delivery-part {
				div:first-of-type {
					border: none;
				}
			}

			input[type='text'],
			input[type='tel'] {
				outline: none;
			}
		}

		// STEP 3
		.checkout-step-3 {
			h1 {
				margin-bottom: 21px;
			}

			.buttons-cart {
				display: flex;
			}
		}
	}

	.right-cart {
		margin-right: 10px;

		.checkout-helper {
			margin-top: 30px;
		}
	}

	@media (max-width: 1080px) {
		flex-wrap: wrap;
		justify-content: center;
		gap: 40px;

		.left-cart {
			padding: 0 23px;
			margin: 0;

			.checkout-step-2 {
				.billing-part {
					margin-top: 58px;
				}

				.comments-part {
					margin-top: 40px;
				}

				.delivery-part {
					margin-top: 48px;
				}
			}
		}

		.right-cart {
			margin: 0;
		}
	}

	@media (max-width: 900px) {
		gap: 32px;

		.left-cart {
			.checkout-step-2 {
				h1 {
					text-align: center;
				}
			}
		}
	}

	@media (max-width: 575px) {
		.left-cart .checkout-step-2 {
			.shipping-part .radio label {
				padding: 18px 14px;
				line-height: 17px;

				input {
					margin: 0;
					margin-right: 20px;
				}

				.icon {
					min-width: 65px;
					width: 65px;
					margin-right: 18px;
					height: 30px;
				}

				.text {
					font-size: 14px;
					// max-width: 110px;
				}

				.price {
					font-size: 12px;
				}
			}

			.billing-part .radio {
				justify-content: center;
			}
		}
	}
}

/*end check out*/

.title.flex-col h1 {
	font-size: 32px;
	line-height: 37px;
}

.taggbox-container {
	max-width: 1460px;
	margin: auto;
}

.homepage-blogs {
	overflow: hidden;
	margin-bottom: 138px;

	@media (max-width: 575px) {
		margin-bottom: 20px;
	}
}

.homepage-blogs .title a {
	font-size: 16px;
	line-height: 18px;
	color: #000;
	position: relative;
	padding-right: 24px;
	transition: 0.3s ease-out;
}

.homepage-blogs .title a::after {
	content: '';
	position: absolute;
	background: url(../images/arrow-black.svg) no-repeat right;
	top: 0;
	bottom: 0;
	margin: auto;
	width: 12px;
	height: 12px;
	right: 0;
	transition: 0.3s ease-out;
}

.homepage-blogs .title a:hover {
	padding-right: 26px;
}

.homepage-blogs .title a:hover:after {
	width: 18px;
	right: -5px;
}

.news-img img {
	width: 800px;
	height: 450px;
}

.news-box .news-img img {
	aspect-ratio: 1;
}

.category-label {
	font-size: 14px;
	font-family: 'Play', 'Commissioner', sans-serif;
	line-height: 20px;
	color: #d71e26;
	font-weight: 700;
	padding: 13px 0 21px 0;
	text-transform: uppercase;

	.spacer {
		margin-left: 3px;
	}

	.date {
		color: #000;
		margin-left: 8px;
		font-family: 'Commissioner', sans-serif;
	}

	@media (max-width: 641px) {
		.spacer {
			margin-left: 0;
		}

		.date {
			margin-left: 5px;
		}
	}
}

.news-details {
	max-width: 268px;
	white-space: normal;
}

.news-details a {
	color: #000;
}

.news-details h3 {
	font-size: 16px;
	font-family: 'Play', 'Commissioner', sans-serif;
	line-height: 28px;
	margin: 0;
}

.news-img img {
	transition: 0.3s ease-in-out;
}

.news-box:hover img {
	transform: scale(1.1);
}

/*start news page*/

.back-news-page {
	background-color: #fff;

	#pager {
		margin: 0;
		padding-bottom: 105px;

		button {
			border-radius: 0;
			font-weight: 700;
		}

		.arrow img {
			width: 6px;
		}

		@media (max-width: 641px) {
			padding-bottom: 48px;
		}
	}
}

.news-sides {
	padding-bottom: 125px;

	@media (max-width: 641px) {
		padding-bottom: 48px;
	}
}

.news-entry-wrapper {
	.previous-next-post {
		display: flex;
		justify-content: space-between;
		font-size: 16px;
		font-family: 'Play', 'Commissioner', sans-serif;
		font-weight: 700;
		margin-top: 26px;

		@media (max-width: 575px) {
			margin-top: 30px;
			margin: 30px 22px 0 22px;
		}

		.post-title {
			max-width: 256px;
			margin-top: 13px;
			font-size: 14px;
			font-weight: 400;
			font-family: 'Commissioner', sans-serif;

			@media (max-width: 575px) {
				display: none;
			}
		}

		.action {
			display: flex;
			align-items: center;
			gap: 16px;
		}

		.next {
			.action {
				justify-content: end;
			}

			svg {
				transform: rotateY(180deg);
			}
		}

		a {
			color: #000;
			text-decoration: none;
		}
	}
}

.left-news-side {
	max-width: 792px;
	margin: 0 auto;
	padding-top: 60px;

	@media (max-width: 575px) {
		width: 100%;
		padding-top: 35px;
	}
}

.newslist {
	gap: 55px 24px;
	grid-template-columns: repeat(2, minmax(130px, 1fr));

	.category-label {
		padding: 14px 0 17px 0;
	}
}

.top-pad {
	margin: 0 auto;
}

.right-news-side {
	background-color: #f1f2f3;
	margin: 0;
	max-width: 100%;
	text-align: center;

	.cats-title {
		font-size: 32px;
		font-family: 'Play', 'Commissioner', sans-serif;
		line-height: 38px;
		font-weight: 600;
		margin: 0 auto;
		padding-top: 42px;

		@media (max-width: 901px) {
			padding-top: 31px;
		}
	}

	.cats-list ul {
		display: flex;
		font-weight: 700;
		font-family: 'Play', 'Commissioner', sans-serif;
		overflow: hidden;
		padding: 30px 0;
		margin: 0 auto;
		max-width: 800px;
		gap: 30px;
		position: relative;

		li a {
			padding: 13px;
			border: 1px solid #000;
			border-radius: 30px;
			text-transform: uppercase;
			text-decoration: none;
			white-space: nowrap;
			color: #000;
		}

		li a.active {
			background-color: #000;
			color: #fff;
		}

		li a:hover {
			color: white;
			background-color: black;
		}

		:first-child > a {
			background-color: #f1f2f3;
		}

		> li {
			padding: 15px 0;
		}

		.swiper {
			padding: 0 20px;
			overflow: visible;

			.swiper-wrapper {
				margin-right: 400px;
				padding: 13px 0;

				.swiper-slide {
					width: auto !important;
				}
			}
		}

		@media (max-width: 768px) {
			gap: 16px;
		}
	}
}

.border-title {
	font-size: 16px;
	line-height: 18px;
	color: #000;
	padding: 14px;
	width: 100%;
	border-radius: 5px;
	border: 1px solid #000;
	display: block;
	text-align: center;
	margin-bottom: 19px;
}

.promoted-article {
	margin-bottom: 71px;
}

.promoted-article h1 {
	font-size: 24px;
	font-family: 'Play', 'Commissioner', sans-serif;
	font-weight: 700;
	line-height: 29px;
}

.promoted-article .news-details {
	padding: 0;
	max-width: unset;
}

.news-newsletter-area {
	background-color: #ffff;
	border-radius: 5px;
	padding: 30px 28px 63px;
	margin-bottom: 30px;
}

.news-newsletter-area h4 {
	font-size: 16px;
	margin-bottom: 23px;
}

.news-newsletter-area .acceptance .input-label {
	margin-left: auto;
	width: fit-content;
	color: #000;
}

.news-newsletter-area .form-input {
	margin-bottom: 9px;
}

.news-newsletter-area .input-label a {
	color: #000;
}

.news-newsletter-area .arrow-button {
	min-width: unset;
	width: 54px;
	min-height: 48px;
	position: absolute;
	top: 0;
	right: 0;
}

.news-newsletter-area .arrow-button:after {
	right: 0;
	left: 0;
}

.news-newsletter-area input[type='email'] {
	padding-right: 54px;
}

.news-summary {
	display: none;
}

.back-news-page .news-summary {
	display: block;
	margin-top: 17px;
}

.entry-body {
	padding-top: 26px;
	color: #000;

	.entry-wrapper {
		position: relative;

		.news-desc {
			background-color: #fff;
			font-weight: 800;
			text-align: center;
			width: 140px;
			position: absolute;
			top: 0;
			transform: translateY(-50%);
			left: calc(50% - 70px);
		}
	}
}

.entry-body h1 {
	font-size: 32px;
	font-family: 'Play', 'Commissioner', sans-serif;
	line-height: 38px;
}

.entry-info {
	border-bottom: 1px solid #000;
	padding-top: 70px;
	padding-bottom: 34px;
	line-height: 20px;
	font-size: 16px;

	a {
		color: #d71e26;
	}

	p:has(img) {
		display: flex;
		flex-wrap: wrap;
		gap: 24px;
		justify-content: center;

		@media (max-width: 575px) {
			gap: 7px;
		}
	}

	p > img {
		width: 384px;
		height: 384px;

		@media (max-width: 575px) {
			max-width: 162px;
			max-height: 162px;
		}
	}
}

.entry-info p {
	margin: 0;
}

.related-news {
	padding: 90px 0 104px;
}

.related-news h2 {
	font-size: 24px;
	margin-bottom: 24px;
	color: #000;
	text-align: left;
}

.related-news .grid-col {
	grid-template-columns: repeat(3, minmax(245px, 1fr));
	gap: 25px;
	overflow: auto;
	white-space: nowrap;
	-ms-overflow-style: none;
	scrollbar-width: none;
	background-image: linear-gradient(to right, #f4f4f4, #f4f4f4), linear-gradient(to right, #f4f4f4, #f4f4f4),
		linear-gradient(to right, rgba(171, 171, 171, 0.25), rgba(255, 255, 255, 0)),
		linear-gradient(to left, rgba(171, 171, 171, 0.25), rgba(255, 255, 255, 0));
	background-position:
		left center,
		right center,
		left center,
		right center;
	background-repeat: no-repeat;
	background-size:
		20px 100%,
		20px 100%,
		10px 100%,
		10px 100%;
	background-attachment: local, local, scroll, scroll;
}

.related-news .grid-col::-webkit-scrollbar {
	display: none;
}

.news-entry-wrapper {
	position: relative;

	hr {
		border-top: 1px solid #000;
		margin-top: 64px;
	}

	@media (max-width: 575px) {
		.left-news-side {
			padding-top: 35px;

			.news-img {
				img {
					height: 213px;
				}
			}

			.entry-body {
				padding-top: 38px;

				h1 {
					margin: 0 22px 48px 22px;
				}

				.entry-info {
					padding: 50px 0 42px 0;
					margin: 0 22px;
				}
			}
		}
	}
}

.share-buttons {
	position: sticky;
	top: 75px;
	left: 0;
	margin-top: 60px;
	margin-left: -130px;
	display: grid;
	gap: 11px;
	height: 100%;
}

.share-buttons button svg path {
	transition: 0.3s ease-out;
}

.share-buttons button:hover svg path {
	fill: #000;
}

.back-news-page #personalised-products {
	background-color: #fff;
	padding-top: 49px;
}

.back-news-page .content-wrapper {
	.promoted-article {
		margin: 0 auto;
		padding-bottom: 80px;

		@media (max-width: 641px) {
			padding-bottom: 35px;
			font-size: 14px;

			.category-label,
			.news-details {
				margin: 0 22px;
			}
		}
	}
}

/*end news page*/
/*store finder google maps*/

#google-map {
	height: 750px;
	width: 100%;
	position: relative;

	.gm-fullscreen-control {
		display: none;
	}
}

#google-map.active {
	height: 380px;
}

.store-box {
	padding: 17px 27px 47px 27px;

	@media (max-width: 480px) {
		margin-top: 39px;
	}

	@media (max-width: 640px) {
		padding: 17px 28px 46px 36px;
		border-top: 1px solid #fff;
	}

	input {
		background-color: #fff;
		padding: 20px 40px 12px 14px;
		overflow: hidden;
	}

	label {
		overflow: hidden;
		padding-right: 30px;
	}
}

.store-box .form-input:after {
	content: '';
	position: absolute;
	top: 13px;
	right: 15px;
	background: url(../images/search-icon.svg) no-repeat center;
	width: 22px;
	height: 22px;
}

#google-map h1,
.back-store-page h1 {
	margin-bottom: 18px;
	font-size: 20px;
	line-height: 20px;
	text-align: center;
	font-family: 'Play', 'Commissioner', sans-serif;
}

#google-map .pin {
	position: relative;
	width: 0;
	height: 0;
	text-align: center;
	cursor: pointer;
}

#google-map .pin img {
	position: absolute;
	bottom: 0;
	right: -23px;
}

#google-map .pin-modal {
	display: none;
}

#google-map .pin-modal.active {
	display: block;
}

#google-map .search-overlay {
	position: absolute;
	top: 41px;
	left: 120px;
	width: 384px;
	background: #000;
	box-shadow: 0px 10px 30px #0000001a;
	color: #fff;

	.form-input {
		margin-bottom: 0;
	}
}

#google-map .closest-shop {
	margin-bottom: 26px;
}

#google-map .info-maps {
	gap: 11px;
}

#google-map .info-maps .flex-col {
	gap: 9px;
	align-items: center;
}

#google-map .store-name {
	font-weight: 700;
	margin-top: 22px;
	margin-bottom: 20px;
	justify-content: space-between;
}

#google-map .buttons {
	display: flex;
	justify-content: space-between;
	border-top: 1px solid rgba(255, 255, 255, 0.5);
	margin: 0 -27px -47px -27px;
}

#google-map button {
	color: #fff;
	font-size: 16px;
	font-family: 'Commissioner', sans-serif;
	border-radius: 0;

	@media (max-width: 640px) {
		font-size: 14px;
	}
}

#google-map .buttons button {
	width: 50%;
	justify-content: center;
	align-items: center;
	gap: 10px;
	height: 54px;
	font-family: 'Play', 'Commissioner', sans-serif;
}

#google-map .store-full-info h1 {
	margin: 37px 0 17px;
	text-align: left;
}

#google-map .buttons button:first-child {
	border-right: 1px solid rgba(255, 255, 255, 0.5);
}

#google-map .buttons button:last-child {
	font-size: 16px;
	padding-right: 20px;
}

#google-map .store-full-info {
	display: none;
}

#google-map .store-full-info button {
	position: relative;
	padding-left: 20px;
}

#google-map .store-full-info button:before {
	content: '';
	position: absolute;
	background: url(../images/arrow-white.svg) no-repeat right;
	top: 0;
	bottom: 0;
	margin: auto;
	width: 18px;
	height: 12px;
	left: -6px;
	transform: rotate(180deg);
	transition: 0.3s ease-out;
}

#google-map .more-info .title,
#google-map .more-info .closest-shop,
#google-map .more-info .buttons,
#google-map .more-info form,
#google-map .more-info .store-box > h1 {
	display: none;
}

#google-map .more-info .store-full-info {
	display: block;
}

.back-store-page {
	padding: 70px 0;
	background-color: #f1f2f3;
}

.active-store {
	gap: 10px 24px;
	align-items: center;
}

.box-active-store {
	background-color: #fff;
	box-shadow: 0px 10px 30px #0000001a;
	border-radius: 5px;
	width: 100%;
}

.store-hours {
	max-width: 32%;
	padding: 60px 0px;

	h1 {
		font-family: 'Play', 'Commissioner', sans-serif;
	}
}

.back-store-page h1 {
	text-align: left;
	margin-bottom: 26px;
}

.store-hours > div {
	max-width: 194px;
	text-align: left;
	margin: auto;
}

.store-gallery {
	max-width: 792px;
	padding: 33px 8.5% 20px;
	position: relative;
	min-height: 414px;
}

.store-hours .hours {
	gap: 15px;
}

.store-hours .hours li {
	justify-content: space-between;
}

.stores-slider {
	.swiper-wrapper {
		align-items: center;
	}

	img {
		margin: auto;
		object-position: center;
		max-height: 380px;
	}
}

/*end store finder google maps*/
/*cart widget*/

@-webkit-keyframes widget-appear {
	0% {
		opacity: 0;
		transform: translateX(100%);
	}

	100% {
		opacity: 1;
		transform: translateX(0);
	}
}

@keyframes widget-appear {
	0% {
		opacity: 0;
		transform: translateX(100%);
	}

	100% {
		opacity: 1;
		transform: translateX(0);
	}
}

#cart-widget {
	position: fixed;
	top: 4vw;
	left: 50%;
	transform: translateX(-50%);
	width: 100%;
	max-width: 995px;
	z-index: 2004;
	box-shadow: 0px 10px 30px rgb(0 0 0 / 26%);
	background: #f1f2f3;
	max-height: 100%;
	overflow-y: auto;

	.cart-items::-webkit-scrollbar {
		display: none;
	}

	.cart-widget {
		width: 100%;
		height: 100%;
		overflow: auto;
		position: relative;

		.close-widget {
			position: absolute;
			top: 22px;
			right: 24px;
			cursor: pointer;

			img {
				width: 18px;
			}
		}

		.product-added {
			padding: 30px 205px 15px;
			background: white;

			.head-account h2 {
				text-align: center;
				font-size: 22px;
				line-height: 28px;
				font-weight: 700;
				font-family: 'Play', 'Commissioner', sans-serif;
				margin-bottom: 50px;
			}

			.cart-items {
				margin: 0;
			}

			.cart-item {
				gap: 0;
				padding: 0;
				border: none;

				.cart-item-info {
					padding-left: 23px;
					max-width: 210px;

					a {
						text-decoration: none;
						color: #262828;
					}
				}
			}

			.quantity {
				display: none;
			}

			.edit-mobile {
				@media (min-width: 481px) {
					display: none;
				}
			}

			.edit {
				justify-content: end;
				margin-left: auto;

				@media (max-width: 480px) {
					display: none;
				}

				.remove-cart-item {
					display: none;
				}

				.price {
					font-size: var(--microsize);
					font-size: 14px;
					font-family: 'Play', 'Commissioner', sans-serif;
					font-weight: 400;
					color: #d71e26;
					text-align: left;

					span {
						margin-left: 7px;
						color: #6a727e;
						text-decoration: line-through;
					}

					.single-price {
						color: #000;
						font-weight: 700;
						text-decoration: none;
					}
				}
			}

			.buttons-cart {
				margin: 40px auto 20px auto;
				gap: 24px;
				justify-content: space-between;

				button {
					border-radius: 0;
					font-family: 'Play', 'Commissioner', sans-serif;
					font-weight: 700;
					padding: 15px 30px;
					width: 282px;

					&::after {
						display: none;
					}
				}

				button {
					border: 1px solid #000;
					transition: 0.3s ease-out;
					max-width: 90%;
					font-size: 16px;

					&:hover {
						color: #fff;
						background-color: #000;
					}
				}

				button:last-child:hover {
					color: #000;
					background-color: #fff;
					border: 1px solid #000;
				}
			}
		}

		.related-products {
			padding-bottom: 30px;

			.related-title {
				line-height: 1.2;
				padding: 30px 0;
				text-align: center;
			}

			.related-container {
				display: flex;
				margin: 0 auto;
				max-width: 832px;
				padding: 0 22px 20px;
				gap: 24px;

				.product-box {
					max-width: 185px;
					width: 24%;
					background: white;
					padding: 16px;

					h4 {
						color: #6a727e;
					}

					.add-wishlist {
						padding: 0;
					}

					.prod-img {
						min-height: unset;

						a {
							display: block;
							// max-height: 150px;
						}
					}

					.prod-details {
						padding: 0;

						.prod-colors {
							display: none;
						}
					}

					.quick-buy {
						.prod-dim {
							height: 20px;
						}
					}
				}
			}
		}
	}

	@media (max-width: 1080px) {
		max-width: 85%;

		.cart-widget {
			.product-added {
				padding: 64px 100px 28px 100px;

				.head-account {
					border: none;
					padding: 0;
				}

				.cart-item {
					padding: 0 13px;
				}

				.buttons-cart {
					flex-wrap: wrap;
					gap: 12px;
					justify-content: center;
				}
			}
		}
	}

	@media (max-width: 767px) {
		top: 0;
		max-width: 100%;

		.cart-widget {
			.close-widget {
				top: 30px;
				right: 25px;
			}

			.product-added {
				padding: 64px 22px 28px 22px;

				.buttons-cart {
					button {
						max-width: 100%;
						width: 100%;
					}
				}
			}

			.related-products .related-container .product-box {
				.prod-img {
					a {
						height: 130px;
					}
				}
			}
		}
	}

	@media (max-width: 640px) {
		.cart-widget .related-products .related-container {
			padding: 0 24px 52px;
			overflow: scroll;
			gap: 10px;

			.product-box {
				width: 100%;
				padding: 14px;

				.prod-img {
					min-height: unset;
					height: 130px;
					width: 130px;
				}
			}
		}
	}
}

/*end cart widget*/
/*menu extras*/

#menu-section nav::-webkit-scrollbar {
	width: 3px;
	/* width of the entire scrollbar */
	height: 100%;
}

#menu-section nav::-webkit-scrollbar-track {
	background: #fff;
	/* color of the tracking area */
	border-radius: 23px;
}

#menu-section nav::-webkit-scrollbar-thumb {
	background-color: #ababab;
	/* color of the scroll thumb */
	border-radius: 23px;
	/* roundness of the scroll thumb */
	border: 3px solid #ababab;
	/* creates padding around scroll thumb */
}

/*end menu extras*/
/*search extras*/

.exit-search {
	position: absolute;
	right: 48px;
	top: 14px;

	@media (max-width: 767px) {
		top: 0;
		left: 0;
		width: 20px;
	}
}

.search-results .searchbar {
	position: relative;
	max-width: 790px;
	margin: 0 auto 60px;
}

.search-results input.searchbar-input {
	width: 100%;
	padding: 1.5% 1.5% 9px 43px;
	background: none;
	border-bottom: 1px solid black !important;
	border-radius: 0;
	height: unset;
}

.search-results input.searchbar-input:focus-visible,
.search-results input.searchbar-input:focus {
	border: none;
}

.search-results .searchbar::before {
	content: '';
	position: absolute;
	background: url('../images/search-icon.svg') no-repeat center;
	left: 0;
	bottom: 13px;
	width: 22px;
	height: 22px;
}

.search-results .cat-list ul {
	margin-bottom: 10px;
}

/*end search extras*/
/*footer extras*/
.footer-clmns {
	gap: 24px;
	justify-content: space-between;
	width: 65%;
	padding: 0 10px;
}

.payment-methods div:last-child {
	padding: 0 20px 0 6%;
	white-space: nowrap;
}

input.add-email::-webkit-input-placeholder {
	opacity: 1;
}

input.add-email::-ms-input-placeholder {
	opacity: 1;
}

input.add-email::placeholder {
	opacity: 1;
}

/*end footer extras*/

.view-black-button {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 48px;
	text-align: center;
	width: fit-content;
	outline: none;
	padding: 0 20px;
	background: black;
	color: #ffffff;
	transition: box-shadow 0.2s;
	font-size: 16px;
	font-family: Play;
	font-weight: 700;

	&:hover {
		box-shadow: 0 5px 20px rgba(0, 0, 0, 0.16);
	}

	&:after {
		right: 20px;
	}

	&:hover:after {
		width: 18px;
		right: 12px;
	}

	&.brands-banner-button {
		margin: 50px auto 70px;
		position: relative;
		padding: 0 30px;

		&::after {
			content: '';
			position: absolute;
			right: 15px;
			top: 0;
			width: 10px;
			height: 100%;
			background-image: url(../images/arrow-white-mobile.svg);
			background-repeat: no-repeat;
			background-position: center;
		}

		@media (max-width: 767px) {
			margin: 20px auto 50px;
		}

		@media (max-width: 480px) {
			width: calc(100% - 46px);
			justify-content: center;
		}
	}

	@media (max-width: 480px) {
		font-size: 14px;
		line-height: 16px;
		min-width: 160px;
		height: 45px;
	}
}

.view-black-text {
	color: black;
	position: relative;
	font-family: Play;
	font-weight: bold;
	font-size: var(--regsize);
	line-height: 1;
	padding: 0 20px 0 5px;
	background: transparent;
	display: block;

	&:after {
		content: '';
		position: absolute;
		right: 0;
		top: 0;
		height: 100%;
		width: 12px;
		height: 100%;
		background: url('../images/black-text-arrow.svg') no-repeat center;
	}
}

/*Brands Page*/
#brands-list {
	#brands-search {
		.title {
			display: none;
		}

		.alphabet-links {
			display: flex;
			justify-content: space-between;

			a {
				color: #030303;
				font-size: 24px;
				line-height: 27px;
				font-weight: bold;

				&.inactive {
					color: #b5bac3;
					pointer-events: none;
				}
			}
		}

		input::placeholder {
			color: black;
		}
	}

	#brands-search-mobile {
		display: none;
	}

	@media (max-width: 768px) {
		.breadcrumbs-container {
			top: 0;
		}

		#brands-search {
			top: 60px;

			.content-wrapper {
				padding: 25px 22px;
			}

			.title {
				display: block;
				text-align: center;
				font-size: 24px;
				font-weight: 700;
				line-height: 29px;
				font-family: 'Play', 'Commissioner', sans-serif;
				margin-bottom: 18px;
			}
		}

		.content-wrapper {
			padding-top: 30px;

			.top-brands {
				padding-bottom: 10px;
				margin-bottom: 46px;
			}

			.brands-list .brands-letter-item {
				padding-bottom: 46px;
				margin-bottom: 46px;
			}
		}
	}

	@media (max-width: 480px) {
		.breadcrumbs-container {
			display: none;
		}

		#brands-search {
			top: 56px;
			position: initial;

			.alphabet-links a {
				font-size: 14px;
			}
		}

		.top-brands .top-brands-items a {
			width: 76px;
		}

		#brands-search-mobile {
			display: block;
			z-index: 2;
			position: sticky;
			bottom: 0;
			background-color: #f1f2f3;

			.content-wrapper {
				padding: 11px 22px;

				.search-bar {
					margin: 0;
				}
			}
		}
	}
}

/*end Brands Page*/
// Overrides anatolie
.page {
	padding-bottom: 76px;
}

.product-box {
	width: 282px;
	height: 460px;
	padding: 25px 22px 17px 22px;

	.prod-img {
		margin: auto;
		// height: 236px;
	}

	.product-box-content {
		position: relative;
		top: -20px;
		width: 100%;
		overflow-y: hidden;
		transition: transform 0.25s ease;

		.prod-price {
			.final-price {
				font-weight: 700;
				margin: 0;
				text-decoration: none;
				color: #000;
			}

			.price-af {
				color: #d71e26;
				text-decoration: none;
			}

			.price-bd {
				margin-left: 7px;
				color: #6a727e;
				text-decoration: line-through;
			}
		}

		.prod-colors {
			margin-bottom: 15px;
			height: 18px;

			.show-more-colors {
				width: 18px;
				height: 18px;
				border: 1px solid #757f8f;
				color: #757f8f;
				border-radius: 100%;
				display: grid;
				place-items: center;

				a {
					color: #757f8f;
					font-size: 21px;
					line-height: 12px;
					height: 15px;
				}
			}
		}

		.quick-buy {
			transform: translateY(100%);
			transition: transform 0.25s ease;
			background-color: transparent;
		}
	}

	&:hover {
		.product-box-content {
			transform: translateY(-20px);
			transition: transform 0.25s ease;
		}

		.quick-buy {
			display: block;
			transform: translateY(0);
			transition: transform 0.25s ease;
		}
	}

	@media (max-width: 810px) {
		padding: 16px 12px 0 12px;
		width: 100%;

		.prod-img {
			height: unset;
		}
	}

	@media (max-width: 480px) {
		min-height: unset;
		height: unset;

		.product-box-content {
			overflow-y: unset;

			.prod-colors,
			.quick-buy {
				display: none;
			}
		}

		&:hover {
			.product-box-content {
				transform: none;
			}
		}
	}
}

.combine-products {
	.swiper {
		.swiper-slide {
			.product-box {
				width: 334px;
				height: unset;
				min-height: 509px;

				.product-box-content {
					top: 0px;
				}
			}

			@media (max-width: 1100px) {
				.product-box {
					width: 300px;
				}
			}

			@media (max-width: 980px) {
				.product-box {
					width: 268px;
					min-height: 454px;
				}
			}

			@media (max-width: 870px) {
				.product-box {
					min-height: 512px;
					width: 100%;
					max-width: 334px;
					padding: 16px 12px 12px 12px;
				}
			}

			@media (max-width: 640px) {
				.product-box {
					min-height: 485px;
				}
			}

			@media (max-width: 480px) {
				.product-box {
					height: 100%;
					min-height: 286px;
					max-height: 316px;
				}

				.swiper-button-prev,
				.swiper-button-next {
					display: none;
				}
			}
		}

		@media (max-width: 480px) {
			height: 300px;

			.swiper-pagination {
				display: block;
				bottom: 0;
			}

			.swiper-pagination-bullet {
				border: 1px solid #000;
				background-color: #000;
				width: 10px;
				height: 10px;
			}

			.swiper-slide .product-box {
				min-height: unset;

				.prod-img {
					display: grid;
					place-items: center;
				}

				.prod-details h3 {
					height: 16px;
				}
			}

			.swiper-button-prev,
			.swiper-button-next {
				display: none;
			}
		}
	}
}

.after-search-section .product-box {
	height: unset;

	.prod-img {
		min-height: unset;
	}

	.product-box-content {
		top: 0;
	}

	&:hover .product-box-content {
		transform: none;
	}
}

#cart-widget .cart-widget .related-products .related-container {
	.product-box {
		width: 334px;
		max-height: 310px;
		padding: 16px;
		padding-bottom: 0;

		.prod-img {
			max-height: 150px;
			display: block;
		}

		.product-box-content {
			top: -10px;

			.quick-buy {
				transform: none;

				a,
				a.disabled {
					pointer-events: none;
					cursor: default;
					color: #6a727e;
				}
			}
		}

		.prod-details {
			.brand-logo {
				line-height: 18px;
				height: unset;
			}

			.prod-price {
				margin-bottom: 2px;
			}
		}

		&:hover {
			.product-box-content {
				transform: none;
			}
		}
	}

	@media (max-width: 1100px) {
		.product-box {
			width: 300px;
		}
	}

	@media (max-width: 980px) {
		.product-box {
			width: 268px;
		}
	}

	@media (max-width: 870px) {
		.product-box {
			height: 100%;
			width: 100%;
			max-width: 334px;
			padding: 16px 12px 12px 12px;
		}
	}
}

.stores-banners {
	padding: 0 22px;

	.StoreSwiper {
		width: 100%;
	}

	.swiper-button-prev,
	.swiper-button-next {
		display: none;
	}

	@media (max-width: 1024px) {
		.combine-title {
			font-size: 28px;
		}

		&#collection-triple-banner .banner-item {
			.banner-image {
				margin-bottom: 10px;
			}

			.title-container h3 {
				font-size: 28px;
				margin: 0;
			}
		}

		.swiper-button-prev,
		.swiper-button-next {
			display: flex;
		}

		.swiper-button-prev {
			left: 0;
			top: 45%;
		}

		.swiper-button-next {
			right: 0;
			top: 45%;
		}
	}

	@media (max-width: 640px) {
		&#collection-triple-banner .banner-item {
			.banner-image img {
				width: calc(100% - 30px);
				margin: auto;
			}
		}
	}
}

/*mobile media*/
@media (min-width: 901px) {
	.mobile901 {
		display: none !important;
	}
}

@media (min-width: 641px) {
	.mobile641 {
		display: none;
	}
}

@media (max-width: 1440px) {
	.right-side {
		margin-right: 0;
	}

	.banner-cat > div:after {
		right: 15px;
	}
}

@media (max-width: 1200px) {
	.further-msg .line {
		margin-left: -50px;
		margin-right: -50px;
	}
}

@media (max-width: 1024px) {
	.account-page .content-wrapper {
		.center2 {
			min-width: auto;
		}
	}

	.address-list-headers > ul,
	.address-item > ul {
		padding-left: 3%;
	}

	.masonry-pictures {
		gap: 13px;
	}

	.wishlist-items {
		gap: 30px 15px;
	}

	.store-gallery {
		padding: 32px 5% 20px;
	}

	.exit-search {
		max-width: unset;
	}

	.cat-list {
		.products-section {
			.grid-part .product-box .prod-img {
				min-height: 210px;
			}
		}
	}
}

@media (max-width: 900px) {
	.mobile900 {
		display: none !important;
	}

	.account-page .content-wrapper > .grid-col {
		grid-template-columns: unset;
		margin: 0;
	}

	.navigation-page h1 {
		padding: 25px 22px !important;
		font-size: 20px;
		line-height: 24px;
		text-align: left;
		font-weight: 700;
	}

	.log-out-sec {
		padding: 37px 22px 50px;
	}

	.sidebar a {
		font-weight: 700;
		padding: 20px 5px 20px 22px;
		border-bottom: 1px solid #dddddd;
		margin-bottom: 0;
	}

	.head-account {
		position: relative;
	}

	.head-account > button {
		padding: 0;
		font-size: 12px;
		font-family: 'Commissioner', sans-serif;
		display: flex;
		align-items: center;
		margin-bottom: 54px;
		margin-right: 25px;
		position: absolute;
		top: -20px;

		svg {
			transform: rotate(180deg);
			height: 12px;
			margin-right: 8px;
		}
	}

	.back-page,
	#checkout.back-page {
		background: #f4f4f4;
	}

	.back-page .content-wrapper {
		padding: 0;
	}

	.left-cart > div {
		max-width: unset;
	}

	.left-cart h1 {
		margin: 0;
		text-align: center;
	}

	.cart-box h1 {
		text-align: center;
	}

	.left-cart .head-account {
		margin-bottom: 35px;
	}

	header .content-wrapper.mobile901 {
		height: 80px;
	}

	.checkout-steps {
		margin-bottom: 0;
	}

	.step3-page form {
		margin: auto;
	}

	.left-cart #checkout-step-3-wrapper h1 {
		text-align: left;
	}

	.step-final-header .shadow-header {
		background: #f4f4f4;
	}

	.step-final-header header .checkout-header {
		background-color: #fff;
	}

	.order-summary-content .notice {
		flex-direction: column;
		align-items: unset;
	}

	.order-summary-content button {
		width: auto;
		margin-left: auto;
	}

	.wishlist-page .page-title {
		flex-direction: column;
		position: relative;
		padding: 40px 0;
	}

	.page-header-wishlist {
		background-color: #fff;
	}

	.wishlist-page > .content-wrapper.body {
		padding-top: 20px;
		padding-bottom: 82px;
	}

	.wishlist-page .page-title > div {
		margin: 24px auto 0;
		padding: 0;
	}

	.edit-wishlist button {
		margin: auto;
	}

	.cat-list {
		.product-filters {
			padding-right: 20px;
		}

		.grid-part .product-list {
			grid-template-columns: repeat(2, minmax(160px, 282px));

			.product-filters {
				padding-right: 20px;
			}
		}
	}

	.one-wishlist h1 {
		margin: auto;
		padding-left: 16px;
	}

	.wishlist-page.one-wishlist .page-title {
		flex-direction: unset;
	}

	.wishlist-page.one-wishlist .page-title > div {
		margin: 0;
		width: unset;
	}

	.edit-wishlist img {
		margin: 0;
	}

	.box-empty-list {
		margin-bottom: 0;
	}

	#footer-top .footer-level-1 {
		flex-direction: column;
		max-width: 600px;
		gap: 58px 0;
	}

	#footer-top .footer-level-1 .newsletter-area,
	.footer-clmns {
		width: 100%;
	}

	#footer-top .footer-level-1 .newsletter-area * {
		max-width: unset;
	}

	#footer-top .footer-level-1 .newsletter-area .newsletter-box {
		margin-bottom: 25px;
		text-align: center;

		.gender-section {
			justify-content: center;
			margin-bottom: 30px;
		}
	}

	#footer-top .footer-level-1 .newsletter-area .social-links {
		justify-content: flex-end;
	}

	.footer-bottom-1 .content-wrapper {
		flex-direction: column;
		gap: 30px 0;
		align-items: center;
	}

	.footer-bottom-1 .payment-methods {
		flex-wrap: wrap;
		margin-right: 0;
	}

	#footer-top {
		padding: 33px 0 40px;
	}

	.footer-bottom-2 .content-wrapper {
		justify-content: center;
		line-height: 19px;
		flex-wrap: wrap;
	}

	.payment-methods div:last-child {
		display: flex;
		padding: 0 22px;
		white-space: normal;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
	}

	#footer-top-boxes {
		flex-direction: column;
	}

	#footer-top-boxes .footer-box .box-title {
		font-size: 16px;
		line-height: 18px;
	}

	#footer-top-boxes .footer-box {
		width: 100%;
	}

	.news-sides {
		flex-direction: column;
	}

	.left-news-side {
		margin: auto;
	}

	.right-news-side {
		margin: auto;
	}

	.promoted-article h1,
	.entry-body h1 {
		font-size: 24px;
	}

	.share-buttons {
		display: none;
	}

	.entry-info {
		margin-top: 8px;
		padding-top: 21px;
	}

	.banner-cat > div {
		flex-direction: column;
	}

	.right-side img {
		max-width: 160px;
	}

	.banner-cat > div:after {
		top: unset;
		bottom: -16px;
		width: 71px;
		height: 193px;
	}

	#contact .cms-content {
		flex-direction: column;
		padding-bottom: 80px;
	}

	#contact .form-area {
		margin: -43px auto 0;
	}

	#contact .info-area {
		max-width: 600px;
		margin: auto;
		width: 100%;
	}

	#contact .info-area .content-wrapper {
		padding: 0;
		display: flex;
		justify-content: space-between;
		gap: 60px 20px;
	}

	#contact .info-area .content-wrapper h2 {
		margin-top: 0;
	}

	.homepage-blogs .title.flex-col h1 {
		font-size: 24px;
	}

	.homepage-blogs .title {
		margin-bottom: 30px;
	}

	.active-store {
		flex-direction: column;
		position: relative;
	}

	.store-hours {
		max-width: 100%;
		padding: 38px 20px 51px;
	}

	.store-hours > div {
		max-width: 239px;
	}

	.store-gallery {
		display: none;
	}

	.login-alter > div {
		flex-direction: column;
		align-items: center;
		max-width: 560px;
		margin: auto;

		button {
			width: 100%;
		}
	}
}

@media (max-width: 767px) {
	.cat-list {
		.grid-part .product-list {
			gap: 24px 10px;

			.product-box .prod-img {
				min-height: 216px;
			}
		}
	}
}

@media (max-width: 640px) {
	.desktop641 {
		display: none;
	}

	button {
		font-size: 14px;
		line-height: 16px;
	}

	.arrow-button:after {
		width: 11px;
		height: 11px;
	}

	.address-item {
		&.mobile641 {
			display: flex;
			justify-content: space-between;
		}

		ul {
			grid-template-columns: unset;
			height: unset;
			gap: 3px;
			padding: 0;

			li {
				font-size: 12px;
			}
		}
	}

	.ordhist-item .prods > ul li:first-child {
		margin-left: 0;
	}

	.address-list li.controls {
		justify-self: unset;
		grid-template-columns: 1fr 1fr;
		gap: 10px;
		margin-top: 28px;
	}

	.add-address button {
		padding: 12px 16px 12px 22px;
		font-size: 14px;
		width: 100%;
	}

	.address-list li.controls button span {
		font-size: 14px;
		line-height: 16px;
	}

	.account-page .main-content {
		padding: 40px 30px;

		.form-content {
			padding: 0;
		}
	}

	.address-list {
		margin-top: 24px;
		margin-bottom: 30px;

		.address-item .controls {
			flex-direction: column;
			justify-content: space-between;
		}
	}

	.address-item {
		margin-bottom: 15px;
		padding: 20px 18px 16px 20px;
	}

	.my-account-form {
		max-width: unset;
	}

	h1.acc-title {
		margin-bottom: 0;
	}

	.account-page .main-content:nth-child(2) h1.acc-title {
		border: none;
		margin-bottom: 0;
		padding-bottom: 23px;
	}

	.profile-page .main-content:first-child .head-account {
		margin-bottom: 24px;
	}

	.form-content {
		padding: 0 22px 48px;
	}

	.profile-page .center2 {
		box-shadow: 0px 10px 30px #00000029;
	}

	.profile-page .main-content {
		box-shadow: none;
	}

	.right-sit {
		margin-top: 22px;
		padding: 13.5px 22px;
	}

	.form-input {
		margin-bottom: 12px;
	}

	.ordhist-item {
		margin-bottom: 15px;
	}

	.ordhist-item > ul {
		grid-template-columns: 1fr 1fr;
		min-height: 170px;
	}

	.ordhist-item .status {
		grid-column: span 1;
		margin-top: 10px;
		font-size: 14px;
		line-height: 14px;
		order: 1;
	}

	.ordhist-item .total {
		order: 2;
		text-align: right;
	}

	.ordhist-item .prods {
		grid-column: span 2;
		margin-top: 22px;
		order: 3;
	}

	.address-item.ordhist-item > ul li:nth-child(2) {
		justify-self: end;
	}

	.ordhist-item .prods > ul {
		justify-content: start;
	}

	#follow-order form {
		padding: 0 22px 150px;
	}

	#follow-order p {
		margin: 18px auto;
		padding: 0 22px;
	}

	#follow-order .further-msg p {
		margin: 58px auto 24px;
	}

	.further-msg a {
		font-size: 14px;
		padding: 11.5px 18px;
		min-width: unset;
		max-width: 290px;
		width: 100%;
		gap: 10px;
	}

	.order-status-item li.flex-col {
		flex-direction: column;
	}

	.order-status-item {
		align-items: start;
		gap: 21px;
	}

	.order-status-item:not(:first-child) li.img:after {
		border-top: 60px solid #ababab;
	}

	.order-status-item li.flex-col span {
		margin: 0 0 0 24px;
		font-size: 0px;
	}

	.order-status-item li.flex-col > div {
		line-height: 16px;
	}

	.address-add-page .inputs,
	.inputs {
		grid-template-columns: 1fr;
	}

	.address-add-page .form-content {
		padding-top: 24px;
	}

	.left-cart .head-account {
		margin-bottom: 19px;
		border: none;
	}

	.cart-box button,
	form button {
		padding: 13.5px 22px;
	}

	.arrow-button {
		min-height: 45px;
	}

	.left-cart #checkout-step-3-wrapper .payment-part h1 {
		margin-bottom: 19px;
	}

	#courier-fields > div {
		gap: 10px;
	}

	#courier-fields label {
		max-width: 160px;
		height: 90px;
		padding: 14px 0;
		font-size: 12px;
	}

	.payment-part label.radio-label {
		padding: 0 16px 0 58px;
	}

	.payment-part img {
		margin-right: 28px;
	}

	.payment-part input[type='radio'] + .radio-label:before {
		left: 17px;
	}

	.payment-part input[type='radio']:checked + .radio-label:after {
		left: 22px;
	}

	.shipping-synopsis {
		grid-template-columns: 1fr;
	}

	.order-completed-page > div:first-child {
		padding-bottom: 32px;
	}

	.wishlist-items {
		grid-template-columns: minmax(150px, 440px);
		justify-content: center;
	}

	.prod-details {
		padding-top: 10px;
	}

	.prod-details > .flex-col {
		gap: 8px;
		flex-direction: column;
		text-align: left;
	}

	.prod-price {
		font-size: 14px;
	}

	.prod-details > .flex-col > div {
		max-width: unset;
	}

	.prod-details h3 {
		margin-bottom: 10px;
	}

	.wishlist-page.one-wishlist > .content-wrapper.body {
		padding-bottom: 42px;
	}

	#personalised-products h3,
	.related-news h2 {
		font-size: 20px;
	}

	.guest-register-content main {
		max-height: unset;
	}

	.register-wrapper > .grid-col {
		grid-template-columns: 1fr;
	}

	.register-wrapper {
		max-width: 384px;
	}

	.login-modal main {
		min-height: unset !important;
		max-height: unset !important;
		padding: 69px 18px 100px;
		bottom: unset;
		height: 100%;
	}

	.register-wrapper > .grid-col button {
		min-width: 261px;
		width: auto;
		margin: 20px auto 0;
	}

	.first-col-reg form {
		text-align: center;
	}

	.modal-content {
		padding: 0 !important;
		height: 100%;
		max-width: unset;
		border-radius: unset;
	}

	.modal-content h2 {
		padding: 14px 22px;
		box-shadow: 0 10px 30px #00000029;
		justify-content: unset;
		font-size: 18px;
	}

	.wish-info {
		padding: 16px 22px 32px;
	}

	.add-new-wish p {
		margin-bottom: 28px;
	}

	.wish-info form {
		max-width: 500px;
		margin: auto;
	}

	.wish-btn {
		text-align: center;
		padding: 13.5px 22px;
	}

	button.arrow-button.submit.wish-btn:after {
		content: none;
	}

	.extra-del:before {
		right: -22px;
		left: -22px;
	}

	p.edit-wish-info {
		text-align: center;
		margin: 14px auto 35px;
	}

	.extra-del {
		margin-top: 53px;
		padding-top: 42px;
	}

	.extra-del button {
		margin: auto;
		width: 100%;
	}

	.extra-del p.edit-wish-info {
		margin: 0 auto 20px;
	}

	.wishlists-list {
		padding: 0;
	}

	.new-wishlist-addition p.mobile641 {
		margin-bottom: 46px;
	}

	.modal-content .add-address {
		margin-bottom: 0;
		padding: 15px 0;
	}

	.promoted-article h1,
	.entry-body h1 {
		font-size: 20px;
		margin-bottom: 0;
	}

	.news-details h3 {
		font-size: 16px;
		line-height: 22px;
	}

	.back-news-page .news-summary {
		font-size: 12px;
		margin-top: 5px;
	}

	.newslist {
		gap: 31px 10px;
		margin: 0 8px;
	}

	.promoted-article {
		margin-bottom: 50px;
	}

	.banner-cat {
		padding: 18px 0 40px;
	}

	.show-more-prods {
		margin-top: 40px;
	}

	.see-more {
		font-size: 14px;
		line-height: 15px;
	}

	.right-side {
		gap: 10px;
	}

	.combine-products .product-box {
		padding: 16px 12px 0 12px;
	}

	.product-filters div.flex-col {
		margin-bottom: 12px;
	}

	.product-filters h2 {
		font-size: 16px;
		line-height: 18px;
	}

	#google-map {
		padding-top: 150px;
	}

	#google-map.active {
		padding-top: 0;
		height: 750px;
		height: calc(900px + 220px);
		margin-bottom: 120px;
	}

	#google-map .search-overlay {
		top: 0;
		left: 0;
		width: 100%;
	}

	#google-map .store-full-info h1,
	#google-map h1,
	.back-store-page h1 {
		font-size: 20px;
		line-height: 20px;
	}

	#contact .form-area .form-container form .form-title {
		font-size: 18px;
	}

	.shadow-header .checkout-steps .checkout-step .step-description {
		width: 122px;
	}
}

@media (max-width: 810px) {
	.cat-list {
		.title-wrapper .cat-title-wrapper {
			flex-direction: column;
			gap: 42px;
			padding: 28px 0px 26px 0px;

			h1 {
				width: unset;
				padding: 0;

				div {
					margin-left: 0;
					margin-right: 22px;

					img {
						padding: 12px 34px;
					}
				}
			}

			.banner-categories {
				width: 100%;

				.cat-item {
					width: 160px;
					justify-content: center;

					.img-container {
						display: none;
					}
				}
			}
		}

		.products-section {
			margin-top: 60px;
			position: relative;

			&.single-grid {
				display: block;
			}

			.filters-mobile {
				position: absolute;
				top: -40px;
				left: 0;
				width: 100%;
				display: flex;
				justify-content: space-between;

				.icon {
					z-index: 100;
				}
			}

			.grid-part {
				.product-list {
					grid-template-columns: repeat(2, minmax(120px, 282px));
					justify-content: center;

					.product-box .prod-img {
						min-height: 255px;
					}

					&.single-grid {
						grid-template-columns: repeat(1, minmax(120px, 282px));

						.product-box .prod-img {
							min-height: 120px;
						}
					}
				}
			}
		}

		.product-filters-mobile {
			position: fixed;
			top: 0;
			left: 0;
			bottom: 0;
			z-index: 3003;
			background-color: #fff;
			width: 100%;
			// padding-bottom: 60px;
			overflow: auto;

			.header-filters {
				display: flex;
				align-items: center;
				border-bottom: 1px solid #b5bac3;
				padding: 20px;

				.filters-icon {
					width: 24px;
					height: 16px;
				}

				h2 {
					margin-left: 12px;
					font-size: 16px;
					line-height: 20px;
					font-family: 'Play', 'Commissioner', sans-serif;
				}

				.close-filters-icon {
					margin: auto;
					margin-right: 0;
				}
			}

			.filters-list {
				padding: 18px 22px;
				z-index: 2003;

				.filter-content {
					border: none;

					strong {
						padding: 18px 0;
					}

					.filter-items {
						margin: 10px 0 30px 0;
					}
				}
			}

			.footer-filters {
				position: sticky;
				bottom: 0;
				padding: 0 22px;
				display: flex;
				justify-content: space-between;
				gap: 12px;
				z-index: 3;

				.clear-all,
				.see-results {
					width: 100%;
					height: 48px;
					display: grid;
					place-items: center;
					font-weight: 700;
					text-transform: uppercase;
					text-align: center;
				}

				.clear-all {
					color: #000;
					border: 1px solid #000;
					background: #fff;
				}

				.see-results {
					color: #fff;
					background: #000;
				}
			}
		}
	}
}

@media (max-width: 575px) {
	.top-pad {
		padding-top: 26px;
		padding-bottom: 59px;
	}
}

@media (max-width: 480px) {
	.navigation-page h1,
	.cart-box h1,
	.left-cart h1,
	.order-completed-page h1,
	.wishlist-page h1,
	#contact .form-area .form-container form .form-title,
	#info-page > .content-wrapper .main-content .page-heading {
		font-weight: 700;
		font-size: 16px;
		line-height: 18px;
	}

	.right-sit {
		width: 100%;
	}

	.left-cart {
		padding: 0;
	}

	.right-cart {
		width: 100%;
	}

	.cart-item {
		padding: 0 22px 30px;
		grid-template-columns: 75px 1fr auto;
		border-bottom-color: #f4f4f4;
	}

	.cart-item > .flex-col {
		padding-left: 10%;
	}

	.cart-box h1 {
		margin-bottom: 16px;
	}

	.cart-box {
		padding: 21px 22px 30px;
	}

	.order-synopsis {
		margin-bottom: 20px;
		border: none;
	}

	.cart-item h4 {
		margin-bottom: 9px;
	}

	.shadow-header {
		// position: sticky;
		top: 0;
		z-index: 2;

		.checkout-logo {
			height: 56px;

			img {
				height: 32px;
			}
		}

		.checkout-steps {
			padding: 30px 0;
		}
	}

	#checkout .left-cart {
		padding-right: 22px;
		padding-left: 22px;

		.checkout-step-2 .shipping-part .radio label {
			.text {
				max-width: 100px;
			}
		}
	}

	textarea {
		padding: 22px 19px;
	}

	#checkout .submit {
		text-align: center;
		margin-top: 12px;
	}

	#checkout .left-cart h4 {
		margin: 26px 0 20px;
	}

	#order-synopsis-checkout .cart-box {
		// padding: 38px 24px;
	}

	#order-synopsis-checkout .line {
		margin-left: -24px; // padding left-right is 24px
		margin-right: -24px;
	}

	#order-synopsis-checkout .cart-items {
		gap: 26px;
	}

	.checkout-steps > div {
		font-size: 12px;
	}

	.step3-page .acceptance .input-label {
		margin-top: 28px;
		margin-bottom: 25px;
		margin-left: 0;
	}

	.order-completed-page h1 {
		text-align: center;
	}

	.masonry-pictures {
		height: 215px;
	}

	.masonry-pictures > div:first-child img {
		max-height: 215px;
	}

	.masonry-pictures .grid-col {
		gap: 11px;
	}

	.masonry-pictures .grid-col div img {
		max-height: 102px;
	}

	.masonry-pictures .grid-col .no-image {
		min-height: 102px;
	}

	.wishlist-page {
		.product-list {
			grid-template-columns: repeat(2, minmax(160px, 282px));
		}

		.name {
			margin-top: 16px;
		}
	}

	.wishlist-page .add-address button {
		min-width: 204px;
		margin: auto;
	}

	.prod-details h3 {
		font-size: 12px;
		line-height: 16px;
	}

	#personalised-products h3,
	.related-news h2 {
		font-size: 16px;
		line-height: 18px;
		margin-bottom: 24px;
	}

	#personalised-products {
		padding-top: 38px;
		padding-bottom: 20px;
	}

	.box-empty-list {
		padding: 34px 31px;
	}

	.box-empty-list p {
		margin-top: 12px;
	}

	.login-modal li button {
		min-width: 164px;
		margin-bottom: 25px;
		font-size: 14px;
	}

	.login-modal ul,
	.login-alter > div {
		gap: 10px;
	}

	#login-wrapper,
	.register-wrapper {
		max-width: 338px;
	}

	.simple-link {
		margin-top: 10px;
	}

	#login-form button.submit {
		margin-top: 42px;
	}

	.login-alter > div {
		max-width: 338px;
	}

	#account-info .grid-col.gender-sec {
		margin: 27px 0 33px;
	}

	.grid-col.gender-sec {
		gap: 27px 25px;
	}

	.accept-sec {
		gap: 12px;
		margin-bottom: 12px;
	}

	#account-info button {
		min-width: 164px;
	}

	.info-box-message {
		padding: 40px 30px;
	}

	.info-box-message strong {
		font-size: 16px;
		line-height: 20px;
	}

	.info-box-message > div {
		font-size: 14px;
	}

	.info-box-message img {
		margin-bottom: 25px;
	}

	.info-box-message button {
		width: 100%;
	}

	.space-arround {
		padding: 70px 0 100px;
	}

	#footer-top .footer-level-1 .footer-menu-clmn {
		gap: 0;
	}

	#footer-top .footer-level-1 .footer-menu-clmn a {
		font-size: 12px;
	}

	.footer-clmns {
		flex-wrap: wrap;
		justify-content: unset;
	}

	.footer-bottom-1 .partners-area {
		column-gap: 10px;
	}

	.partner-box:first-child img {
		max-width: 64px;
	}

	.partner-box:last-child img {
		max-width: 125px;
	}

	.footer-bottom-2 {
		padding: 25px 0 24px 0;
	}

	#footer-top .footer-level-1 .newsletter-area .newsletter-title {
		font-size: 30px;
	}

	#footer-top .footer-level-1 .newsletter-area .newsletter-text {
		font-size: 12px;
		line-height: 20px;
		margin: 24px 0;
	}

	#footer-top .footer-level-1 .newsletter-area input::placeholder,
	#footer-top .footer-level-1 .newsletter-area input {
		font-size: 12px;
	}

	.subscription .add-email {
		padding: 0 14px;
	}

	.subscription .submit-email {
		width: 48px;
		right: 10px;
	}

	.subscription .submit-email .before-submit {
		// font-size: 0;
		// background-image: url(../images/arrow-white-mobile.svg);
		// width: 9px;
		// background-repeat: no-repeat;
		// background-position: center;
		// right: 0;
		// margin: auto;
	}

	.footer-bottom-1 .content-wrapper {
		margin-bottom: 35px;
	}

	#footer-top .footer-level-1 .newsletter-area .social-links {
		justify-content: center;
	}

	#footer-top .footer-level-1 {
		gap: 36px 0;
	}

	.payment-methods div:last-child svg {
		max-width: 110px;
	}

	.payment-methods div:last-child {
		padding: 0;
	}

	#user-login {
		display: none;
	}

	header .content-wrapper {
		height: 56px;
	}

	.modal-content h2 {
		font-size: 16px;
	}

	.mini-wishlist {
		gap: 10px;
	}

	.info-wishlist .name {
		margin-bottom: 7px;
		font-size: 14px;
		line-height: 16px;
	}

	.promoted-article h1,
	.entry-body h1 {
		font-size: 22px;
		line-height: 26px;
	}

	.promoted-article .news-desc {
		font-size: 14px;
		line-height: 20px;
	}

	.news-details h3 {
		font-size: 14px;
		line-height: 20px;
	}

	.news-desc {
		font-size: 12px;
		line-height: 20px;
	}

	.news-desc span {
		display: block;
	}

	.back-news-page .news-summary {
		display: none;
	}

	.news-img {
		overflow: unset;
	}

	.news-box:hover img {
		transform: unset;
	}

	.category-label {
		bottom: -34px;
		left: 0;
		right: unset;
		font-size: 12px;
	}

	.promoted-article {
		margin-bottom: 27px;
	}

	.news-newsletter-area {
		padding-bottom: 53px;
	}

	.entry-body {
		padding-bottom: 0;
	}

	.related-news {
		padding: 44px 0 38px;
	}

	#google-map .buttons {
		margin: 0 -28px -46px -36px;
	}

	#google-map .store-full-info h1 {
		margin-bottom: 19px;
	}

	#google-map .store-full-info button:before {
		background: url('../images/arrow back.svg') no-repeat right;
		transform: rotate(0deg);
	}

	.back-store-page {
		padding: 33px 0 30px;
		padding: 0;

		.content-wrapper {
			padding: 0;

			.box-active-store {
				padding: 60px 0;
				border-radius: 0;
				position: absolute;
				top: -465px;
				left: 0;

				& > div {
					margin: 0 auto 0 22px;
				}
			}
		}
	}

	.store-hours .hours {
		gap: 15px;
	}

	.numb {
		font-size: 14px;
	}

	#google-map.active {
		div.gmnoprint {
			display: none;
		}
	}

	.bread-none {
		// display: none;
	}

	#contact .info-area .content-wrapper {
		flex-direction: column;
	}

	#contact .form-area {
		margin: -59px auto 0;
	}

	#contact .form-area .form-container form .form-row {
		flex-direction: column;
		align-items: flex-start;
		margin: 12px 0 13px;
	}

	#contact .form-area .form-container form label .attach-top {
		margin-top: 20px;
	}

	#contact textarea {
		padding: 11px 15px;
		min-height: 73px;
	}

	#contact .form-area .form-container form .black-arrow-button {
		text-align: center;
		width: 100%;
		padding-left: 15px;
	}

	#contact .form-area .form-container form .capcha-align {
		width: 100%;
		text-align: left;
		margin: 0;
	}

	#contact .form-area .form-container form .captcha-wrapper iframe {
		transform-origin: left;
	}

	#contact .form-area .form-container form .capcha-align .captcha-wrapper {
		width: fit-content;
	}

	#contact .cms-content {
		padding-bottom: 64px;
	}

	#contact .breadcrumbs-container {
		display: none;
	}

	.product-box:hover .quick-buy {
		display: none;
	}

	#pager {
		margin-top: 40px;
	}

	.homepage-blogs .title a {
		padding-right: 16px !important;
		font-size: 14px;
	}

	.homepage-blogs .title a::after {
		width: 10px !important;
		height: 11px;
		background-size: cover;
	}

	.homepage-blogs .title.flex-col h1 {
		font-size: 16px;
		line-height: 18px;
	}

	.homepage-blogs .title {
		margin-bottom: 21px;
	}

	.product-box {
		.prod-details {
			h3 {
				height: 32px;
			}
		}
	}

	.cat-list .products-section {
		.filters-mobile {
			.display-select {
				z-index: 100;
				display: flex;
				align-items: center;

				.four-grid-display {
					display: grid;
					grid-template-columns: 7px 7px;
					grid-template-rows: 7px 7px;
					gap: 2px;
					margin-right: 5px;
				}

				.single-grid-display {
					display: grid;
					grid-template-columns: 16px;
					grid-template-rows: 16px;
				}

				.four-grid-display,
				.single-grid-display {
					span {
						background: #b6bac2;
					}

					&.active {
						span {
							background: #6a727e;
						}
					}
				}
			}
		}

		.grid-part .product-list {
			gap: 14px 10px;

			&.single-grid {
				gap: 24px;

				.product-box {
					.prod-img {
						min-height: 274px;
					}
				}
			}

			.product-box {
				.prod-img {
					min-height: unset;
					max-height: 160px;
				}

				.prod-details {
					.prod-price {
						line-height: 17px;
					}

					.prod-colors {
						// margin-bottom: 18px;
						display: flex;
					}
				}

				.quick-buy {
					display: none;
				}
			}
		}
	}

	.wishlist-page {
		.product-list {
			gap: 20px 10px;

			.product-box {
				max-width: 160px;
			}
		}
	}

	#lang-name {
		img {
			width: 27px;
			height: 27px;
		}

		span {
			color: #000;
		}
	}
}

@media (max-width: 370px) {
	header #logo img {
		max-width: 85%;
		margin: auto;
	}

	header #logo {
		right: unset;
	}

	.shadow-header .checkout-steps .checkout-step .step-description {
		width: 100px;
	}

	.cat-list {
		.products-section {
			.prod-img {
				padding: 15px 0 0;
			}
		}
	}

	.product-page .product-info .sizes {
		#sizes-dropdown {
			width: 100%;
		}
	}
}

@media (max-height: 800px) {
	.register-content main {
		min-height: unset;
		height: 100%;
	}
}

@media (max-height: 600px) {
	.login-content main {
		min-height: unset;
		height: 100%;
	}

	.modal-content {
		height: 100%;
	}
}

@media (max-height: 410px) {
	.guest-register-content main {
		min-height: unset;
		height: 100%;
	}
}

/*end mobile*/

.checkout-step2-page {
	background-color: #f1f2f3;

	.wrapper {
		display: flex;
		flex-direction: column;
	}

	.billing-information-container {
		display: flex;
		flex-direction: column;

		@media (max-width: 575px) {
			align-items: center;
		}
	}

	.billing-info-title,
	.delivery-info-title {
		flex: 1;
		font-size: 20px;
		font-family: Play, Commissioner, sans-serif;
		width: 100%;
		text-align: left;
		margin: 0;

		@media (max-width: 575px) {
			text-align: center;
		}
	}

	.select-receipt-invoice {
		margin-top: 25px;
		margin-bottom: 30px;
		display: flex;
		gap: 40px;

		@media (max-width: 980px) {
			padding-left: 0px;
			margin-bottom: 25px;
		}

		label {
			align-items: center;
			color: #000;
			cursor: pointer;
			display: flex;
			gap: 8px;

			input[type='radio'] {
				display: block;
				height: 20px;
				width: 20px;

				&:checked {
					background-color: black;
				}

				@media (max-width: 980px) {
					margin-left: -1px;
				}
			}
		}
	}

	.step2-container {
		display: grid;
		grid-template-columns: 1fr 450px;
		grid-template-rows: masonry;
		gap: 17px;

		.step2-wrapper {
			max-width: 690px;
		}

		@media (max-width: 980px) {
			grid-template-columns: 1fr;
			gap: 40px;

			.step2-wrapper {
				width: 100%;
				max-width: unset;
				margin: auto;
			}
		}

		.addresses-container {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			gap: 18px;
			margin-bottom: 30px;

			@media (max-width: 980px) {
				max-width: 100%;
			}

			@media (max-width: 575px) {
				justify-content: center;
			}

			.address-item {
				padding: 15px 30px;
				display: flex;
				flex-direction: column;
				justify-content: center;
				margin-bottom: 0px;
				min-height: 80px;
				cursor: pointer;
				gap: 7px;
				transition: 0.5s;
				background-color: #fff;
				width: 100%;

				h4 {
					margin: 0px;
					text-align: left;
				}

				span {
					font-size: 14px;
				}

				@media (max-width: 480px) {
					width: 100%;
				}
			}

			.address-item.active {
				border: 1px solid #000;
			}

			.address-item.create-new {
				color: #6e7486;
				height: 80px;
				flex-direction: row;
				align-items: center;
				justify-content: space-between;

				h4 {
					width: 150px;
				}

				img {
					background-color: #fff;
					padding: 7px;
					width: 28px;
					border-radius: 50px;
				}
			}
		}

		.custom-checkbox {
			margin-top: 20px;
			padding-top: 5px;
			font-size: 14px;

			@media (max-width: 575px) {
				padding-top: 0px;
			}
		}

		.billing-info-form {
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-auto-rows: auto;
			column-gap: 17px;
			row-gap: 10px;

			.address-title {
				grid-column: 1 / -1;
			}

			@media (max-width: 575px) {
				display: flex;
				flex-direction: column;
				gap: 12px;
			}

			input::placeholder {
				opacity: 1;
				color: #6e7486;
			}

			.input-help {
				font-size: 11px;
			}

			.input-error {
				font-size: 11px;
				color: red;
				font-weight: 500;
				margin: 3px 0;
			}

			.react-tel-input {
				.flag-dropdown {
					border: 0;
					border-radius: 0;
					background-color: #fff;
					border-right: 1px solid #cacaca;
				}

				.form-control {
					position: relative;
					font-size: 14px;
					letter-spacing: 0.01rem;
					margin-top: 0 !important;
					margin-bottom: 0 !important;
					padding-left: 48px;
					margin-left: 0;
					background: #fff;
					line-height: 25px;
					height: 48px !important;
					width: 100% !important;
					border: 0;
					border-radius: 0;
				}
			}

			.react-tel-input input:focus {
				// border: 1px solid #0154ff !important;
			}
		}

		.order-synopsis {
			display: flex;
			flex-direction: column;
			gap: 26px;

			@media (max-width: 980px) {
				order: 2;
				margin-top: 0px;
			}
		}

		.delivery-information-container {
			margin: 50px 0 35px;

			@media (max-width: 980px) {
				order: 0;
				margin: 70px 0 65px;
				max-width: 100%;
			}

			@media (max-width: 575px) {
				margin: 30px 0 30px;
			}

			p {
				font-weight: 400;
				color: #6e7486;

				@media (max-width: 575px) {
					text-align: center;
				}
			}

			.shipping-info-form {
				margin-top: 30px;
				display: grid;
				grid-template-columns: 1fr 1fr;
				grid-auto-rows: auto;
				column-gap: 17px;
				row-gap: 10px;

				@media (max-width: 575px) {
					display: flex;
					flex-direction: column;
					gap: 12px;
				}

				input::placeholder {
					opacity: 1;
					color: #6e7486;
				}

				.input-error {
					font-size: 11px;
					color: red;
					font-weight: 500;
					margin: 3px 0;
				}

				.react-tel-input {
					.form-control {
						position: relative;
						font-size: 14px;
						letter-spacing: 0.01rem;
						margin-top: 0 !important;
						margin-bottom: 0 !important;
						padding-left: 48px;
						margin-left: 0;
						background: #fff;
						line-height: 25px;
						height: 48px !important;
						width: 100% !important;
						border: 0;
						border-radius: 0;
					}

					.flag-dropdown {
						border-radius: 0;
						border: 0;
						border-right: 1px solid #cacaca;
						background-color: #fff;
					}
				}

				.react-tel-input input:focus {
					// border: 1px solid #0154ff !important;
				}

				.custom-checkbox {
					margin-top: 100px;
					padding-top: 5px;
					grid-column-start: 1;
					grid-column-end: 3;
					font-size: 14px;

					@media (max-width: 980px) {
						order: -1;
						margin-top: 0px;
					}

					@media (max-width: 575px) {
						padding-top: 0px;
					}
				}
			}
		}

		.customer-notes {
			margin-bottom: 50px;

			h4 {
				text-align: left;
			}

			@media (max-width: 540px) {
				margin-top: 44px;
				margin-bottom: 30px;
			}
		}

		textarea {
			margin-top: 14px;
			max-width: 100%;
			font-family: Commissioner, sans-serif;
			max-width: 100%;
			padding: 18px 20px;
			resize: none;
		}

		.buttons-cart {
			justify-content: space-between;
			gap: 22px;

			button {
				border: 1px solid #000;
				border-radius: 0;
				font-family: Play, Commissioner, sans-serif;
				font-weight: 700;
				height: 48px;
				width: 235px;
				padding: 0;
			}

			@media (max-width: 540px) {
				flex-wrap: wrap;
				justify-content: center;

				button {
					width: 100%;
				}
			}
		}
	}

	// Order synopsis
	#order-synopsis-checkout {
		// margin-top: -100px;

		@media (max-width: 980px) {
			margin-top: 0px;
		}

		.coupons-container {
			margin-bottom: 25px;
		}

		h1 {
			font-size: 20px;
			font-weight: 700;
			font-family: 'Play', 'Commissioner', sans-serif;
		}
	}

	input[type='text'],
	textarea {
		background-color: #fff;
	}
}

.checkout-step3-page {
	background-color: #f1f2f3;

	.content-wrapper {
		padding: 0;
	}

	.step3-container {
		display: grid;
		grid-template-columns: 1fr 450px;
		grid-template-rows: masonry;
		gap: 18px;

		@media (max-width: 980px) {
			grid-template-columns: 1fr;
		}

		@media (max-width: 480px) {
			gap: 32px;
		}

		.left {
			max-width: 690px;
			width: 100%;
			margin: 0 10px;
			padding: 0 22px;

			@media (max-width: 980px) {
				margin: auto;
			}
		}

		.shipping-options {
			margin-bottom: 25px;

			.shipping-options-title {
				width: 100%;
				font-size: 20px;
				font-family: Play, Commissioner, sans-serif;
				font-weight: 600;
				text-align: left;
				margin: 0 0 35px;
			}

			.select-shipping-type {
				padding-left: 15px;
				margin-top: 25px;
				margin-bottom: 30px;
				display: flex;
				gap: 35px;

				@media (max-width: 980px) {
					padding-left: 0px;
					margin-bottom: 25px;
				}

				@media (max-width: 550px) {
					flex-direction: column;
					align-items: center;
					gap: 25px;
				}

				label {
					align-items: center;
					color: #000;
					cursor: pointer;
					display: flex;
					gap: 10px;

					input[type='radio'] {
						display: block;
						height: 20px;
						width: 20px;

						&:checked {
							background-color: black;
							border-color: black;
						}

						@media (max-width: 980px) {
							margin-left: -1px;
						}
					}
				}
			}

			.shipping-options-container {
				display: flex;
				flex-direction: column;
				gap: 12px;

				.shipping-notice {
					font-size: 16px;
					text-align: center;
					padding-top: 12px;
					font-weight: bold;
				}

				&.radio {
					gap: 12px;

					label {
						background-color: #fff;
						margin: 0;
						padding: 14px;
						padding-right: 40px;
						display: flex;
						align-items: center;

						input {
							margin: 0 28px 0;
							margin-right: 28px;
							cursor: pointer;
						}

						input[type='radio']:checked {
							border: 5px solid #ffffff;
							background: #000;
						}

						.icon {
							width: 110px;
							min-width: 110px;
							height: 42px;
							display: flex;
							justify-content: center;
							align-items: center;
							border-radius: 5px;
							margin-right: 30px;
						}

						.icon-geniki {
							background: #1669c3;
						}

						.text {
							display: flex;
							justify-content: space-between;
							flex-grow: 1;
							font-size: 16px;
							font-weight: 400;

							.prices {
								font-weight: 600;
							}
						}

						.price {
							margin: auto 0 auto auto;
							font-family: 'Play', 'Commissioner', sans-serif;
							font-weight: 400;
						}

						&:has(input[type='radio']:checked) .text,
						&:has(input[type='radio']:checked) .price {
							font-weight: 700;
						}

						@media (max-width: 575px) {
							line-height: 17px;
							padding: 18px 14px;

							input {
								margin: 0 20px 0 0;
							}

							.icon {
								height: 30px;
								margin-right: 18px;
								min-width: 65px;
								width: 65px;
							}
						}
					}
				}

				.shipping-option {
					height: 70px;
					width: 100%;
					padding: 0 20px;
					display: flex;
					align-items: center;
					gap: 20px;
					background-color: #fff;
					cursor: pointer;

					input[type='radio'] {
						display: block;
						-ms-transform: scale(1.7);
						/* IE 9 */
						-webkit-transform: scale(1.7);
						/* Chrome, Safari, Opera */
						transform: scale(1.7);
						cursor: pointer;
					}

					.title {
						width: 100%;
						display: flex;
						align-items: center;
						gap: 20px;

						@media (max-width: 450px) {
							gap: 12px;
						}

						span {
							font-size: 14px;

							@media (max-width: 350px) {
								font-size: 13px;
							}
						}
					}

					&.selected {
						border: 1px solid #000;

						.title {
							font-weight: 700;
						}
					}
				}

				.shipping-option.store {
					height: 90px;
					gap: 50px;
					padding: 9px 20px;

					@media (max-width: 450px) {
						gap: 30px;
					}

					.title {
						width: 100%;
						display: flex;
						align-items: center;
						justify-content: space-between;

						@media (max-width: 450px) {
							gap: 12px;
						}

						h4 {
							font-size: 14px;
							margin: 0px;
						}

						span {
							font-size: 12px;

							@media (max-width: 375px) {
								font-size: 14px;
							}
						}
					}
				}
			}
		}

		.payment-options {
			margin-bottom: 30px;
			margin-top: 80px;

			.payment-options-title {
				width: 100%;
				font-size: 20px;
				font-family: Play, Commissioner, sans-serif;
				font-weight: 600;
				text-align: left;
				margin: 0 0 35px;
			}

			.payment-options-container {
				display: flex;
				flex-direction: column;
				gap: 12px;

				.payment-option {
					height: 70px;
					width: 100%;
					padding: 0 14px;
					display: flex;
					align-items: center;
					gap: 20px;
					background-color: #fff;
					cursor: pointer;
					padding-right: 40px;

					input[type='radio'] {
						display: inline-block;
						cursor: pointer;
						appearance: none;
						width: 20px;
						height: 20px;
						background: #ffffff;
						outline: 1px solid #000;
						border: none;
						border-radius: 50%;
						flex-shrink: 0;
						margin: auto 7px auto 0;
						margin: 0 28px;
					}

					input[type='radio']:checked {
						border: 5px solid #ffffff;
						background: #000000;
					}

					.title {
						width: 100%;
						display: flex;
						align-items: center;
						gap: 20px;

						@media (max-width: 450px) {
							gap: 12px;
						}

						span {
							font-size: 14px;

							&.icon {
								width: 72px;

								img {
									margin: auto;
								}
							}

							@media (max-width: 350px) {
								font-size: 13px;
							}
						}
					}

					.price {
						margin: auto 0 auto auto;
						font-family: 'Play', 'Commissioner', sans-serif;
						font-weight: 400;
					}

					&:has(input[type='radio']:checked) .text,
					&:has(input[type='radio']:checked) .price {
						font-weight: 700;
					}

					&.selected {
						// border: 1px solid #000;

						.title {
							font-weight: 700;
						}
					}

					@media (max-width: 575px) {
						line-height: 17px;
						padding: 18px 14px;
						gap: 0;

						input {
							margin: 0 20px 0 0 !important;
						}
					}
				}

				.sub-options {
					margin-top: -12px;

					.sub-option {
						height: 50px;
						background-color: #f8fafd;
						display: flex;
						align-items: center;
						justify-content: space-between;
						border-top: 1px solid #f2f4f8;
						padding: 16px 20px;

						.name {
							width: 160px;
						}

						img {
							margin-right: 0;
							margin-left: auto;
							cursor: pointer;
						}

						@media (max-width: 480px) {
							font-size: 12px;

							.name {
								width: unset;
								margin-right: 10px;
							}
						}
					}
				}
			}
		}

		.gift-checkbox-container {
			padding: 9px;
			border: 1px solid #e2e5ea;
			display: flex;
			align-items: center;
			gap: 50px;
			height: 45px;
			margin-bottom: 35px;

			@media (max-width: 450px) {
				gap: 12px;
			}

			.custom-checkbox .checkmark {
				top: 0px;

				&:after {
					border-width: 0 1px 1px 0;
				}
			}

			.title {
				width: 100%;
				display: flex;
				align-items: center;
				gap: 15px;

				@media (max-width: 450px) {
					gap: 12px;
				}

				img {
					max-width: 55px;
					max-height: 28px;
				}

				span {
					font-size: 15px;

					@media (max-width: 350px) {
						font-size: 13px;
					}
				}
			}
		}

		.privacy-newsletter-checkbox {
			margin-bottom: 30px;

			label a {
				font-size: 12px;
				color: #000;
				text-decoration: underline;
			}

			.custom-checkbox {
				@media (max-width: 450px) {
					font-size: 14px;
				}

				.checkmark {
					top: 0px;

					@media (max-width: 500px) {
						top: 1px;
					}
				}
			}
		}

		.buttons-cart {
			justify-content: space-between;
			gap: 22px;

			button {
				border: 1px solid #000;
				border-radius: 0;
				font-family: Play, Commissioner, sans-serif;
				font-weight: 700;
				height: 48px;
				width: 235px;
				padding: 0;
			}

			@media (max-width: 540px) {
				flex-wrap: wrap;
				justify-content: center;

				button {
					width: 100%;
				}
			}
		}
	}
}

// COUPONS CONTAINER ORDER SYNOPSIS
.coupons-container,
.giftcards-container {
	background-color: #f2f4f8;
	padding: 10px 22px 10px;
	margin-bottom: 25px;

	.header {
		position: relative;
		cursor: pointer;

		.title {
			text-align: left;
			margin: 5px 0;
			font-size: 16px;
			color: #000;
		}

		&::after {
			content: '';
			background-image: url(../images/arrow-right.svg);
			background-repeat: no-repeat;
			position: absolute;
			margin: auto;
			width: 20px;
			height: 20px;
			transform: rotate(90deg);
			right: 10px;
			top: 3px;
			transition: 0.15s ease;
		}

		&.active::after {
			content: '';
			background-image: url(../images/arrow-right.svg);
			background-repeat: no-repeat;
			position: absolute;
			margin: auto;
			width: 20px;
			height: 20px;
			transform: rotate(-90deg);
			right: 5px;
			top: -5px;
			transition: 0.15s ease;
		}
	}

	.coupons-badges {
		margin-top: 25px;
		display: flex;
		gap: 19px;
		flex-wrap: wrap;

		.badge {
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 10px;
			border-radius: 21px;
			font-size: 14px;
			font-family: 'Roboto Condensed';
			font-weight: 700;
			color: #000;
			padding: 7px 19px;
			border: 1px solid #000;
			cursor: pointer;
			transition: 0.5s;

			img {
				cursor: pointer;
				width: 11px;
			}
		}

		.badge:hover {
			opacity: 0.7;
		}
	}

	.coupon-submit {
		margin-top: 16px;

		h4 {
			text-align: left;
			font-size: 16px;
			margin-bottom: 14px;
			font-weight: 400;
		}

		.coupon-input {
			display: flex;
			align-items: center;

			input {
				padding: 14px;
				background-color: #fff;
				height: 55px;
			}

			input::placeholder {
				opacity: 1;
			}

			button {
				width: 58px;
				height: 55px;
				padding: 0px !important;

				img {
					display: block;
					margin: auto;
				}
			}
		}
	}
}

#cart-page {
	.coupons-container,
	.giftcard-container {
		background-color: #f2f4f8;
		padding: 10px 18px 10px;
		margin-bottom: 25px;
		border-top: 15px solid #f1f2f3;

		.header {
			position: relative;
			cursor: pointer;

			.title {
				text-align: left;
				margin: 5px 0;
				font-size: 16px;
				color: #040707;
				text-decoration: underline;
			}

			&::after {
				content: '';
				background-image: url(../images/arrow-right.svg);
				background-repeat: no-repeat;
				position: absolute;
				margin: auto;
				width: 20px;
				height: 20px;
				transform: rotate(90deg);
				right: 5px;
				top: 3px;
				transition: 0.15s ease;
			}

			&.active::after {
				content: '';
				background-image: url(../images/arrow-right.svg);
				background-repeat: no-repeat;
				position: absolute;
				margin: auto;
				width: 20px;
				height: 20px;
				transform: rotate(-90deg);
				right: 0px;
				top: -5px;
				transition: 0.15s ease;
			}
		}

		.coupons-badges {
			margin-top: 25px;
			display: flex;
			gap: 19px;
			flex-wrap: wrap;

			.badge {
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 10px;
				border-radius: 21px;
				font-size: 14px;
				font-family: 'Roboto Condensed';
				font-weight: 700;
				color: #000;
				padding: 7px 19px;
				border: 1px solid #000;
				cursor: pointer;
				transition: 0.5s;

				img {
					cursor: pointer;
					width: 11px;
				}
			}

			.badge:hover {
				opacity: 0.7;
			}
		}

		.coupon-submit {
			margin-top: 16px;

			h4 {
				text-align: left;
				font-size: 16px;
				margin-bottom: 14px;
				font-weight: 400;
			}

			.coupon-input {
				display: flex;
				align-items: center;

				input {
					padding: 14px;
					background-color: #fff;
					border: 1px solid #000;
					height: 55px;
				}

				input::placeholder {
					opacity: 1;
				}

				button {
					width: 58px;
					height: 55px;
					padding: 0px !important;

					img {
						display: block;
						margin: auto;
					}
				}
			}
		}
	}
}

// COUPONS CONTAINER ORDER SYNOPSIS
/* CUSTOM CHECKBOX */
.custom-checkbox {
	display: block;
	position: relative;
	padding-left: 30px;
	margin-bottom: 17px;
	cursor: pointer;
	font-size: 16px;
	color: #6e7486;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	/* Hide the browser's default checkbox */
	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
	}

	/* Create a custom checkbox */
	.checkmark {
		position: absolute;
		top: 4px;
		left: 0;
		height: 20px;
		width: 20px;
		border: 1px solid #b5bac3;
		border-radius: 3px;
		background-color: #fff;
	}

	/* When the checkbox is checked, add a blue background */
	input:checked ~ .checkmark {
		border: 1px solid #b5bac3;
		background-color: #fff;
		border-radius: 3px;
	}

	/* Create the checkmark/indicator (hidden when not checked) */
	.checkmark:after {
		content: '';
		position: absolute;
		display: none;
	}

	/* Show the checkmark when checked */
	input:checked ~ .checkmark:after {
		display: block;
	}

	/* Style the checkmark/indicator */
	.checkmark:after {
		left: 6px;
		top: 2px;
		width: 6px;
		height: 10px;
		border: solid #000;
		border-width: 0 2px 2px 0;
		-webkit-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		transform: rotate(45deg);
	}
}

/* CUSTOM CHECKBOX */
// Giftcards

#giftcard-page {
	.giftcard-content-wrapper {
		// max-width: 600px;
	}

	.giftcard-header-banner {
		background-repeat: no-repeat;
		background-size: cover;
		background-color: #f1f2f3;
		text-align: left;

		.giftcard-container {
			height: 100%;
			position: relative;
			padding: 35px 0;
			display: flex;
			justify-content: space-between;
			align-items: center;

			@media (max-width: 640px) {
				flex-direction: column;
				justify-content: center;
				align-items: center;
			}

			.details {
				h2 {
					margin-top: 0;
					text-align: left;
					font-size: 32px;
					color: #000;
					margin-bottom: 35px;
					// font-family: 'Roboto Condensed';

					@media (max-width: 768px) {
						text-align: center;
					}

					@media (max-width: 450px) {
						font-size: 24px;
					}
				}
			}

			.giftcard-img {
				// width: 350px;

				@media (max-width: 640px) {
					// width: 215px;
				}
			}
		}
	}

	.giftcard-steps {
		max-width: 600px;
		position: relative;
		padding: 25px 0px 75px;

		@media (max-width: 615px) {
			padding: 45px 12px 75px;
		}

		.prev-step {
			left: -15vw;
			position: absolute;
			display: flex;
			align-items: center;
			gap: 20px;
			cursor: pointer;

			@media (max-width: 920px) {
				left: -100px;
			}

			@media (max-width: 820px) {
				left: -50px;
			}

			@media (max-width: 720px) {
				left: -20px;
			}

			@media (max-width: 650px) {
				left: 10px;
				gap: 10px;
			}

			@media (max-width: 420px) {
				top: 7px;
			}

			img {
				transform: rotate(180deg);

				@media (max-width: 650px) {
					width: 7px;
				}
			}

			span {
				font-size: 16px;

				@media (max-width: 650px) {
					font-size: 12px;
				}
			}
		}

		.giftcard-step {
			width: 100%;

			// Common styles for all steps
			input::placeholder {
				opacity: 1;
			}

			h3 {
				font-size: 24px;
				text-align: center;
				font-family: 'Roboto Condensed';

				@media (max-width: 640px) {
					font-size: 20px;
				}
			}

			.next-step-button {
				width: 100%;
				max-width: 375px;
				margin: 25px auto 0px;

				button {
					width: 100%;
					padding: 17px 0 !important;
				}
			}

			// Specific styles for each step
			.first-step {
				h3 {
					margin-bottom: 30px;
				}

				.receiver-tabs {
					width: 100%;
					display: flex;
					justify-content: space-around;
					border-bottom: 3px solid #ebedeb;
					padding-bottom: 17px;
					margin: 45px auto 38px;
					position: relative;

					@media (max-width: 575px) {
						gap: 10px;
					}

					h4 {
						font-size: 16px;
						font-weight: 600;
						margin: 0;
						color: #a3a9b9;
						cursor: pointer;

						@media (max-width: 575px) {
							font-size: 16px;
						}
					}

					h4.active {
						color: #000;
					}
				}

				.receiver-tabs:has(.active-first):before {
					content: '';
					position: absolute;
					top: 34px;
					left: 0px;
					margin: auto;
					width: 50%;
					height: 3px;
					background-color: #000;

					@media (max-width: 615px) {
						left: -12px;
						width: 52%;
					}
				}

				.receiver-tabs:has(.active-second):before {
					content: '';
					position: absolute;
					top: 34px;
					right: 0px;
					margin: auto;
					width: 50%;
					height: 3px;
					background-color: #000;

					@media (max-width: 615px) {
						right: -12px;
					}
				}

				.tab-content input {
					margin-bottom: 12px;
				}
			}

			.second-step {
				.giftcard-options-container {
					margin: 35px auto;
					display: flex;
					justify-content: center;

					@media (max-width: 650px) {
						display: block;
					}

					.giftcard-options {
						display: grid;
						gap: 18px;
						grid-template-columns: repeat(6, 1fr);

						@media (max-width: 650px) {
							grid-template-columns: repeat(auto-fit, minmax(70px, 1fr));
						}

						@media (max-width: 350px) {
							gap: 10px;
						}

						.option {
							padding: 14px 27px;
							border-radius: 5px;
							border: 1px solid #f3f3f3;
							cursor: pointer;

							@media (max-width: 650px) {
								padding: 14px 0px;
								display: flex;
								justify-content: center;
								align-items: center;
							}

							h4 {
								font-size: 17px;
								margin: 0;
							}
						}

						.option:hover {
							border: 1px solid #ccc;
							opacity: 0.7;
						}

						.option.active {
							border: 1px solid #000;
							background-color: #f1f2f3;
						}
					}
				}

				.giftcard-quantity {
					max-width: 180px;
					margin: auto;
					margin-bottom: 45px;
					text-align: center;

					h4 {
						margin: 0;
						font-size: 24px;
						padding-bottom: 16px;
					}
				}
			}

			.third-step {
				.giftcard-message {
					margin-top: 18px;
					height: 195px;
				}
			}

			.fourth-step {
				.input-container {
					margin: 27px 0 37px;

					input {
						margin-bottom: 12px;
						background-color: #f2f4f8;
						border: none;
						border-radius: 0;
						color: #6e7486;
						font-size: 14px;
						height: 48px;
						line-height: 22px;
						outline: none;
						padding: 12px 14px;
						width: 100%;
					}

					.error {
						border: 1px solid red;
					}
				}
			}

			.fifth-step {
				.input-container {
					margin: 27px 0 37px;

					.billing-input {
						margin-bottom: 12px;

						.input-error {
							color: red;
						}
					}

					#countrySelect {
						margin-bottom: 18px;

						div:first-of-type {
							background-color: #f1f2f3;
						}
					}

					#regionSelect {
						margin-bottom: 18px;

						div:first-of-type {
							background-color: #f1f2f3;
						}
					}

					#postalCodeSelect {
						div:first-of-type {
							background-color: #f1f2f3;
						}
					}
				}

				.payment-options {
					margin-bottom: 25px;

					.payment-options-title {
						width: 100%;
						font-family: 'Roboto Condensed';
						font-weight: 600;
						text-align: center;
						margin: 0 0 35px;

						@media (max-width: 980px) {
							text-align: center;
						}
					}

					.payment-options-container {
						display: flex;
						flex-direction: column;
						gap: 12px;

						.payment-option {
							height: 90px;
							width: 100%;
							padding: 0 20px;
							display: flex;
							align-items: center;
							gap: 50px;
							border: 1px solid #e2e5ea;

							input[type='radio'] {
								display: inline-block;
								appearance: none;
								width: 20px;
								height: 20px;
								background: #ffffff;
								outline: 1px solid #000;
								border: none;
								border-radius: 50%;
								flex-shrink: 0;
								margin: auto 7px auto 0;
							}

							input[type='radio']:checked {
								background-color: #000;
								border: 5px solid #fff;
							}

							.title {
								width: 100%;
								display: flex;
								align-items: center;
								gap: 50px;

								@media (max-width: 450px) {
									gap: 12px;
								}

								// img {
								//     max-width: 35px;
								// }

								span {
									font-size: 14px;

									@media (max-width: 350px) {
										font-size: 13px;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

.order-completed-page-giftcard .content-wrapper {
	.order-summary-content {
		justify-content: center;

		.msg-info {
			font-weight: 700;
			font-size: 15px;
			color: #040707;
			line-height: 20px;
		}
	}
}

.giftcards-container {
	margin-bottom: 30px;
	background-color: #f2f4f8;
	padding: 10px 22px 10px;

	.header {
		position: relative;
		cursor: pointer;

		.title {
			text-align: left;
			margin: 5px 0;
			font-size: 16px;
			color: #000;
		}

		&::after {
			content: '';
			background-image: url(../images/arrow-right.svg);
			background-repeat: no-repeat;
			position: absolute;
			margin: auto;
			width: 20px;
			height: 20px;
			transform: rotate(90deg);
			right: 10px;
			top: 3px;
			transition: 0.15s ease;
		}

		&.active::after {
			content: '';
			background-image: url(../images/arrow-right.svg);
			background-repeat: no-repeat;
			position: absolute;
			margin: auto;
			width: 20px;
			height: 20px;
			transform: rotate(-90deg);
			right: 5px;
			top: -5px;
			transition: 0.15s ease;
		}
	}

	.giftcards-badges {
		margin-top: 25px;
		display: grid;
		gap: 19px;
		grid-template-columns: 1fr 1fr;

		@media (max-width: 500px) {
			grid-template-columns: 1fr;
		}

		.badge {
			display: flex;
			align-items: center;
			justify-content: space-between;
			gap: 10px;
			border-radius: 0px;
			font-size: 14px;
			font-family: 'Roboto Condensed';
			font-weight: 700;
			color: #000;
			padding: 7px 19px;
			border: 1px solid #000;
			transition: 0.3s;
			cursor: pointer;

			img {
				cursor: pointer;
				width: 11px;
			}
		}

		.badge:hover {
			opacity: 0.7;
		}
	}

	.giftcard-submit {
		margin-top: 16px;

		h4 {
			color: #0154ff;
			text-align: left;
			font-size: 16px;
			margin-bottom: 14px;
			font-weight: 400;
		}

		.giftcard-input {
			display: flex;
			align-items: center;
			border: 1px solid #000;
			background-color: #000;

			input {
				padding: 14px;
				background-color: #fff;
				height: 55px;
			}

			input::placeholder {
				opacity: 1;
			}

			button {
				width: 58px;
				height: 55px;
				padding: 0px !important;

				img {
					display: block;
					margin: auto;
				}
			}
		}
	}
}

// Filters Override
.filter-category {
	.filter-category-name {
		text-align: left;
		cursor: pointer;
		font-family: 'Play';
		font-size: 16px;
		position: relative;
		// test1

		.filter-content strong:before {
			content: '';
			position: absolute;
			background-color: #000000;
			top: 0;
			bottom: 0;
			right: 0;
			margin: auto;
			width: 11px;
			height: 1px;
		}

		.filter-content strong:after {
			transition: transform 0.5s;
			content: '';
			position: absolute;
			background-color: #000000;
			top: 0;
			bottom: 0;
			right: 0;
			margin: auto;
			width: 11px;
			height: 1px;
			transform: rotate(90deg);
		}

		.filter-content strong.active-filter:after {
			transform: rotate(0deg);
		}

		.visible-values {
			&:before,
			&:after {
				background-color: #000;
				bottom: 0;
				content: '';
				height: 1px;
				margin: auto;
				position: absolute;
				right: 0;
				top: 0;
				width: 11px;
				transition: transform 0.5s;
				transition: -webkit-transform 0.5s;
				transition:
					transform 0.5s,
					-webkit-transform 0.5s;
			}

			&:after {
				transform: rotate(90deg);
			}

			&.active-filter:after {
				transform: rotate(0deg);
				transition: transform 0.5s;
				transition: -webkit-transform 0.5s;
				transition:
					transform 0.5s,
					-webkit-transform 0.5s;
			}
		}
	}

	.show-more {
		text-decoration: underline;
		cursor: pointer;
	}
}

.filter-category-values {
	.disabled {
		pointer-events: none;
		opacity: 0.5;
	}

	.filter-value {
		font-family: 'Commissioner', sans-serif;
		display: block;
		position: relative;
		padding-left: 30px;
		margin-bottom: 17px;
		cursor: pointer;
		font-size: 14px;
		color: #6e7486;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;

		/* Hide the browser's default checkbox */
		input {
			position: absolute;
			opacity: 0;
			cursor: pointer;
			height: 0;
			width: 0;
		}

		/* Create a custom checkbox */
		.checkmark {
			position: absolute;
			top: 0;
			left: 0;
			height: 20px;
			width: 20px;
			border: 1px solid #b5bac3;
			border-radius: 3px;
		}

		/* When the checkbox is checked, add a blue background */
		input:checked ~ .checkmark {
			border: 1px solid #b5bac3;
			background-color: #fff;
			border-radius: 3px;
		}

		/* Create the checkmark/indicator (hidden when not checked) */
		.checkmark:after {
			content: '';
			position: absolute;
			display: none;
		}

		/* Show the checkmark when checked */
		input:checked ~ .checkmark:after {
			display: block;
		}

		/* Style the checkmark/indicator */
		.checkmark:after {
			left: 6px;
			top: 2px;
			width: 6px;
			height: 10px;
			border: solid #d71e26;
			border-width: 0 1px 1px 0;
			-webkit-transform: rotate(45deg);
			-ms-transform: rotate(45deg);
			transform: rotate(45deg);
		}
	}

	.filter-value-color {
		// padding: 8px 15px;
		// uncomment above to fix padding-left color values.
		margin-bottom: 12px;
		display: flex;
		align-items: center;
		gap: 15px;
		cursor: pointer;

		.colorbox {
			width: 20px;
			height: 20px;
			border-radius: 3px;
			border: 1px solid #f1f2f3;
		}

		.color-title {
			font-size: 14px;
			color: #6e7486;
		}
	}

	.active {
		background-color: #f2f4f8;
	}
}

// Sorting
.view-options {
	display: flex;
	justify-content: flex-end;
	gap: 15px;
	padding: 20px 0;

	@media (max-width: 810px) {
		position: absolute;
		left: 0px;
		right: 0px;
		top: -70px;
	}

	@media (max-width: 480px) {
		justify-content: center;
		top: -65px;
	}

	// @media (max-width: 575px) {
	// 	justify-content: center;
	// }
}

.CategoriesSwiper {
	.swiper-slide {
		// max-width: 200px;
		.title-container {
			// display: none;
		}
	}

	.swiper-wrapper {
		// margin: 0 50px;
	}

	.swiper-button-prev,
	.swiper-button-next {
		top: 60%;
	}
}

// Order-Page
.order-page {
	background-color: #ffffff;
	border-radius: 5px;
	padding: 40px 40px 20px 40px;

	.order-status {
		padding: 0px 40px 0px 40px;
	}

	.order-status p {
		font-size: 16px;
		line-height: 21px;
		font-weight: bold;
		-webkit-font-smoothing: antialiased;
	}

	.order-information {
		padding: 10px 40px 20px 40px;
		display: flex;
		justify-content: space-between;
	}

	.order-information > div {
		display: flex;
		flex-direction: column;
		gap: 10px;
	}

	.order-information > div span:first-child {
		color: #707070;
	}

	.order-products-container {
		margin-top: 40px;
		padding: 20px 0px;
		border-top: 1px solid #e8e8e8;
	}

	.order-product {
		display: flex;
		justify-content: space-between;

		&:nth-child(odd) {
			background-color: #f7f7f7;
		}
	}

	.order-product .media {
		display: flex;
		align-items: center;
	}

	.order-product .media figure {
		border: 1px solid #f3f3f3;
		border-radius: 8px;
	}

	.order-product .media .name {
		color: #000;
		font-weight: 400;
		margin: 5px 0;
	}

	.order-product .media .dimension {
		color: #707070;
		font-weight: 400;
		margin: 5px 0;
	}

	.order-product .media a.name:hover {
		text-decoration: underline;
	}

	.order-product .price {
		padding-right: 40px;
		margin: auto 0;
	}

	.order-synopsis-information {
		margin: 10px 0;
		border-top: 1px solid #e8e8e8;
		padding: 20px 40px;
		display: flex;
		justify-content: space-between;
		gap: 30px;

		.order-billing-information {
			p {
				margin-bottom: 30px;
				display: flex;
				flex-direction: column;
				gap: 10px;
			}
		}

		.order-total-summary {
			align-self: center;

			p {
				font-size: 17px;
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				gap: 60px;
			}
		}
	}

	.order-actions .cancel {
		padding: 10px 20px;
		background: transparent;
		border: 1px solid #333;
		border-radius: 4px;
		color: #333;
		margin-right: 10px;
	}

	.order-actions button:hover {
		color: #fff;
		background-color: #333;
	}

	@media all and (max-width: 950px) {
		.order-synopsis-information {
			display: block;
			justify-content: space-between;
		}
	}

	@media all and (max-width: 768px) {
		.order-information {
			padding: 10px 40px 0px 40px;
			display: block;
		}

		.order-information > div {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			gap: 0px;
			margin-bottom: 15px;
		}

		.order-information > div span:first-child {
			color: #707070;
		}

		.order-products-container {
			margin-top: 40px;
			border-top: 1px solid #e8e8e8;
		}

		.order-product {
			display: flex;
			justify-content: space-between;
			position: relative;
			margin-bottom: 20px;
		}

		.order-product .price {
			position: absolute;
			bottom: 0px;
			right: 0px;
		}

		.order-synopsis-information {
			padding: 20px 40px 20px 40px;
		}

		.order-actions {
			padding: 0px 40px;
		}
	}

	@media all and (max-width: 425px) {
		.order-status {
			padding: 0px 16px 0px 16px;
		}

		.order-information {
			padding: 10px 16px 0px 16px;
		}

		.order-information > div {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			gap: 4px;
			margin-bottom: 25px;
		}

		.order-products-container {
			padding: 20px 16px 20px 0px;
		}

		.order-product {
			display: flex;
			justify-content: space-between;
			margin-bottom: 35px;
		}

		.order-product .media {
			align-items: flex-start;
		}

		.order-product .media figure {
			margin: 15px 10px;
		}

		.order-product .media .name {
			font-size: 14px;
		}

		.order-product .price {
			position: absolute;
			bottom: -20px;
			right: 10px;
		}

		.order-synopsis-information {
			.order-total-summary {
				p {
					font-size: 15px;
					display: flex;
					flex-direction: row;
					justify-content: space-between;
					gap: 10px;
				}
			}
		}
	}
}

@media all and (max-width: 768px) {
	.order-page {
		padding: 20px 0;
	}
}

// Size-Guide
.sizeguide-dialog-overlay {
	position: fixed;
	inset: 0;
	z-index: 10000;
	background: rgba(0, 0, 0, 0.6);
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: auto;

	@media (max-width: 575px) {
		align-items: end;
	}

	.sizeguide-dialog {
		z-index: 10000;
		position: relative;
		animation: fadeIn 0.5s;

		@keyframes fadeIn {
			0% {
				opacity: 0;
			}

			100% {
				opacity: 1;
			}
		}

		.close-modal-btn {
			position: absolute;
			top: 20px;
			right: 20px;
			cursor: pointer;

			@media (max-width: 768px) {
				top: 15px;
				right: 15px;
			}
		}
	}

	.sizeGuideModal {
		max-width: 575px;
		width: 100%;
		max-height: 100vh;
		background-color: #fff;
		position: relative;
		border-radius: 4px;

		@media (max-width: 575px) {
			margin: 0;
		}

		// .sizeGuide-container,
		.sizeGuide-container {
			padding: 40px 0;
			background-color: #fff;
			max-height: 85vh;
			overflow-y: auto;

			&::-webkit-scrollbar {
				// display: none;
			}

			@media (max-width: 575px) {
				padding: 40px 12px;
			}

			.title {
				text-align: left;
				padding: 0 56px;
				margin-bottom: 20px;
				text-decoration: underline;
			}

			h2 {
				font-size: 24px;
				margin-top: 8px;
				font-family: Play;
				font-weight: 600;
			}

			h4 {
				text-align: center;
				font-size: 16px;
				font-weight: 600;
				margin: 0;
			}

			.countries-sizes {
				margin-top: 22px;
				padding: 0 56px;
				height: 285px;
				display: grid;
				grid-template-columns: 52px 1fr;
				position: relative;
				align-items: center;

				@media (max-width: 575px) {
					padding: 0;
				}

				.countries {
					display: flex !important;
					height: 100%;
					flex-direction: column;
					justify-content: space-around;
					background-color: #000;
					border-radius: 4px;
					color: #fff;
				}

				.sizes {
					display: flex;
					height: 100%;
					flex-direction: column;
					justify-content: space-around;
					max-width: 575px;
					overflow-x: scroll;

					.row {
						width: 100%;
						height: 25px;
						display: flex;
						margin-top: 2px;
						flex-wrap: nowrap;
						align-items: flex-end;
						gap: 35px;
						padding-left: 35px;
						font-size: 16px;

						h4 {
							margin-top: auto;
							margin-bottom: 0;
							min-width: 40px;
							max-width: 40px;
						}
					}

					.row:nth-child(2n) {
						// background-color: #f2f4f8;
					}
				}

				.sizes::-webkit-scrollbar {
					width: 2px;
					height: 5px;
					bottom: -100px;
				}

				/* Track */
				.sizes::-webkit-scrollbar-track {
					background: #f1f1f1;
				}

				/* Handle */
				.sizes::-webkit-scrollbar-thumb {
					background: #888;
				}

				/* Handle on hover */
				.sizes::-webkit-scrollbar-thumb:hover {
					background: #555;
				}
			}
		}

		.footer {
			margin: 30px auto;
			display: flex;
			justify-content: center;

			button {
				padding: 0 70px;
				height: 50px;
			}
		}
	}
}

.button-p {
	padding: 10px 20px;
	height: 48px;
	font-family: Play;
	font-weight: 700;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	background: black;
	font-size: calc(2px + var(--microsize));
	color: white;
	cursor: pointer;
}

/*cookies consent*/
#cookie-banner {
	position: fixed;
	bottom: 0;
	left: 0;
	z-index: 2010;
	width: 100%;
	background-color: #363636;
	color: #fff;
	padding: 20px;
	border-radius: 8px 8px 0 0;
	font-size: 14px;

	.content-wrapper {
		max-width: 880px;
	}
}

@media (max-width: 1090px) {
	#cookie-banner:has(.cookies-categories) {
		height: 100%;
		max-height: 550px;
		overflow: auto;
	}
}

#cookie-banner hr {
	margin: 15px 0;
	height: 0;
	border-width: 1px 0 0;
	border-style: solid;
	border-color: #707070;
}

#cookie-banner .buttons {
	display: flex;
	justify-content: space-between;
}

@media (max-width: 485px) {
	#cookie-banner .buttons {
		flex-direction: column;
		gap: 10px;
	}
}

#cookie-banner button {
	border: 1px solid #bbb;
	color: #bbb;
	border-radius: 4px;
	padding: 11px 23px;
	font-size: 14px;
}

#cookie-banner button:hover,
#cookie-banner button:active,
#cookie-banner button:focus {
	border-color: #bbb;
	background: #2c2c2c;
}

.cookies-categories .explanation {
	padding: 10px;
}

.cookies-categories .explanation h3 {
	font-size: 16px;
}

.cookies-categories .explanation p {
	margin: 0 0 0px 0;
	padding-top: 5px;
}

.cookies-categories .header {
	padding: 10px;
	border-bottom: 1px solid #fff;
	display: flex;
	justify-content: space-between;
	font-weight: bold;
	font-size: 16px;
}

.cookies-categories .options-wrapper {
	margin-top: 5px;
}

.cookies-categories .options-wrapper .option {
	padding: 10px;
	border-bottom: 1px solid #f3f3f3;
	display: flex;
	justify-content: space-between;

	input[type='checkbox'] {
		display: block;
	}

	details > p {
		color: #bbb;
	}
}

.cookies-categories .options-wrapper .option .description {
	width: 100%;
}

.cookies-categories p {
	font-weight: 500;
	color: #f3f3f3;
	padding: 10px 0px 15px 0;
}

.cookies-categories .footer {
	margin-top: 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;

	.buttons {
		gap: 20px;
	}

	.buttons .agree {
		background-color: #fff;
		color: #000 !important;
	}

	.buttons .agree:hover {
		border-color: #bbb;
		background: #fff !important;
		opacity: 0.7 !important;
	}
}

@media (max-width: 600px) {
	.cookies-categories .footer {
		margin-top: 10px;
		display: block;
	}
}

.dynamic-banner-popup-modal {
	display: flex;
	align-items: center;
	justify-content: center;
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.85);
	z-index: 1000;

	.dynamic-banner-content {
		position: relative;
		background-color: #fff;
		padding: 20px 20px 10px;
		border-radius: 12px;
		max-width: 600px;
		max-height: 85vh;
		display: flex;
		flex-direction: column;
		align-items: center;

		img {
			max-width: 100%;
			max-height: 75vh;
			border-radius: 8px;
			object-fit: contain;
		}

		.button-container {
			position: absolute;
			top: 6px;
			right: 4px;

			.popup-action-btn {
				background: none;
				border: none;
				cursor: pointer;
				padding: 0;

				img {
					width: 20px;
					height: 16px;
				}

				&:hover img {
					opacity: 0.7;
				}
			}
		}

		.popup-button-container {
			margin-top: 6px;
			display: flex;
			justify-content: center;

			.popup-extra-btn {
				background-color: #040707;
				color: #fff;
				border: none;
				padding: 8px 16px;
				font-size: 14px;
				border-radius: 5px;
				cursor: pointer;
				transition: background-color 0.3s ease;

				&:hover {
					background-color: #e64a19;
				}
			}
		}
	}
}

/*end cookies consent*/
